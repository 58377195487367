export function return_language(lang) {
    switch (lang) {
        case "cn":
            return "중국어";
        case "en":
            return "영어";
        case "jp":
            return "일본어";
        case "ko":
            return "한국어";
        default:
            return lang;
    }
}

export function return_cq_type(type) {
    switch (type) {
        case "SINGLE":
            return "단일 선택";
        case "MULTIPLE":
            return "다중 선택​";
        case "SHORT":
            return "단답형 텍스트";
        case "LONG":
            return "장문형 텍스트";
        case "NUMBER":
            return "숫자";
        case "FILE":
            return "파일";
        default:
            return type;
    }
}

export const types = ["SINGLE", "MULTIPLE", "SHORT", "LONG", "NUMBER", "FILE"];