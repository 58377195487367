<script>
import { ref, defineComponent, onMounted, inject, watch, computed, onUnmounted } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    showSatisModal: Boolean,
    user: Object,
    // activeAccountInfo: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const router = useRouter();
    const eventId = computed(() => store.state.eventStore.eventId);
    const { userType, userId } = props.user;
    const isAuthenticated = ref(false);
    const token = ref('');

    const ptfList = ref([]);
    const ptsType = ref('BUYER'); // 통역사도 바이어 불러와야 함
    if (userType.value == 'SELLER') {
      ptsType.value = 'SELLER';
    }
    const readPieceToFormList = async () => {
      try {
        // axios를 사용하여 설문조사를 가져옵니다.
        const response = await axios.get('/satisfaction/read_piece_to_form_list', {
          params: {
            piece_event_id: eventId.value,
            pts_type: ptsType.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          ptfList.value = response.data.pform_list
          ptfList.value.map(item => {
            const record = recordList.value.find(r => r.sf_id == item.pf_id);
            if (record != undefined) {
              item.sr_id = record.sr_id;
              item.sr_status = record.sr_status;
            } else {
              item.sr_status = null;
              item.sr_id = null;
            }
          })
        } else {
          alert('설문조사를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('설문조사를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    const recordList = ref([]);
    const readRecordListForBis = async () => {
      try {
        // axios를 사용하여 설문조사를 가져옵니다.
        const response = await axios.get('/satisfaction/read_record_list_for_bis', {
          params: {
            event_id: eventId.value,
            sf_type: 'PIECE',
            sr_type: ptsType.value,
            account_id: userId.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          recordList.value = response.data.record_list;
          readPieceToFormList();
        } else {
          alert('설문조사를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('설문조사를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    const closeModals = () => {
      ptfList.value = []
      emit('close');
    }

    const routeForBIS = (pfId, srId, pfTitle) => {
      store.commit('eventStore/setSatisfactionId', pfId);
      store.commit('eventStore/setSatisfactionTitle', pfTitle);
      store.commit('eventStore/setSatisfactionRecordId', srId);
      router.push({ name: 'BISSatisfaction' });
    }

    const validateSrId = async (pfId, srId, pfTitle) => {
      if (srId == null) {
        const created = await createRecord(pfId);
        console.log(created);
        if (created.sr_id != undefined) {
          routeForBIS(pfId, created.sr_id, pfTitle);
        }
      } else {
        routeForBIS(pfId, srId, pfTitle);
      }
    }

    // 상담일지 생성, 수정
    const createRecord = async pfId => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post(
          "/satisfaction/create_record",
          {
            "sf_id": pfId,
            "sf_type": "PIECE",
            "account_id": userId.value,
            "answers": {},
            "sr_type": ptsType.value
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          }
        );

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          return {result: true, sr_id: response.data.sr_id};
        } else {
          alert("상담일지를 시작하는데 오류가 발생했습니다.");
          return {result: false};
        }
      } catch (error) {
        console.error("상담일지를 시작하는데 오류가 발생했습니다.:", error);
        return {result: false};
      }
    };

    watch(() => props.showSatisModal, (newValue, oldValue) => {
      // 속성 이름을 올바르게 참조
      // ptfList.value = { ...newValue };
      readRecordListForBis();
    }, { deep: true });


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        readRecordListForBis()
      }
    });

    return {
      router,
      userType,
      ptfList,
      recordList,
      closeModals,
      token,
      eventId,
      validateSrId,
      routeForBIS
    }
  },
});
</script>

<template>
  <div id="selectEvent">
    <div class="overlay" v-if="showSatisModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showSatisModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <p class="size_20 b_700" v-if="userType == 'INTERPERTER'">바이어 설문 조사</p>
          <p class="size_20 b_700" v-else>설문조사 선택하기</p>
          <p class="size_14 sub_text">작성하실 설문조사를 선택해 주세요.</p>
          <div class="event_list_wrap">
            <div class="event_list" v-for="item in ptfList" :key="item.pf_id"
              :class="{'disabled': item.sr_status == 4}"
              @click="item.sr_status != 4 ? validateSrId(item.pf_id, item.sr_id, item.pf_title) : ''">
              <p>{{ item.pf_title }}</p>
              <div class="ds_flex gap12 al_center">
                <img v-if="item.sr_status != null && item.sr_status != 4" src="@/assets/img/survey/record_1.png" width="24" alt="작성중">
                <template v-if="item.sr_status != 4">
                  <i class="icon-up_arrow rotate90 size_24 sub_text"></i>
                </template>
                <template v-else>
                  <div class="ds_flex al_center gap4 size_12 sub_text">
                    제출 완료
                    <i class="icon-checked size_16 sub_text"></i>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
