<script>
import { ref, onMounted, computed, inject, watch } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'bisQrSetting',
  components: {
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const route = useRoute();

    // query로 전달된 데이터 가져오기
    const userId = route.query.user_id;
    const userType = route.query.user_type;

    const QRCode = ref('')
    // 사용자 유형 포맷팅 함수 정의
    function userTypeFormat() {
      switch (userType) {
        case 'INTERPRETER':
          return "통역사";
        case 'SELLER':
          return "셀러";
        case 'BUYER':
          return "바이어";
        default:
          return "-";
      }
    }

    // Fetch Qr Code
    async function fetchBISQr() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/user/read_qr', {
          params: {
            account_id: userId,
            account_type: userType
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          QRCode.value = response.data.qr
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }

    const userInfo = ref([])
    // Fetch BIS
    async function fetchBISInfo() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/user/read_account_detail_info', {
          params: {
            account_id: userId,
            account_type: userType
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userInfo.value = response.data.info.account
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchBISQr()
        fetchBISInfo()
      }
    });
    return {
      QRCode,
      userType,
      userTypeFormat,
      userInfo
    };
  },
}

</script>

<template>
  <div id="bisQrSetting" class="back_light_gray2">
    <div class="mx_1160 t_center ds_flex al_center jus_center dir_column gap20">
      <p class="user_type_bdg" :class="userType">{{ userTypeFormat(userType) }}</p>
      <p class="size_32 b_700">{{ userInfo.company }}</p>
      <div class="ds_flex al_center jus_center qr_wrap">
        <img :src="QRCode" />
      </div>
      <p class="size_20 b_700">QR 보내기</p>
      <div class="ds_flex gap16 al_center jus_center qr_method_wrap">
        <div class="qr_method">
          <p class="">{{ userInfo.contact_email }}</p>
          <hr/>
          <p class="secondary_text">이메일로 보내기</p>
        </div>
        <div class="qr_method">
          <p class="">{{ userInfo.mobile }}</p>
          <hr/>
          <p class="secondary_text">문자로 보내기</p>
        </div>
      </div>
    </div>
  </div>
</template>
