<script>
import { ref, onMounted, computed, inject, watch } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';

export default {
  name: 'operateLiveMatch',
  components: {
    Pagination,
    subHeader,
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('operate');

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
      }
    });
    return {
      activeSub
    };
  },
}

</script>

<template>
  <div id="operateLiveMatch">
    <sub-header :activeSub="activeSub"></sub-header>
      <div class="mx_1160">
        현장매칭
      </div>
  </div>
</template>
