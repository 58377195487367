<script>
import { ref, watch } from "vue";
import * as survey_form from "@/assets/js/survey_form";

export default {
    props: {
        defaultValue: null,
        options: Array
    },
    setup(props, { emit }) {
        let options = props.options;
        let default_value = ref("");
        const selected = ref(default_value);
        const opened = ref(false);
        const focus = ref(null);

        const openSelect = () => {
            if (opened.value == true) {
                return false;
            } else {
                focus.value.focus();
            }
        };

        const blurClose = () => {
            // 드롭다운 자신 눌렀을때 닫히도록 타임아웃 걸기
            // 타임아웃 없으면 자신 눌렀을 때 닫기는 척만 함
            setTimeout(() => {
                opened.value = false;
            }, 90);
        };

        // 혹시 다시 input 없이 쓰고 싶을수도 있으니까 남겨놓는 토글기능..
        // const openSelect = () => {
        //     if (opened.value) {
        //         opened.value = false;
        //     } else {
        //         opened.value = true;
        //     }
        // };

        const changeOption = (option) => {
            selected.value = option;
            opened.value = false;
            emit("change", selected.value);
        };

        watch(
            // 드롭다운 프롭 변경 디텍
            () => props.defaultValue,
            (newDV, oldDV) => {
                if (oldDV == undefined) {
                    default_value.value = newDV;
                } else {
                    if (newDV !== oldDV) {
                        default_value.value = newDV;
                    }
                }
            },
            { immediate: true }
        );

        return {
            options, // 옵션 v-for 돌려야 하는 값 object 또는 array
            selected, // 선택된 옵션값
            blurClose, // 선택값 변경 함수
            changeOption, // 선택값 변경 함수
            opened, // 드롭다운 오픈 체크
            openSelect, // 드롭다운 오픈 함수
            survey_form,
            focus,
        };
    },
};
</script>
<template>
    <div class="fake_dropdown_wrap" :style="`width: 110px`">
        <input type="text" ref="focus" name="dropDownOpener" @focus="opened = true" @blur="blurClose()" class="focus_input" />
        <div class="fake_dropdown ds_flex al_center gap4" :class="[opened ? 'active' : '']" @click="openSelect()">
            <i class="icon-global_scope"></i>
            <p>{{ survey_form.return_language(selected) }}</p>
            <i :class="opened ? 'icon-up_arrow' : 'icon-down_arrow'"></i>
        </div>
        <transition name="dropdown">
            <div class="fake_dropdown_options" v-if="opened">
                <div  v-for="option in options" :key="option" class="fake_option" :class="selected == option ? 'active' : ''" @click="changeOption(option)">
                    {{ survey_form.return_language(option) }}
                    <i :class="selected == option ? 'icon-checked' : ''"></i>
                </div>
            </div>
        </transition>
    </div>
</template>
