<script>
import { ref, defineComponent, onMounted, inject, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showMessageModal: Boolean,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    // 메시지 리스트
    const msgLogList = ref([]);

    // 모달 닫기 이벤트
    const closeModals = () => {
      emit('close');
    }

    // 공지 리스트 가져오기
    async function readMsgLog() {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: eventId.value,
        })

        const url = `/message/read_log?${params.toString()}`
        const res = await axios.get(url, headers);

        if (res.data.result) {
          msgLogList.value = res.data.log_list.reverse();
        }
      } catch (e) {
        console.log(e);
      }
    }

    function modifyType(noticeType) { // 타입 한글 텍스트로 변환
      return typeMap[noticeType] || '';
    }

    function modifyDate(date) { // 일시 양식 변경
      const dateString = date.split('T');
      const timeString = dateString[1].split(':');
      const time = `${timeString[0]}:${timeString[1]}`

      return `${dateString[0]} (${time})`;
    }

    function modifyTotalCnt(totalVal) {
      if (totalVal > 1) {
        return `+${totalVal - 1}`;
      }
    }
    const typeMap = {
      'SELLER': '셀러',
      'BUYER': '바이어',
      'INTERPRETER': '통역사',
    };


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        await readMsgLog();
      }
    });

    return {
      closeModals,
      msgLogList,
      modifyType,
      typeMap,
      modifyDate,
      modifyTotalCnt
    }
  },
});
</script>

<template>
  <div id="sendNoticeHistory">
    <transition name="slide-fade">
      <div class="sidemodal" v-if="showMessageModal">
        <div class="top">
          <p class="sub_text" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <p class="size_26 b_700">보낸 메시지함</p>
        <div class="cont_wrap modal_overflow">
          <template v-if="msgLogList.length > 0">
            <div class="msg_log_list_container" v-for="(mll, index) in msgLogList">
              <div class="ds_flex jus_between notice_target_title">
                <div class="ds_flex gap8">
                  <p class="size_16 primary_text b_700">{{ modifyType(mll.ml_type) }}</p>
                  <span class="seller_msg_count">{{ modifyTotalCnt(mll.total_cnt) }}</span>
                </div>
                <span class="size_14 sub_text">{{ modifyDate(mll.sent_datetime) }}</span>
              </div>

              <p class="back_light_gray2 secondary_text size_14 notice_contents">{{ mll.message_text }}</p>
              <p class="notice_sender sub_text size_14">{{ mll.created_by }}</p>
              <hr />
            </div>
          </template>
          <template v-else>
            <p class="size_14 sub_text">정보가 없습니다.</p>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
