<script>
import { ref, onMounted, computed, inject } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import satisfySettingModal from "@/components/admin/satisfySettingModal.vue";
import * as survey_form from "@/assets/js/survey_form";
import DropDownNext from "@/components/admin/dropDown/dropDownNextAction.vue";

export default {
    name: "satisfySetting",
    props: {
        windowSize: Object,
    },
    components: {
        subHeader,
        tabHeader,
        satisfySettingModal,
        DropDownNext,
    },
    setup(props) {
        const axios = inject("axios"); // $axios 주입 받기
        const store = useStore();
        const eventId = ref("");
        const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
        const accountType = computed(() => store.state.userStore.accountType);
        const userName = ref("");
        const token = ref("");

        const activeSub = ref("setting"); // 여기에서 'setting'이라는 값을 정의합니다
        const activeHeadTab = ref("question"); // 여기에서 'question'이라는 값을 정의합니다.

        const validateCqType = (val) => {
            return ["SINGLE", "MULTIPLE"].includes(val);
        };

        // 메뉴 내 탭
        const activeTab = ref("BUYER");
        const handleActive = (BIS) => {
            activeTab.value = BIS;
        };
        const activeLang = ref("ko");
        const checkedLang = ref(["ko"]);
        const availableLang = ref(["ko", "en", "cn", "jp"]);

        // 언어탭 추가
        const openLangDropDown = ref(false);
        const oLDFocus = ref(null);
        const openoLD = () => {
            if (openLangDropDown.value) {
                openLangDropDown.value = false;
            } else {
                openLangDropDown.value = true;
            }
        };
        const findAvailable = (lang) => {
            const bool = checkedLang.value.find((avail) => avail == lang);
            if (bool == lang) {
                return true;
            } else {
                return false;
            }
        };
        const checkAvailableLang = (lang) => {
            if (lang == activeLang.value) {
                alert("액티브된 탭은 삭제할 수 없습니다.");
            } else {
                if (findAvailable(lang)) {
                    const newLang = checkedLang.value.filter((_lang) => _lang !== lang);
                    checkedLang.value = newLang;
                } else {
                    checkedLang.value.push(lang);
                    activeLang.value = lang;
                }
            }
        };
        const alignedLang = computed(() => {
            return checkedLang.value.sort((a, b) => {
                if (a == "ko" || b == "jp" || (a == "en" && b != "ko") || (a != "jp" && b == "cn")) {
                    // ko가 배열 뒤쪽에 있거나
                    // jp가 배열 앞쪽에 있거나
                    // en이 앞으로 갈지 정할 때 앞에 ko가 아니거나 (cn, jp면 앞으로)
                    // cn이 뒤로 갈지 정할 때 뒤에 jp가 아니거나 (en, ko면 뒤로)
                    // 위 네가지 중 하나면 비교군 둘이 스위치
                    return -1;
                } else {
                    return 0;
                }
            });
        });

        // 설문구조 다중 찾기
        const satisfyBList = ref([]); // 바이어 설문구조
        const satisfySList = ref([]); // 셀러 설문구조
        const activeSatisList = computed(() => {
            if (activeTab.value == "BUYER") {
                return satisfyBList.value;
            } else if (activeTab.value == "SELLER") {
                return satisfySList.value;
            }
        });
        const activePfTitle = ref(null);
        const readPieceToFormList = async (BIS, event = eventId.value) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/read_piece_to_form_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: event,
                        pts_type: BIS
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    if (response.data.pform_list.length > 0) {
                        if (BIS == "BUYER") {
                            satisfyBList.value = response.data.pform_list
                        } else if (BIS == "SELLER") {
                            satisfySList.value = response.data.pform_list
                        }
                    }
                } else {
                    console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        }

        // 설문구조 다중 추가
        const createPieceToFormList = async (BIS, event = eventId.value) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/create_piece_to_form`;
                // Axios 요청을 보냅니다.
                const response = await axios.post(
                    url,
                    {
                        piece_event_id: event,
                        pts_type: BIS,
                        pf_title: "",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                        },
                    }
                );

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    console.log('form created', response.data);
                    showToast()
                    pfId.value = response.data.pf_id;
                } else {
                    console.log('사전 설문 폼 생성에 오류가 발생했습니다');
                }
            } catch (error) {
                console.error("사전 설문 폼 생성에 오류가 발생했습니다:", error);
            }
        }

        const updatePieceToForm = async () => {
            let pfId = activeSatisForm.value.pf_id;
            let pfTitle = activeSatisForm.value.pf_title;
            try {
                // Axios 요청을 보냅니다.
                const response = await axios.put(
                    "/satisfaction/update_piece_to_form",
                    {
                        pf_id: pfId,
                        pf_title: pfTitle,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                        },
                    }
                );

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    return true;
                } else {
                    alert("설문을 수정하지 못했습니다.");
                }
            } catch (error) {
                console.error("설문을 수정하는 중에 오류가 발생했습니다:", error);
            }
        };

        const deletePieceToForm = async () => {
            // BIS 별 질문 받아오기
            try {
                // Axios 요청을 보냅니다.
                const response = await axios.delete('/satisfaction/delete_piece_to_form', {
                    data: {
                        pf_id: pfId.value
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`
                    }
                });
                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    console.log('deleted', response.data);
                    alert('설문구조 삭제가 완료되었습니다.')
                    readPieceToFormList(activeTab.value, eventId.value);
                    pfId.value = 0;
                } else {
                    console.log('사전 설문 폼 생성에 오류가 발생했습니다');
                }
            } catch (error) {
                console.error("사전 설문 폼 생성에 오류가 발생했습니다:", error);
            }
        }

        const toastBool = ref(false);
        // const toastTitle = ref('');
        const showToast = title => {
            // toastTitle.value = title;
            toastBool.value = true;
            readPieceToFormList(activeTab.value, eventId.value);
            setTimeout(() => {
                toastBool.value = false;
                // toastTitle.value = '';
            }, 700);
        }

        const goPrevious = () => {
            if (confirm('리스트로 돌아가면 변경하신 내용이 저장되지 않습니다.')) {
                pfId.value = 0;
                readPieceToFormList(activeTab.value, eventId.value);
            }
        }

        // 설문조사 상세
        const pfId = ref(0); // 설문조사 id값, 리스트에서 선택
        const activeSatisForm = computed(()=>{
            const selected = activeSatisList.value.find(l=>l.pf_id == pfId.value);
            return selected;
        })
        const activeList = computed(() => {
            if (activeTab.value == "BUYER") {
                return surveyBList.value;
            } else if (activeTab.value == "SELLER") {
                return surveySList.value;
            }
        });
        const emptyBool = computed(() => {
            // 각 리스트 비었는지 확인
            if (activeList.value.length < 1) {
                return true;
            } else {
                return false;
            }
        });
        const isRecordBExist = ref(false); // 작성된 상담일지 있는지 체크
        const isRecordSExist = ref(false); // 작성된 상담일지 있는지 체크
        const surveyBList = ref([]); // 바이어 설문구조
        const surveySList = ref([]); // 셀러 설문구조
        const readPieceToQuestionList = async (BIS, event = eventId.value, pf_id) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: event,
                        pts_type: BIS,
                        pf_id: pf_id,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    if (BIS == "BUYER") {
                        surveyBList.value = response.data.question_list;
                        isRecordBExist.value = response.data.is_record_exist;
                        getOptionList(surveyBList.value);
                    } else if (BIS == "SELLER") {
                        surveySList.value = response.data.question_list;
                        isRecordSExist.value = response.data.is_record_exist;
                        getOptionList(surveySList.value);
                    }
                    response.data.question_list.map((item) => {
                        item.is_one_time = 1
                    });
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            } finally {
                pfId.value = pf_id;
            }
        };

        const isRecordExist = computed (()=> {
            // 액티브된 탭의 설문구조 하위에 상담일지 있는지 체크
            if (activeTab.value == 'BUYER') {
                return isRecordBExist.value;
            } else if (activeTab.value == 'SELLER') {
                return isRecordSExist.value;
            }
        })


        // options_list 만듦
        const getOptionList = (paramList) => {
            paramList.map((q, index) => {
                q.option_list = [];
                if (index < paramList.length - 1) {
                    paramList.forEach((qq, idx) => {
                        let option_name = "";
                        if (idx > index) {
                            if (idx == index + 1) {
                                option_name = `${idx + 1}. 다음 선택지로 넘어가기`;
                                q.option_list.push({
                                    key: "next",
                                    name: option_name,
                                });
                            } else {
                                option_name = `${idx + 1}. ${qq.pts_title[activeLang.value]}`;
                                q.option_list.push({
                                    key: idx + 1,
                                    name: option_name,
                                });
                            }
                            if (idx == paramList.length - 1) {
                                q.option_list.push({
                                    key: "finish",
                                    name: `상담일지 끝내기`,
                                });
                            }
                        }
                    });
                } else {
                    q.option_list.push({
                        key: "finish",
                        name: `상담일지 끝내기`,
                    });
                }
            });
        };

        // next_action 만듦
        const getNextAction = (paramList) => {
            paramList.map((q, index) => {
                q.next_action = {};
                if (index < paramList.length - 1) {
                    if (q.sq_type == "SINGLE") {
                        Object.keys(q.pts_answer_form).forEach((key) => {
                            q.next_action[key] = q.option_list[0].key;
                        });
                    } else {
                        q.next_action["0"] = q.option_list[0].key;
                    }
                } else {
                    if (q.sq_type == "SINGLE") {
                        Object.keys(q.pts_answer_form).forEach((key) => {
                            q.next_action[key] = q.option_list[0].key;
                        });
                    } else {
                        q.next_action["0"] = q.option_list[0].key;
                    }
                }
            });
        };

        //sort 변경
        const getSort = (paramList) => {
            paramList.map((q, index) => {
                q.sort = index;
            });
        };

        // 폼 변경, 검사
        const surveyChange = (index, key, value, lang = "") => {
            if (!isRecordExist.value) {
                if (value == "bool") {
                    // 불리엔 토글 모드
                    if (!activeList.value[index][key]) {
                        activeList.value[index][key] = 1;
                    } else {
                        activeList.value[index][key] = 0;
                    }
                } else {
                    activeList.value[index][key][lang] = value;
                }
            }
        };

        const surveyFormChange = (index, idx, lang, value) => {
            activeList.value[index]["pts_answer_form"][idx][lang] = value;
        };

        const surveyDelete = (item, index) => {
            if (confirm("질문을 삭제 후 저장하면 복구할 수 없습니다. 진행하시겠습니까?")) {
                activeList.value.splice(index, 1);
                getOptionList(activeList.value);
                getNextAction(activeList.value);
            }
        };

        const actionChange = (index, idx, selected) => {
            activeList.value[index].next_action[idx] = selected;
        };

        // 팝업
        const showNewQuestion = ref(false);
        const showPreview = ref(false);
        const showOrderQuestion = ref(false);

        const closeAllModals = () => {
            showNewQuestion.value = false;
            showOrderQuestion.value = false;
            showPreview.value = false;
        };

        const modalTypeChange = (props, value) => {
            // 팝업 내에서 props 변경된 경우 emit으로 호출
            if (props == "activeList") {
                if (activeTab.value == "BUYER") {
                    surveyBList.value = value;
                } else if (activeTab.value == "SELLER") {
                    surveySList.value = value;
                }
                getSort(activeList.value);
                getOptionList(activeList.value);
                getNextAction(activeList.value);
            }
        };

        // 질문 추가
        const modalPush = (question) => {
            console.log(question)
            const new_index = {
                sq_answer_form: question.pts_answer_form,
                sq_id: "",
                sq_title: question.pts_title,
                sq_type: question.sq_type,
                is_essential: 0,
                is_must: 1,
                is_one_time: 1,
                lang: Object.keys(question.pts_title),
                list_title: question.pts_title["ko"],
                next_action: {},
                option_list: {},
                pts_answer_form: question.pts_answer_form,
                pts_title: question.pts_title,
                sort: activeList.value.length,
            };
            activeList.value.push(new_index);
            console.log(activeList.value)
            getOptionList(activeList.value);
            getNextAction(activeList.value);
        };

        const cancelDirection = () => {
            // 취소 버튼 누르면 새로고침 해서 변경 정보 날리기
            // 추후 새로고침 대신 데이터 초기화로 변경 필요할지?
            if (confirm("확인을 누르시면 수정내용이 초기화 됩니다. 초기화하시겠습니까?")) {
                location.reload();
            }
        };

        const submitForm = async () => {
            // 콜백으로 프로세스 진행
            // 1. 지울애들 지우고 -> 2. 수정할 애들 수정하고 -> 3. 순서 바뀐거 바꾸고 -> 4. 새로 생긴애들 생성
            const _pfTitle = activeSatisForm.value.pf_title;
            console.log(_pfTitle)
            if (_pfTitle == '') {
                alert('설문조사 제목을 입력해 주세요.');
                activePfTitle.value.focus();
            } else {
                deletePieceToQuestion();
            }
        };

        const readOriginQuestions = async (type, pfId) => {
            // db에 있는 질문 불러오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/satisfaction/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: eventId.value,
                        pts_type: type,
                        pf_id: pfId,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    return response.data.question_list;
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const validateUpdate = async () => {
            // db에 있는 질문이랑 다른거 꼽아내기
            const [s_result, b_result] = await Promise.all([
                readOriginQuestions("SELLER", satisfySList.value[0].pf_id),
                readOriginQuestions("BUYER", satisfyBList.value[0].pf_id)
            ]);
            const origin = [...s_result, ...b_result];
            const update = [...surveyBList.value, ...surveySList.value];
            const ptsList = [];
            const deleteList = [];

            if (origin.length > 0) {
                origin.forEach((ori) => {
                    let upd = update.find((item) => item.pts_id == ori.pts_id);
    
                    if (upd == undefined) {
                        deleteList.push(ori.pts_id);
                        return false;
                    }
    
                    Object.keys(ori["pts_title"]).forEach((key) => {
                        if (ori["pts_title"][key] != upd["pts_title"][key]) {
                            ptsList.push({
                                pts_id: upd.pts_id,
                                pts_title: upd.pts_title,
                                pts_answer_form: upd.pts_answer_form,
                                is_must: upd.is_must,
                                next_action: upd.next_action,
                            });
                            return false;
                        }
                    });
    
                    if (validateCqType(ori["sq_type"])) {
                        for (const [key, value] of Object.entries(ori["pts_answer_form"])) {
                            for (const [key2, value2] of Object.entries(value)) {
                                if (value2 != upd["pts_answer_form"][key][key2]) {
                                    ptsList.push({
                                        pts_id: upd.pts_id,
                                        pts_title: upd.pts_title,
                                        pts_answer_form: upd.pts_answer_form,
                                        is_must: upd.is_must,
                                        next_action: upd.next_action,
                                    });
                                    return false;
                                }
                            }
                        }
                    }
    
                    for (const [key, value] of Object.entries(ori["next_action"])) {
                        if (value != upd["next_action"][key]) {
                            ptsList.push({
                                pts_id: upd.pts_id,
                                pts_title: upd.pts_title,
                                pts_answer_form: upd.pts_answer_form,
                                is_must: upd.is_must,
                                next_action: upd.next_action,
                            });
                            return false;
                        }
                    }
    
                    if (ori["is_must"] != upd["is_must"]) {
                        ptsList.push({
                            pts_id: upd.pts_id,
                            pts_title: upd.pts_title,
                            pts_answer_form: upd.pts_answer_form,
                            is_must: upd.is_must,
                            next_action: upd.next_action,
                        });
                        return false;
                    }
                });
            }

            return { ptsList, deleteList };
        };

        const deletePieceToQuestion = async () => {
            const deleteList = await validateUpdate();
            if (deleteList.deleteList.length > 0) {
                try {
                    // Axios 요청을 보냅니다.
                    let url = `/satisfaction/delete_piece_to_question`;
                    // Axios 요청을 보냅니다.
                    const response = await axios.delete(
                        url,
                        {
                            data: {
                                piece_event_id: eventId.value,
                                pts_id_list: deleteList.deleteList,
                            },
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        alert("질문을 삭제하지 못했습니다.");
                    }
                } catch (error) {
                    console.error("질문 삭제하는 중에 오류가 발생했습니다:", error);
                } finally {
                    updatePieceToQuestion();
                    updatePieceToForm();
                }
            } else {
                console.log("?");
                updatePieceToQuestion();
                updatePieceToForm();
            }
        };

        const updatePieceToQuestion = async () => {
            const ptsList = await validateUpdate();
            if (ptsList.ptsList.length > 0) {
                try {
                    // Axios 요청을 보냅니다.
                    const response = await axios.put(
                        "/satisfaction/update_piece_to_question",
                        {
                            piece_event_id: Number(eventId.value),
                            pts_list: ptsList.ptsList,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        alert("설문을 수정하지 못했습니다.");
                    }
                } catch (error) {
                    console.error("설문을 수정하는 중에 오류가 발생했습니다:", error);
                } finally {
                    promiseOrdered();
                }
            } else {
                promiseOrdered();
            }
        };

        const promiseOrdered = async () => {
            Promise.all([updatePieceToQuestionOrder("SELLER"), updatePieceToQuestionOrder("BUYER")]).then(([s_result, b_result]) => {
                if (s_result == true && b_result == true) {
                    promiseCreate();
                } else {
                    alert(`${s_result}\n${b_result}`);
                }
            });
        }

        const updatePieceToQuestionOrder = async type => {
            const ptsOrderList = [];
            let updateList = [];
            if (type == 'SELLER') {
                updateList = surveySList.value;
            } else if (type == 'BUYER') {
                updateList = surveyBList.value;
            }

            updateList.forEach((item) => {
                if (item.pts_id != "" && item.pts_id != undefined) {
                    ptsOrderList.push({
                        pts_id: item.pts_id,
                        sort: item.sort,
                    });
                }
            });

            if (ptsOrderList.length > 0) {
                try {
                    // Axios 요청을 보냅니다.
                    const response = await axios.put(
                        "/satisfaction/update_piece_to_question_order",
                        {
                            piece_event_id: eventId.value,
                            pts_order_list: ptsOrderList
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return error;
                }
            } else {
                return true;
            }
        };

        const promiseCreate = () => {
            Promise.all([createPieceToQuestionList("SELLER"), createPieceToQuestionList("BUYER")]).then(([s_result, b_result]) => {
                if (s_result == true && b_result == true) {
                    alert("설문구조 수정이 완료되었습니다.");
                    location.reload();
                } else {
                    alert(`${s_result}\n${b_result}`);
                }
            });
        }

        const createPieceToQuestionList = async (type) => {
            let createList = null;
            let pfId = null;
            if (type == "BUYER") {
                createList = surveyBList.value;
                pfId = satisfyBList.value[0].pf_id;
            } else if (type == "SELLER") {
                createList = surveySList.value;
                pfId = satisfySList.value[0].pf_id;
            }
            let request_fq = [];
            let request_oq = [];
            if (createList.length > 0) {
                createList.forEach((item) => {
                    // 리스트 내에 DB생성 안된 질문만(id로 판별) 필터링 해서 집어넣기
                    if (item.pts_id == "" || item.pts_id == undefined) {
                        if (item.sq_is_essential == 0 || item.sq_is_essential == undefined) {
                            request_oq.push({
                                sort: item.sort,
                                is_must: item.is_must,
                                next_action: item.next_action,
                                sq_type: item.sq_type,
                                pts_title: item.pts_title,
                                pts_answer_form: Object.assign({}, item.pts_answer_form),
                            });
                        } else {
                            request_fq.push({
                                sort: item.sort,
                                is_must: item.is_must,
                                next_action: item.next_action,
                                sq_id: item.sq_id,
                                pts_title: item.pts_title,
                                pts_answer_form: Object.assign({}, item.pts_answer_form),
                            });
                        }
                    }
                });
                try {
                    // 전체 URL에 쿼리 스트링을 추가합니다.
                    let url = `/satisfaction/create_piece_to_question_list`;
                    // Axios 요청을 보냅니다.
                    const response = await axios.post(
                        url,
                        {
                            request_fq: request_fq,
                            request_oq: request_oq,
                            piece_event_id: eventId.value,
                            pts_type: type,
                            pf_id: pfId,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );

                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return error;
                }
            } else {
                return true;
            }
        };

        onMounted(async () => {
            if (checkPermissionsAndRedirect()) {
                await loadEventDetails();
            }
        });

        const checkPermissionsAndRedirect = () => {
            if (!isAuthenticated.value) {
                router.push({ name: "home" }); // 비인증 사용자를 홈으로 리다이렉션
                return false;
            } else if (accountType.value !== "A1" && accountType.value !== "A2") {
                alert("접근 권한이 없습니다.");
                router.push({ name: "eventList" }); // 권한이 없는 사용자를 이벤트 리스트로 리다이렉션
                return false;
            }
            return true;
        };

        const loadEventDetails = async () => {
            userName.value = store.state.userStore.userName;
            token.value = store.state.userStore.token;
            eventId.value = router.currentRoute.value.params.eventId;
            store.commit("eventStore/setEventId", eventId.value);

            if (eventId.value) {
                try {
                    await readPieceToFormList('BUYER', eventId.value);
                    await readPieceToFormList('SELLER', eventId.value);
                } catch (error) {
                    console.error("Error loading event details:", error);
                    alert("이벤트를 불러오지 못했습니다.");
                    router.push("/admin/event/select");
                }
            }
        };

        return {
            // 공통
            eventId, // 피스 상담회 pk
            isAuthenticated, // 페이지 접속 권한
            userName, // 유저 네임
            activeSub, // 행사 설정 탭 액티브
            activeHeadTab, // 설정 세부 탭 액티브
            survey_form, // js import
            validateCqType, // sq_type 단일선택 다중선택 boolean
            // 메뉴 내 탭
            activeTab, // BIS 액티브
            isRecordExist, // activeTab에 따라 상담일지 여부 체크 컴퓨티드
            handleActive, // BIS 액티브 함수
            checkedLang, // 추가된 언어페어 리스트
            availableLang, // 모든 언어페어 리스트
            activeLang, // 언어페어 액티브
            // 언어탭 추가
            openLangDropDown, // 언어탭 드롭다운
            oLDFocus, // 드롭다운 포커스용 인풋
            openoLD, // 열고 닫기 함수
            findAvailable, // 드롭다운 내 체크 여부
            checkAvailableLang, // 언어탭 체크
            alignedLang, // 언어탭 체크
            // 사전설문 다중구조 리스트
            activeSatisList,
            activePfTitle,
            readPieceToFormList,
            createPieceToFormList,
            deletePieceToForm,
            showToast,
            goPrevious,
            // toastTitle,
            toastBool,
            // 설문구조 리스트
            pfId,
            readPieceToQuestionList,
            activeSatisForm,
            activeList, // activeTab에 따라 surveyXList 반환 컴퓨티드
            emptyBool, // activeList가 비어있는지 boolean
            isRecordBExist, // 작성된 상담일지 있는지 체크
            isRecordSExist, // 작성된 상담일지 있는지 체크
            surveyBList, // 바이어 설문구조
            surveySList, // 셀러 설문구조
            surveyChange, // for문 내 pts_answer_form 제외 모든 필드 수정용 함수
            surveyFormChange, // for문 내 pts_answer_form 필드 수정용 함수
            surveyDelete, // 질문 삭제 버튼
            actionChange, // nextaction 변경 시 함수
            // 팝업
            showNewQuestion, // 새 질문 추가하기  boolean
            showOrderQuestion, // 질문 순서 변경하기 boolean
            showPreview, // 질문 미리보기 boolean
            closeAllModals, // 모든 팝업 닫힘처리
            modalTypeChange, // 팝업 타입 변경 함수
            // 질문 추가
            modalPush, // 새 질문 activeList 추가 함수
            // 서브밋
            cancelDirection, // 최하단 취소 누를 시 confirm 후 새로고침
            submitForm, // 최하단 저장 버튼
            validateUpdate,
        };
    }
}
</script>
<template>
    <div id="surveySetting" class="back_light_gray2">
        <sub-header :activeSub="activeSub"></sub-header>
        <div class="mx_1160">
            <div class="header_tab_wrap">
                <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
            </div>
            <template v-if="pfId != 0">
                <div class="ds_flex jus_between al_center">
                    <a href="javascript:void(0)" @click="goPrevious()" class="ds_flex gap12 al_center">
                        <i class="icon-down_arrow goback size_24 sub_text"></i>
                        <div class="size_22 b_700 cs_pointer secondary_text">리스트 보기</div>
                    </a>
                    <div v-show="!emptyBool" class="ds_flex gap14">
                        <p
                            class="inline_cont_btn hover_back_btn"
                            @click="
                                showPreview = false;
                                showOrderQuestion = true;
                            "
                        >
                            질문 순서 변경하기
                        </p>
                        <p
                            class="icon_cont_btn hover_back_btn"
                            @click="
                                showPreview = true;
                                showOrderQuestion = false;
                            "
                        >
                            <i class="icon-preview"></i>구조 미리보기
                        </p>
                    </div>
                </div>
                <div class="lang_tab">
                    <p v-for="lang in alignedLang" :key="lang" :class="[activeLang == lang ? 'active' : '', 'cs_pointer']" @click="activeLang = lang">{{ survey_form.return_language(lang) }}</p>
                    <p v-if="!isRecordExist" class="plus fake_dropdown_wrap">
                        <i class="icon-plus" @click="openoLD"></i>
                        <transition name="dropdown">
                            <div class="fake_dropdown_options" v-if="openLangDropDown" style="width: 140px; top: 0; left: 100%">
                                <div v-for="lang in availableLang" class="fake_option cs_pointer" :key="lang">
                                    <label class="fake_checkbox" :for="`check_for_${lang}`" :class="{ active: findAvailable(lang) }">
                                        <i v-if="findAvailable(lang)" class="icon-checked white_text"></i>
                                    </label>
                                    <label class="fake_label" :for="`check_for_${lang}`">{{ survey_form.return_language(lang) }}</label>
                                    <input type="checkbox" :id="`check_for_${lang}`" :name="`check_for_${lang}`" @change="checkAvailableLang(lang)" />
                                </div>
                                <div class="btn_wrap">
                                    <div class="dropdown_submit active_btn" @click="openLangDropDown = false">완료</div>
                                </div>
                            </div>
                        </transition>
                    </p>
                </div>
                <div class="survey_flow" :class="activeLang == alignedLang[0] ? 'border' : ''">
                    <div class="pf_title_wrap">
                        <div class="pf_title_icon"><i class="icon-flow"></i></div>
                        <input type="text" class="pf_title" ref="activePfTitle" placeholder="설문지 제목을 입력하세요" v-model="activeSatisForm.pf_title">
                    </div>
                    <template v-if="!emptyBool">
                        <div v-for="(item, index) in activeList" :key="index" class="survey_flow_inner">
                            <div class="survey_flow_top">
                                <div class="flow_title">
                                    <div class="num">{{ index + 1 }}</div>
                                    <input type="text" :id="`pts_title_${index}`" :value="item.pts_title[activeLang]" @keyup="surveyChange(index, 'pts_title', $event.target.value, activeLang)" :disabled="isRecordSExist"/>
                                </div>
                                <div class="flow_check">
                                    <div class="flow_check_inner" :class="{ disabled: isRecordExist }">
                                        <label class="secondary_text cs_pointer" :for="`is_must_${index}`">필수 질문</label>
                                        <label class="fake_checkbox" :for="`is_must_${index}`" :class="{ active: item.is_must == 1 }">
                                            <i v-if="item.is_must == 1" class="icon-checked"></i>
                                        </label>
                                        <input
                                            type="checkbox"
                                            :id="`is_must_${index}`"
                                            @change="surveyChange(index, 'is_must', 'bool')"
                                            :true-value="1"
                                            :false-value="0"
                                            :value="item.is_must"
                                        />
                                    </div>
                                    <div v-if="!isRecordExist" class="flow_delete" @click="surveyDelete(item, index)"><i class="icon-trash size_20 sub_text"></i></div>
                                </div>
                            </div>
                            <div class="survey_flow_bottom">
                                <template v-if="item.sq_type == 'SINGLE'">
                                    <div v-for="(itm, idx) in item.pts_answer_form" :key="idx" class="ds_flex al_center gap36">
                                        <template v-if="item.is_one_time == 1">
                                            <label :for="`pts_sq_answer_${idx}`" class="current_actions" :class="{'disabled' : isRecordExist}">
                                                <input type="text" :id="`pts_sq_answer_${idx}`" :value="itm[activeLang]" @keyup="surveyFormChange(index, idx, activeLang, $event.target.value)" :disabled="isRecordExist"/>
                                                <i :class="`icon-q_${item.sq_type} not_entered_text`"></i>
                                            </label>
                                        </template>
                                        <template v-else>
                                            <div class="current_actions disabled">
                                                <p class="cont">{{ itm[activeLang] }}</p>
                                                <i :class="`icon-q_${item.sq_type} not_entered_text`"></i>
                                            </div>
                                        </template>
                                        <drop-down-next
                                            :options="item.option_list"
                                            :defaultValue="item.next_action[idx]"
                                            :disabled="index == activeList.length - 1"
                                            @change="
                                                (selected) => {
                                                    actionChange(index, idx, selected);
                                                }
                                            "
                                        ></drop-down-next>
                                        <!-- <div class="delete">
                                            <i class="icon-delete sub_text" :style="item.is_one_time == 1 ? '' : 'color: #fff;'"></i>
                                        </div> -->
                                    </div>
                                </template>
                                <template v-else-if="item.sq_type == 'MULTIPLE'">
                                    <div v-for="(itm, idx) in item.pts_answer_form" :key="idx" class="ds_flex al_center gap36">
                                        <template v-if="item.is_one_time == 1">
                                            <label :for="`pts_title_${idx}`" class="current_actions" :class="{'disabled' : isRecordExist}">
                                                <input type="text" :id="`pts_title_${idx}`" :value="itm[activeLang]" @keyup="surveyFormChange(index, idx, activeLang, $event.target.value)" :disabled="isRecordExist"/>
                                                <i :class="`icon-q_${item.sq_type} not_entered_text`"></i>
                                            </label>
                                        </template>
                                        <template v-else>
                                            <div class="current_actions disabled">
                                                <p class="cont">{{ itm[activeLang] }}</p>
                                                <i :class="`icon-q_${item.sq_type} not_entered_text`"></i>
                                            </div>
                                        </template>
                                        <drop-down-next
                                            :options="item.option_list"
                                            :defaultValue="item.next_action[0]"
                                            :disabled="index == activeList.length - 1 || isRecordExist"
                                            @change="
                                                (selected) => {
                                                    actionChange(index, 0, selected);
                                                }
                                            "
                                        ></drop-down-next>
                                        <!-- <div class="delete">
                                            <i class="icon-delete sub_text" :style="item.is_one_time == 1 ? '' : 'color: #fff;'"></i>
                                        </div> -->
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="ds_flex al_center gap36 not_entered_text">
                                        <div class="current_actions disabled">
                                            <p class="cont">
                                                <template v-if="item.sq_type == 'SHORT' || item.sq_type == 'LONG'">답변을 입력하세요.</template>
                                                <template v-else-if="item.sq_type == 'NUMBER'">숫자를 입력하세요.</template>
                                                <template v-else-if="item.sq_type == 'FILE'">파일을 첨부하세요.</template>
                                            </p>
                                            <i :class="`icon-q_${item.sq_type} not_entered_text`"></i>
                                        </div>
                                        <drop-down-next
                                            :options="item.option_list"
                                            :defaultValue="item.next_action[0]"
                                            :disabled="index == activeList.length - 1 || isRecordExist"
                                            @change="
                                                (selected) => {
                                                    actionChange(index, 0, selected);
                                                }
                                            "
                                        ></drop-down-next>
                                        <!-- <div class="delete">
                                            <i class="icon-delete sub_text" :style="item.is_one_time == 1 ? '' : 'color: #fff;'"></i>
                                        </div> -->
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                    <p v-if="!isRecordExist" class="add_new_question" style="margin-top: 28px;" @click="showNewQuestion = true">새 질문 추가하기 +</p>
                </div>
                <div v-if="!isRecordExist" class="btn_wrap">
                    <div class="ds_flex gap16">
                        <div class="inline_main_btn cancel_btn b_700" @click="cancelDirection">취소</div>
                        <div class="inline_main_btn active_btn b_700" @click="submitForm">저장</div>
                    </div>
                    <div v-if="!emptyBool" class="inline_main_btn cancel_btn b_700" @click="deletePieceToForm(pfId)">삭제</div>
                </div>
            </template>
            <template v-else>
                <div class="ds_flex jus_between al_center">
                    <div class="bis_tab">
                        <p class="inline_sub_btn" :class="{ active: activeTab == 'BUYER' }" @click="handleActive('BUYER')">바이어</p>
                        <p class="inline_sub_btn" :class="{ active: activeTab == 'SELLER' }" @click="handleActive('SELLER')">셀러</p>
                    </div>
                    <div class="ds_flex">
                        <p
                            class="icon_cont_btn hover_back_btn"
                            @click="createPieceToFormList(activeTab, eventId)"
                        >
                            설문 추가하기
                        </p>
                    </div>
                </div>
                <template v-if="activeSatisList.length > 0">
                    <div class="user_list">
                        <div class="user_list_item" v-for="item in activeSatisList" :key="item.pf_id">
                            <div class="item_left cs_pointer" @click="readPieceToQuestionList(activeTab, eventId, item.pf_id)">
                                <p class="item_status back_primary">
                                    <i class="icon-flow"></i>
                                </p>
                                <div class="item_main">
                                    <template v-if="item.pf_title != ''">
                                        <p class="item_title">{{ item.pf_title }}</p>
                                    </template>
                                    <template v-else>
                                        <p class="item_title not_entered_text">아직 제목이 없는 설문조사입니다. 제목을 채워주세요!</p>
                                    </template>
                                </div>
                            </div>
                            <div class="item_right">
                                <p class="item_preview" @click="showQuestionPreview(item.cq_id)">
                                    <i class="icon-preview"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="ds_flex dir_column jus_center al_center" :style="`height: ${windowSize.height - 600}px; min-height: 300px`">
                        <img src="@/assets/img/survey/no_survey.png" />
                        <p class="primary_text size_18 b_700" style="margin-top: 20px">저장된 설문지 구조가 없어요.</p>
                        <p class="secondary_text t_center" style="margin-top: 16px">바이어 설문 조사를 위한 설문지를 생성하세요.</p>
                        <button class="inline_main_btn eqqui_btn b_700" style="margin-top: 26px" @click="createPieceToFormList(activeTab, eventId)">설문지 생성하기</button>
                    </div>
                </template>
            </template>
        </div>
        <satisfy-setting-modal
            :BIS="activeTab"
            :show="{ showNewQuestion: showNewQuestion, showPreview: showPreview, showOrderQuestion: showOrderQuestion }"
            :checked-lang="alignedLang"
            :activeList="activeList"
            :height="windowSize.height"
            @close="closeAllModals"
            @change="modalTypeChange"
            @push="modalPush"
        ></satisfy-setting-modal>
        <transition name="fade-alert">
            <div class="toast" v-if="toastBool">
                <div class="icon_box back_main"><i class="icon-checked"></i></div>
                <p>설문조사를 생성했어요.</p>
                <!-- <p>{{toastTitle}}</p> -->
            </div>
        </transition>
    </div>
</template>