<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import language from '@/assets/js/bisatLang'
import field from '@/assets/js/bisatField'
import { useToast } from 'vue-toastification';

export default {
  name: 'BISMyAccount',
  components: {
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const toast = useToast(); // Toast 호출 함수
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userName, userId, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;
    const userEmail = ref('')

    const userInfo = ref([])
    async function fetchUserInfo() {
      try {
        const response = await axios.get('/user/read_account_detail_info_for_bis', {
          params: {
            'piece_event_id': eventId.value,
            'account_id': userId.value,
            'account_type': userType.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userInfo.value = response.data.info

        } else {
          console.log("사용자 정보 로드 실패")
        }
      } catch (error) {
      }
    }

    const editMode = ref(false)
    const languageSelect = language.map(item => ({
      label: item.label,
      value: item.code
    }))
    const fieldSelect = field.map(item => ({
      label: item.label,
      value: item.code
    }))
    const getLanguageLabel = (value) => {
      const selectedLanguage = language.find(item => item.code === value);
      return selectedLanguage ? selectedLanguage.label : value;
    };

    const updateUserInfo = async () => {
      const data = {
        account_id: userId.value,
        full_name: userInfo.value.full_name,
        contact_email: userInfo.value.contact_email,
        mobile: userInfo.value.mobile,
        company: userInfo.value.company,
        lang1: userInfo.value.lang1,
        lang2: userInfo.value.lang2,
        eqqui_profile: userInfo.value.eqqui_profile,
        bank_name: userInfo.value.bank_name,
        bank_ac_holder: userInfo.value.bank_ac_holder,
        bank_ac_number: userInfo.value.bank_ac_number,
      }
      const url = '/user/update_account_detail_info_for_bis';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          toast.success('내 정보가 성공적으로 변경되었습니다.');
          editMode.value = false
          await fetchUserInfo();
        } else {
          toast.alert(error);
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }
    const getCategoryLabel = (value) => {
      const selectedField = field.find(item => item.code === value);
      return selectedField ? selectedField.label : value;
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        userEmail.value = store.state.userStore.email;
        await fetchUserInfo()
      }
    });
    return {
      router,
      userInfo,
      formattedUserType,
      userType,
      userName,
      userEmail,
      getLanguageLabel,
      editMode,
      languageSelect,
      fieldSelect,
      updateUserInfo,
      getCategoryLabel
    };
  },
};
</script>

<template>
  <div class="no_header">
    <div class="my_account">
      <div class="page_title ds_flex jus_between al_center">
        <div class="ds_flex gap8 al_center">
          <p class="icon_box" @click="router.push({ name: 'BISDashboard' })"><i
              class="icon-down_arrow rotate90 size_28"></i></p>
          <p class="size_22 b_700">내 정보</p>
        </div>
        <p class="icon_cont_btn secondary_text size_14" @click="editMode = true"><i class="icon-edit sub_text"></i>수정
        </p>
      </div>
      <div class="field_wrap">
        <div class="top">
          <div class="ds_flex dir_column gap4">
            <div class="ds_flex jus_between al_center">
              <p class="size_14" :class="`${userType}_text`">{{ formattedUserType }}</p>
              <a class="user_type_bdg size_12 back_light_gray sub_text" v-if="userInfo.company_homepage"
                :href="userInfo.company_homepage" target="_blank">홈페이지</a>
            </div>
            <p class="size_24 b_700">{{ userName }}</p>
            <p class="sub_text">{{ userEmail }}</p>
          </div>
          <p class="user_type_bdg size_12 product" v-if="userType !== 'INTERPRETER'">#{{ userInfo.product ?
            userInfo.product : "-" }}</p>
        </div>
        <div class="content" v-if="userType === 'INTERPRETER'">
          <section class="cont1 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기본정보</p>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">이름</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.full_name" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.full_name }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">이메일</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.contact_email" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.contact_email }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">연락처</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.mobile" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.mobile }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">통역언어</p>
              <template v-if="editMode">
                <div class="ds_flex dir_column gap12">
                  <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.lang1" />
                  <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.lang2" />
                </div>
              </template>
              <template v-else>
                <p class="b_600">{{ getLanguageLabel(userInfo.lang1) }}<template v-if="userInfo.lang2">, {{
                  getLanguageLabel(userInfo.lang2) }}</template>
                </p>
              </template>
            </div>
          </section>
          <hr />
          <section class="cont2 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">세부정보</p>
            <div class="text_box_wrap ds_flex dir_column gap12">
              <div class="text_box" :class="{ 'back_light_gray3': !editMode }">
                <p class="size_14 secondary_text">에퀴코드</p>
                <template v-if="editMode">
                  <p class="fake_textbox"><span class="sub_text url">www.eqqui.com/profile/</span><input type="text"
                      v-model="userInfo.eqqui_profile" /></p>
                </template>
                <template v-else>
                  <p class="b_600 main_text">https://www.eqqui.com/profile/{{ userInfo.eqqui_profile }}</p>
                </template>
              </div>
              <div class="text_box bank" :class="{ 'back_light_gray3': !editMode }">
                <p class="size_14 secondary_text">입금정보</p>
                <template v-if="editMode">
                  <p class="fake_textbox"><span class="sub_text size_14">예금주</span><input type="text"
                      v-model="userInfo.bank_ac_holder" /></p>
                  <p class="fake_textbox"><span class="sub_text size_14">은행명</span><input type="text"
                      v-model="userInfo.bank_name" /></p>
                  <p class="fake_textbox"><span class="sub_text size_14">계좌번호</span><input type="text"
                      v-model="userInfo.bank_ac_number" /></p>
                </template>
                <template v-else>
                  <p class="b_600">{{ userInfo.bank_ac_holder }}</p>
                  <div class="ds_flex gap8 al_center">
                    <p class="size_14 secondary_text">{{ userInfo.bank_name }}</p>
                    <p class="size_14 secondary_text">|</p>
                    <p class="size_14 secondary_text">{{ userInfo.bank_ac_number }}</p>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </div>
        <div class="content" v-else>
          <section class="cont1 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기업 정보</p>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">기업명</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.company" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.company }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">담당자</p>
              <template v-if="editMode">
                <div class="ds_flex dir_column gap12">
                  <p class="fake_textbox"><input type="text" v-model="userInfo.full_name" /></p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.contact_email" /></p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.mobile" /></p>
                </div>
              </template>
              <template v-else>
                <div class="text_box" :class="{ 'back_light_gray3': !editMode }">
                  <p class="size_14 secondary_text">{{ userInfo.full_name }}</p>
                  <p class="size_14 secondary_text">{{ userInfo.contact_email }} | {{ userInfo.mobile }}</p>
                </div>
              </template>
            </div>
          </section>
          <hr />
          <section class="cont2 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기업세부정보</p>
            <div class="text_box_wrap ds_flex dir_column gap20">
              <template v-if="editMode">
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개</p>
                  <textarea class="fake_textbox" v-model="userInfo.company_explain"></textarea>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">홈페이지</p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.company_homepage" /></p>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개서</p>
                  <p class="block_main_btn file">파일 첨부하기</p>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">분야</p>
                  <v-select :options="fieldSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.category" />
                </div>
              </template>
              <template v-else>
                <div class="text_box back_light_gray3">
                  <p class="b_600">{{ userInfo.company_explain }}</p>
                  <div class="ds_flex jus_between">
                    <p class="size_14 secondary_text">기업소개서</p>
                    <a class="size_14 main_text" v-if="userInfo.company_file && userInfo.company_file.file_url"
                      :href="userInfo.company_file.file_url" target="_blank">
                      {{ userInfo.company_file.file_name ? userInfo.company_file.file_name : '-' }}
                    </a>
                    <span v-else>-</span>
                  </div>
                  <div class="ds_flex jus_between">
                    <p class="size_14 secondary_text">관심분야</p>
                    <p class="size_14 b_600">{{ getCategoryLabel(userInfo.category) }}</p>
                  </div>
                </div>
              </template>
            </div>
          </section>
        </div>
      </div>
      <p v-if="editMode" class="block_main_btn save" @click="updateUserInfo">저장하기</p>
    </div>
  </div>
</template>
