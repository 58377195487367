<script>
import { ref, onMounted, computed, inject, watch, watchEffect, reactive } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import scheduleModal from '@/components/admin/eventSettingTablesModal.vue';

export default {
  name: 'eventSettingTables',
  props: {
    event: Object
  },
  components: {
    Pagination,
    subHeader,
    tabHeader,
    scheduleModal
  },
  setup(props) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const is_video_link = computed(() => store.state.eventStore.isActiveOnlineLink);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('setting'); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref('schedule'); // 여기에서 'schedule'이라는 값을 정의합니다.

    //이벤트 일시 관련 변수
    const { eventTitle, selectDate, handleSelectDate } = props.event;
    const eventDateList = ref([]);

    const eventDateTime = ref([])

    //이벤트 부스 관련 변수 (Y축)
    const yList = ref([])

    // 스케줄 관련 변수
    const schedule = ref([])

    // 모달 관련 변수
    const showBoothSideModal = ref(false);
    const showScheduleUploadModal = ref(false);
    const showScheduleAddModal = ref(false);
    const selectedScheduleData = ref({});

    const closeAllModals = (type) => {
      showBoothSideModal.value = false;
      showScheduleUploadModal.value = false;
      showScheduleAddModal.value = false;
    };

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수

    const totalPages = computed(() => Math.ceil(yList.value.length / itemsPerPage.value));
    const paginatedYList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return yList.value.slice(start, end);
    });
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };

    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0]
          }
          fetchDateTime()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP2. 이벤트 세팅 시 설정한 상담회 날짜, 시간 불러오기 (X축 생성)
    async function fetchDateTime() {
      store.commit('eventStore/setEventDate', selectDate.value)
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
          limit: 30,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateTime.value = response.data.datetime.map(item => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
          }));
          if (selectedType.value == 'booth_name') {
            fetchBoothList();
          } else if (selectedType.value == 'buyer_name') {
            fetchBISList('buyer');
          } else if (selectedType.value == 'seller_name') {
            fetchBISList('seller');
          } else if (selectedType.value == 'interpreter_name') {
            fetchBISList('interpreter');
          }
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP3. 부스 불러오기 (Y축 생성)
    async function fetchBoothList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_booths?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          yList.value = response.data.booths;
          fetchSchedule();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP3. 기준으로 보기 추가 - BIS 불러오기 (Y축 생성)
    async function fetchBISList(type) {
      try {
        const queryParams = new URLSearchParams();
        // 선택된 타입에 따라 쿼리 파라미터 추가
        if (type === 'buyer') {
          queryParams.append('account_type_list', 'buyer');
        } else if (type === 'interpreter') {
          queryParams.append('account_type_list', 'interpreter');
        } else if (type === 'seller') {
          queryParams.append('account_type_list', 'seller');
        }
        queryParams.append('piece_event_id', eventId.value);
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          yList.value = response.data.user_list;
          fetchSchedule();
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // STEP4. 스케줄 불러오기
    async function fetchSchedule() {
      try {
        let fetchedCount = 0; // 현재까지 가져온 데이터 수
        const limit = 100; // 요청당 가져올 데이터 수
        let totalCount = 0; // 전체 데이터 수
        let page = 1

        schedule.value = []; // 스케줄 초기화

        do {
          const queryParams = new URLSearchParams({
            piece_event_id: eventId.value,
            date: selectDate.value,
            limit,
            page
          });

          if (searchBy.value) {
            queryParams.append('search_by', searchBy.value);
          }
          if (searchText.value) {
            queryParams.append('search_text', searchText.value);
          }

          const url = `/schedule/read_piece_to_schedule?${queryParams.toString()}`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });

          // 서버로부터 받은 전체 데이터의 개수 저장
          totalCount = response.data.count_info.total;

          if (totalCount >= 0) {
            // 이미 가져온 데이터에 새로운 데이터를 추가
            schedule.value = [...schedule.value, ...response.data.schedule];

            // 가져온 데이터 수 업데이트
            fetchedCount += response.data.schedule.length;
            page += 1;
          } else {
            alert('에러');
            break;
          }

        } while (fetchedCount < totalCount); // 가져온 데이터가 전체 데이터보다 적을 때 반복

      } catch (error) {
        console.error('스케줄 불러올때 오류:', error);
      }
    }


    const openScheduleAddModal = (booth, time, isSchedule) => {
      selectedScheduleData.value = { booth, time, isSchedule };
      showScheduleAddModal.value = true;
    }

    const getSchedules = (ptb_id, ptd_id) => {
      let schedules = schedule.value.filter(s => s.ptb_id === parseInt(ptb_id) && s.ptd_id === parseInt(ptd_id));
      // onlyInterpreter가 true일 경우 INTERPRETER로 필터링
      if (onlyInterpreter.value) {
        schedules = schedules.filter(s => s.created_by_type === 'INTERPRETER');
      }
      return schedules
    };

    const getSchedulesByBIS = (account_id, ptd_id, BIS) => {
      // BIS별로 보기 가공 - fetchSchedule에서 뽑은 데이터의 회원 정보와 스케줄 정보가 동일한지 확인
      if (BIS == 'buyer') {
        let schedules = schedule.value.filter(s => s.buyer_id === parseInt(account_id) && s.ptd_id === parseInt(ptd_id));
        if (onlyInterpreter.value) {
          schedules = schedules.filter(s => s.created_by_type === 'INTERPRETER');
        }
        return schedules
      } else if (BIS == 'seller') {
        let schedules = schedule.value.filter(s => s.seller_id === parseInt(account_id) && s.ptd_id === parseInt(ptd_id));
        if (onlyInterpreter.value) {
          schedules = schedules.filter(s => s.created_by_type === 'INTERPRETER');
        }
        return schedules
      } else if (BIS == 'interpreter') {
        let schedules = schedule.value.filter(s => s.interpreter_id === parseInt(account_id) && s.ptd_id === parseInt(ptd_id));
        if (onlyInterpreter.value) {
          schedules = schedules.filter(s => s.created_by_type === 'INTERPRETER');
        }
        return schedules
      }
    };

    const scheduleDownload = async () => {
      try {
        const response = await axios.get(`/schedule/download_schedules_table?piece_event_id=${eventId.value}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob' // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'schedule_table.xlsx'); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    }

    const changeBoothMode = ref([false]);

    const updateBooth = async (ptb_id, booth_name) => {
      try {
        const response = await axios.put('/schedule/update_booths',
          {
            ptb_id: ptb_id,
            booth_name: booth_name
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          },
        );
        if (response.data.result === true) {
          alert("저장되었습니다.");
          router.go()
        } else {
          console.log('Update failed:', response.data.result);
        }
      } catch (error) {
        console.error('Update failed:', error);
      }
    };

    const deleteBooth = async (ptb_id) => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/schedule/delete_booth?ptb_id=${ptb_id}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go();
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };

    // FUNCTION BAR
    const onlyInterpreter = ref(false)
    const selectedType = ref('booth_name')
    const searchBy = ref('booth_name')
    const searchText = ref('')
    const searchByLabel = (searchBy) => {
      switch (searchBy) {
        case 'booth_name':
          return "부스명"
        case 'buyer_name':
          return "바이어명"
        case 'interpreter_name':
          return "통역사명"
        case 'seller_name':
          return "셀러명"
        default:
          return "-"
      }
    }

    // 전체 선택/해제 토글 함수
    const toggleSelectOnlyInterpreter = () => {
      const newValue = !onlyInterpreter.value;
      onlyInterpreter.value = newValue;
    };

    // 화상링크 자동생성 일괄
    const createAllVideoLink = async () => {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value
        });
        const url = `/schedule/create_piece_to_all_sched_video_link?${queryParams.toString()}`;
        const response = await axios.post(url, {}, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          alert("일괄 자동생성을 성공했습니다.")
        } else {
          alert('일괄 자동생성을 실패했습니다.');
        }
      } catch (error) {
        console.error('Error creating video link:', error);
      }
    }



    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate()
      }
    });

    return {
      activeSub,
      activeHeadTab,
      eventId,
      eventDateList,
      fetchDateTime,
      selectDate,
      handleSelectDate,
      eventDateTime,
      yList,
      getSchedules,
      getSchedulesByBIS,
      showBoothSideModal,
      showScheduleUploadModal,
      showScheduleAddModal,
      openScheduleAddModal,
      selectedScheduleData,
      closeAllModals,
      scheduleDownload,
      currentPage,
      itemsPerPage,
      totalPages,
      paginatedYList,
      handleClickPage,
      changeBoothMode,
      updateBooth,
      deleteBooth,
      fetchSchedule,
      onlyInterpreter,
      toggleSelectOnlyInterpreter,
      selectedType,
      searchBy,
      searchText,
      searchByLabel,
      is_video_link,
      createAllVideoLink
    };
  },
}

</script>

<template>
  <div id="eventSettingTables">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <div class="header_tab gap18">
          <p class="inline_cont_btn" @click="showBoothSideModal = true">부스 추가</p>
          <p class="inline_cont_btn" @click="showScheduleUploadModal = true">스케줄 업로드</p>
        </div>
      </div>
      <div class="tables_wrap eventSettingTables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="handleSelectDate">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="scheduleDownload"><i class="icon-download"></i></p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="booth_name">부스명</option>
                  <option value="buyer_name">바이어명</option>
                  <option value="interpreter_name">통역사명</option>
                  <option value="seller_name">셀러명</option>
                </select>
                <input type="text" v-model="searchText" :placeholder="`${searchByLabel(searchBy)} 검색어 입력`"
                  @keyup.enter="fetchSchedule()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = ''; fetchSchedule()"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="user_table_top_cont ds_flex al_center">
              <label class="fx_name">기준으로 보기</label>
              <div class="radio_wrap back_light_gray">
                <div class="radio_option">
                  <input type="radio" id="radio_booth" name="user_type" value="booth_name" v-model="selectedType"
                    @change="fetchDateTime()" />
                  <label for="radio_booth">부스</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_buyer" name="user_type" value="buyer_name" v-model="selectedType"
                    @change="fetchDateTime()" />
                  <label for="radio_buyer">바이어</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_seller" name="user_type" value="seller_name" v-model="selectedType"
                    @change="fetchDateTime()" />
                  <label for="radio_seller">셀러</label>
                </div>
                <div class="radio_option">
                  <input type="radio" id="radio_interpreter" name="user_type" value="interpreter_name"
                    v-model="selectedType" @change="fetchDateTime()" />
                  <label for="radio_interpreter">통역사</label>
                </div>
              </div>
              <!-- <p class="inline_cont_btn size_14 sub_text video" v-if="is_video_link === 'Y'" @click="createAllVideoLink">화상링크 일괄생성</p> -->
            </div>
            <div class="ds_flex gap8 al_center">
              <label class="function_name size_14 b_700 sub_text" for="onlyInterpreter">통역사 추가 상담만 보기</label>
              <label for="onlyInterpreter" class="fake_checkbox" :class="{ 'active': onlyInterpreter }">
                <i v-if="onlyInterpreter" class="icon-checked"></i>
              </label>
              <input type="checkbox" id="onlyInterpreter" v-model="onlyInterpreter" @change="fetchDateTime()" />
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table>
            <thead>
              <tr>
                <th>{{ searchByLabel(selectedType) }}<span>▾</span></th>
                <th v-for="date in eventDateTime" :key="date.ptd_id">{{ date.start_time }}~{{ date.end_time }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedYList.length > 0">
                <!-- 부스 기준 -->
                <template v-if="selectedType === 'booth_name'">
                  <tr v-for="(booth, index) in paginatedYList" :key="index">
                    <td class="booth" :class="{ 'edit': changeBoothMode[index] }">
                      <template v-if="!changeBoothMode[index]">
                        {{ booth.booth_name }}
                        <p class="icon_box" @click="changeBoothMode[index] = true"><i class="icon-edit"></i></p>
                      </template>
                      <template v-else>
                        <input type="text" class="fake_textbox" v-model="booth.booth_name" />
                        <div class="ds_flex">
                          <p class="icon_box" @click="deleteBooth(booth.ptb_id)"><i class="icon-trash"></i></p>
                          <p class="icon_box active" @click="updateBooth(booth.ptb_id, booth.booth_name)">저장</p>
                        </div>
                      </template>
                    </td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                        <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                          :key="schedule.schedule_id" @click="openScheduleAddModal(booth, time, schedule.schedule_id)">
                          <p class="schedule_info"><span>{{ schedule.created_by_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">B</span><span>{{ schedule.buyer_company
                              }} {{
                                schedule.buyer_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">I</span><span>{{
                            schedule.interpreter_name }}
                            </span></p>
                          <p class="schedule_user_info"><span class="type_bdg">S</span><span>{{ schedule.seller_company
                              }} {{
                                schedule.seller_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">FM</span><span>{{ schedule.manager_name
                              }}</span></p>
                        </div>
                        <p class="size_12 sub_text add_btn" @click="openScheduleAddModal(booth, time, false)">+ 추가</p>
                      </template>
                      <template v-else>
                        <p class="add_schedule" @click="openScheduleAddModal(booth, time, false)">+ 추가</p>
                      </template>
                    </td>
                  </tr>
                </template>
                <!-- 바이어 기준 -->
                <template v-if="selectedType === 'buyer_name'">
                  <tr v-for="buyer in paginatedYList" :key="buyer.account_id">
                    <td class="booth">{{ buyer.company }}</td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedulesByBIS(buyer.account_id, time.ptd_id, 'buyer').length">
                        <div class="schedule_info_wrap"
                          v-for="schedule in getSchedulesByBIS(buyer.account_id, time.ptd_id, 'buyer')"
                          :key="schedule.schedule_id"
                          @click="openScheduleAddModal(schedule, time, schedule.schedule_id)">
                          <p class="schedule_info"><span>{{ schedule.ptb }}/{{ schedule.created_by_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">I</span><span>{{
                            schedule.interpreter_name }}
                            </span></p>
                          <p class="schedule_user_info"><span class="type_bdg">S</span><span>{{ schedule.seller_company
                              }} {{
                                schedule.seller_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">FM</span><span>{{ schedule.manager_name
                              }}</span></p>
                        </div>
                        <p class="size_12 sub_text add_btn" @click="openScheduleAddModal(schedule, time, false)">+ 추가
                        </p>
                      </template>
                      <template v-else>
                        <p class="add_schedule" @click="openScheduleAddModal(schedule, time, false)">+ 추가</p>
                      </template>
                    </td>
                  </tr>
                </template>
                <!-- 셀러 기준 -->
                <template v-if="selectedType === 'seller_name'">
                  <tr v-for="seller in paginatedYList" :key="seller.account_id">
                    <td class="booth">{{ seller.company }}</td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedulesByBIS(seller.account_id, time.ptd_id, 'seller').length">
                        <div class="schedule_info_wrap"
                          v-for="schedule in getSchedulesByBIS(seller.account_id, time.ptd_id, 'seller')"
                          :key="schedule.schedule_id"
                          @click="openScheduleAddModal(schedule, time, schedule.schedule_id)">
                          <p class="schedule_info"><span>{{ schedule.ptb }}/{{ schedule.created_by_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">B</span><span>{{ schedule.buyer_company
                              }} {{
                                schedule.buyer_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">I</span><span>{{
                            schedule.interpreter_name }}
                            </span></p>
                          <p class="schedule_user_info"><span class="type_bdg">FM</span><span>{{ schedule.manager_name
                              }}</span></p>
                        </div>
                        <p class="size_12 sub_text add_btn" @click="openScheduleAddModal(schedule, time, false)">+ 추가
                        </p>
                      </template>
                      <template v-else>
                        <p class="add_schedule" @click="openScheduleAddModal(schedule, time, false)">+ 추가</p>
                      </template>
                    </td>
                  </tr>
                </template>
                <!-- 통역사 기준 -->
                <template v-if="selectedType === 'interpreter_name'">
                  <tr v-for="interpreter in paginatedYList" :key="interpreter.account_id">
                    <td class="booth">{{ interpreter.full_name }}</td>
                    <td v-for="time in eventDateTime" :key="time.ptd_id">
                      <template v-if="getSchedulesByBIS(interpreter.account_id, time.ptd_id, 'interpreter').length">
                        <div class="schedule_info_wrap"
                          v-for="schedule in getSchedulesByBIS(interpreter.account_id, time.ptd_id, 'interpreter')"
                          :key="schedule.schedule_id"
                          @click="openScheduleAddModal(schedule, time, schedule.schedule_id)">
                          <p class="schedule_info"><span>{{ schedule.ptb }}/{{ schedule.created_by_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">B</span><span>{{ schedule.buyer_company
                              }} {{
                                schedule.buyer_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">S</span><span>{{ schedule.seller_company
                              }} {{
                                schedule.seller_name }}</span></p>
                          <p class="schedule_user_info"><span class="type_bdg">FM</span><span>{{ schedule.manager_name
                              }}</span></p>
                        </div>
                        <p class="size_12 sub_text add_btn" @click="openScheduleAddModal(schedule, time, false)">+ 추가
                        </p>
                      </template>
                      <template v-else>
                        <p class="add_schedule" @click="openScheduleAddModal(schedule, time, false)">+ 추가</p>
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td>부스 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
    <schedule-modal :show-booth-side-modal="showBoothSideModal" :show-schedule-upload-modal="showScheduleUploadModal"
      :show-schedule-add-modal="showScheduleAddModal" @close="closeAllModals" :boothList="yList"
      :selectedScheduleData="selectedScheduleData"></schedule-modal>
  </div>
</template>