<script>
import { ref, onMounted, computed, inject, onBeforeUnmount } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import bisatStatus from '@/assets/js/bisatStatus.json';
import receptionTableModal from '@/components/admin/receptionTableModal.vue';
import jsQR from "jsqr";


export default {
  name: 'receptionQr',
  components: {
    Pagination,
    receptionTableModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');


    // QR 관련 변수
    const mode = ref('individual')
    const batch_status = ref('')
    const video = ref(null);
    const canvas = ref(null);
    const qrCodeData = ref('');
    let animationFrameId = null;
    let isScanning = false; // QR 코드 스캔 중 여부

    // 모달 관련 변수
    const showStatusModal = ref(false);
    const selectedUserData = ref({});
    const closeAllModals = (type) => {
      showStatusModal.value = false;
    };
    const openOperateModal = (type, userId) => {
      selectedUserData.value = { type, userId, mode: 'qr_mode' };
      showStatusModal.value = true;
    };

    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
        if (video.value) {
          video.value.srcObject = stream;
          video.value.setAttribute("playsinline", true); // iOS 사용시 전체 화면을 사용하지 않음을 전달
          video.value.play();
        }
        isScanning = true;
        scanQRCode();
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    };

    const drawLine = (begin, end, color) => {
      if (canvas.value) {
        const context = canvas.value.getContext('2d');
        context.beginPath();
        context.moveTo(begin.x, begin.y);
        context.lineTo(end.x, end.y);
        context.lineWidth = 4;
        context.strokeStyle = color;
        context.stroke();
      }
    };

    const drawRect = (location, color) => {
      drawLine(location.topLeftCorner, location.topRightCorner, color);
      drawLine(location.topRightCorner, location.bottomRightCorner, color);
      drawLine(location.bottomRightCorner, location.bottomLeftCorner, color);
      drawLine(location.bottomLeftCorner, location.topLeftCorner, color);
    };

    const drawFinderPattern = (location, color) => {
      drawLine(location.topLeftFinderPattern, location.topRightFinderPattern, color);
      drawLine(location.topRightFinderPattern, location.bottomLeftFinderPattern, color);
      drawLine(location.bottomLeftFinderPattern, location.topLeftFinderPattern, color);
    };

    const scanQRCode = () => {
      if (video.value && canvas.value && isScanning) {
        const context = canvas.value.getContext('2d');
        context.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height);
        const imageData = context.getImageData(0, 0, canvas.value.width, canvas.value.height);

        // QR 코드 해독 로직
        try {
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            isScanning = false; // 스캔 중지
            qrCodeData.value = code.data;

            // QR 코드의 위치에 경계 그리기
            drawRect(code.location, "#FF3B58");

            // Finder 패턴에 경계 그리기
            drawFinderPattern(code.location, "#3BFF58");

            // Bottom Right Alignment 패턴이 있을 경우 그리기
            if (code.location.bottomRightAlignmentPattern) {
              drawLine(code.location.bottomRightAlignmentPattern, code.location.bottomRightAlignmentPattern, "#3B58FF");
            }

            // QR 코드 데이터 분할 및 추출
            const qrParts = code.data.split('<!@!>');
            const userId = parseInt(qrParts[0]);

            // API 호출
            setTimeout(() => {
              if (mode.value === 'individual') {
                openOperateModal('BUYER', userId);
              }
              else {
                sendQRDataToAPI(qrCodeData.value, batch_status.value)
              }
            }, 1000);
          }
        } catch (error) {
          alert("Error decoding QR code:", error);
        }
      }
      if (isScanning) {
        animationFrameId = requestAnimationFrame(scanQRCode, batch_status.value);
      }
    };

    const sendQRDataToAPI = async (qrCodeData, status) => {
      console.log(qrCodeData, status)
      if (qrCodeData && status) {
        try {
          const queryParams = new URLSearchParams({
            piece_event_id: eventId.value,
            qr_data: qrCodeData,
            status: status
          });
          const url = `/reception/scan_qr_for_status?${queryParams.toString()}`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          });

          if (response.data.result === true) {
            alert('QR 데이터 전송 성공');
            startCamera()
          } else {
            alert('QR 데이터 전송 실패');
            startCamera()
          }
        } catch (error) {
          console.error('QR 데이터 전송 중 오류 발생:', error);
        }
      } else {
        alert("데이터가 정확하지 않습니다.")
        startCamera()
      }
    };

    const stopScan = () => {
      isScanning = false;
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        startCamera();
      }
    });

    onBeforeUnmount(() => {
      stopScan();
    });

    // 모달에서 저장 버튼 클릭 시 호출되는 메서드
    const handleModalSave = (data) => {
      // API 호출
      sendQRDataToAPI(qrCodeData.value, data)
    };

    const getStatusList = computed(() => {
      return bisatStatus.BUYER.qr || [];
    });



    return {
      video,
      canvas,
      qrCodeData,
      startCamera,
      stopScan,
      mode,
      closeAllModals,
      showStatusModal,
      openOperateModal,
      selectedUserData,
      handleModalSave,
      getStatusList,
      batch_status
    };
  },
}
</script>

<template>
  <div id="receptionQr">
    <div class="mx_1160">
      <div class="header_tab">
        <a @click="mode = 'individual'" :class="{ 'active': mode === 'individual' }">개별 QR 촬영</a>
        <a @click="mode = 'batches'" :class="{ 'active': mode === 'batches' }">일괄 QR 촬영</a>
      </div>
      <div class="text_wrap" :class="{ 'back_light_gray': mode === 'batches' }">
        <template v-if="mode === 'individual'">
          <p class="size_26 b_700">내 QR코드를 스캔하세요.</p>
          <p class="secondary_text">QR코드는 비샛의 [내 QR코드]에서 확인할 수 있어요.</p>
        </template>
        <template v-else>
          <p class="size_20 b_700">변경할 상태를 선택하세요.</p>
          <p class="secondary_text">QR코드 촬영을 하면 선택한 상태로 일괄 변경돼요.</p>
          <div class="status_list">
            <p class="block_main_btn" v-for="item in getStatusList" :key="item.code" @click="batch_status = item.status"
              :class="{ 'active': batch_status === item.status }">
              <i :class="`icon-${item.icon}`"></i>{{ item.label }}
            </p>
          </div>
        </template>
      </div>
      <video ref="video" autoplay style="display: none;"></video>
      <canvas ref="canvas" width="640" height="480" v-if="mode === 'individual' || (mode === 'batches' && batch_status)"></canvas>
    </div>
    <reception-table-modal :show-status-modal="showStatusModal" @close="closeAllModals"
      :selected-user-data="selectedUserData" @save="handleModalSave">
    </reception-table-modal>
  </div>
</template>
