<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import "@/assets/css/modal.css";
import { useStore } from "vuex";   
import dropDownMultiple from "./dropDown/dropDownMultiple.vue"; 

export default defineComponent({
    props: {
        showRecordModal: Object,
        BIS: String
    },
    components: {
        dropDownMultiple,
    },
    setup(props, { emit }) {
        // Axios 인스턴스와 Vuex 스토어 사용
        const axios = inject("axios");
        const store = useStore();
        const eventId = computed(() => store.state.eventStore.eventId);
        const masterId = computed(() => store.state.eventStore.masterId);
        const BIS = props.BIS;
        const eventTitle = computed(() => store.state.eventStore.eventTitle);
        const schedule_id = props.showRecordModal;
        // 페이지 상태 및 토큰 관리
        const isAuthenticated = ref(false);
        const token = ref("");
        const current_lang = ref("ko");
        const types = ["cn", "en", "jp", "ko"];

        const closeModals = () => {
            emit("close");
        };

        // 상담일지 상세
        const r = ref({});
        const answers = ref({});
        const readRecord = async () => {
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_record`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        schedule_id: schedule_id.selected,
                        cr_type: BIS,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    r.value = response.data.record;
                    answers.value = response.data.record.answers;
                    let start_time = r.value.start_time.split(":");
                    let end_time =  r.value.end_time.split(":");
                    r.value.booth_time = `${start_time[0]}:${start_time[1]} ~ ${end_time[0]}:${end_time[1]}`;
                    readAccountDetailInfo('BUYER');
                    readAccountDetailInfo('SELLER');
                    readAccountDetailInfo('INTERPRETER');
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const returnComma = money => {
            return Number(money).toLocaleString("ko-KR")
        }

        // 설문구조
        const questions = ref([]);
        const callQuestionList = async () => {
            if (masterId.value != null) {
                await readMasterToQuestionList(BIS);
            } else {
                await readPieceToQuestionList(BIS);
            }
        }
        const readPieceToQuestionList = async (BIS) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: eventId.value,
                        ptc_type: BIS,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    questions.value = response.data.question_list;
                    // 역순으로 순회하면서 조건에 맞는 항목 제거
                    for (let i = questions.value.length - 1; i >= 0; i--) {
                        if (questions.value[i].cq_id === 1 || 
                            questions.value[i].cq_id === 2 || 
                            questions.value[i].cq_id === 42) {
                            questions.value.splice(i, 1);
                        }
                    }
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };
        const readMasterToQuestionList = async (BIS) => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_master_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        master_event_id: masterId.value,
                        mtc_type: BIS,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    questions.value = response.data.question_list;
                    // 역순으로 순회하면서 조건에 맞는 항목 제거
                    for (let i = questions.value.length - 1; i >= 0; i--) {
                        if (questions.value[i].cq_id === 1 || 
                            questions.value[i].cq_id === 2 || 
                            questions.value[i].cq_id === 42) {
                            questions.value.splice(i, 1);
                        }
                    }
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        // 유저정보
        const scheB = ref({});
        const scheI = ref({});
        const scheS = ref({});
        const readAccountDetailInfo = async _bis => {
            let id = "";
            if (_bis == "INTERPRETER") {
                id = r.value.interpreter_id;
            } else if (_bis == "BUYER") {
                id = r.value.buyer_id;
            } else if (_bis == "SELLER") {
                id = r.value.seller_id;
            }
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/user/read_account_info_in_piece`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: eventId.value,
                        account_id: id,
                        account_type: _bis,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    if (_bis == "INTERPRETER") {
                        scheB.value = response.data.account_info;
                    } else if (_bis == "BUYER") {
                        scheI.value = response.data.account_info;
                    } else if (_bis == "SELLER") {
                        scheS.value = response.data.account_info;
                    }
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        }

        // 수정모드 onoff
        const editMode = ref(false);

		const changeCheckValue = (cq_id, compAnswers) => {
			answers.value[cq_id] = compAnswers;
        };

        const changeNumber = (value, answer) => {
            answer = value;
        }

        const cancelUpdate = () => {
            readRecord();
            editMode.value = false;
        }

        const updateRecordStatus = async status => {
            let rejectReason = "";
            if (status == 3) {
                rejectReason = window.prompt('반려사유를 작성해 주세요.',"");
            }
            try {
                // Axios 요청을 보냅니다.
                const response = await axios.put(
                    "/consultation/update_record_status",
                    {
                        schedule_id: Number(schedule_id.selected),
                        cr_type: BIS,
                        cr_status: Number(status),
                        reject_reason: rejectReason,
                    },
                    {
                        headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                        },
                    }
                );

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    showToast(status);
                } else {
                    alert("질문을 수정하지 못했습니다.");
                }
            } catch (error) {
                console.error(error);
            }
        }

        const showToast = status => {
            closeModals();
            emit("toast", status);
        }

        const updateRecord = async () => {
            if (validateUpdate() == true) {
                try {
                    // Axios 요청을 보냅니다.
                    const response = await axios.put(
                        "/consultation/update_record",
                        {
                            schedule_id: Number(schedule_id.selected),
                            answers: answers.value,
                            cr_type: BIS,
                            is_email: Number(r.value.is_email),
                        },
                        {
                            headers: {
                            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                            },
                        }
                    );
    
                    // 요청이 성공했고 결과가 true인 경우
                    if (response.data.result === true) {
                        alert("상담일지가 수정되었습니다.");
                    } else {
                        alert("질문을 수정하지 못했습니다.");
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                alert('수정할 내용이 없습니다.');
            }
        }

        const readOriginQuestions = async () => {
            // db에 있는 질문 불러오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_record`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        schedule_id: schedule_id.selected,
                        cr_type: BIS,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    return response.data.record.answers;
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const validateUpdate = async () => {
            const originResult = await readOriginQuestions(BIS);
            const arrayOfTrue = [];

            for (const [key, value] of Object.entries(originResult)) {
                if (typeof value == 'object') {
                    const newOne = Object.values(answers.value[key]);
                    value.forEach((element,idx) => {
                        if (newOne[idx] !== element) {
                            arrayOfTrue.push(true);
                        } else {
                            arrayOfTrue.push(false);
                        }
                    });
                } else {
                    if (answers.value[key] != value) {
                        arrayOfTrue.push(true);
                    } else {
                        arrayOfTrue.push(false);
                    }
                }
            }
            if (arrayOfTrue.includes(false)) {
                return false;
            } else {
                return true;
            }
        }

        // 함수 트리거
        watch(schedule_id, () => {
            if (schedule_id.onoff) {
                readRecord(schedule_id.selected);
            }
        });

        const deleteRecord = async () => {
            if (confirm("삭제한 정보는 복구할 수 없습니다. 정말로 삭제하시겠습니까?")) {
                if (r.value.cr_id) {
                    try {
                        // Axios 요청을 보냅니다.
                        let url = `/consultation/delete_record`;
                        // Axios 요청을 보냅니다.
                        const response = await axios.delete(
                            url,
                            {
                                data: {
                                    cr_id: r.value.cr_id,
                                    schedule_id: r.value.schedule_id,
                                },
                                headers: {
                                    Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                                },
                            }
                        );

                        // 요청이 성공했고 결과가 true인 경우
                        if (response.data.result === true) {
                            alert("상담일지가 삭제되었습니다.");
                            // 상담일지 목록으로 돌아간다
                            location.reload();
                        } else {
                            alert("상담일지를 삭제하지 못했습니다.");
                        }
                    } catch (error) {
                        console.error("상담일지를 삭제하는 중에 오류가 발생했습니다:", error);
                    }
                }
            }
        };

        // 컴포넌트 마운트 시 로직
        onMounted(async () => {
            isAuthenticated.value = store.state.userStore.isAuthenticated;
            if (!isAuthenticated.value) {
                router.push({ name: "home" });
            } else {
                token.value = store.state.userStore.token;
            }
            callQuestionList();
            window.addEventListener("keyup", (event) => {
                if (event.code === "Escape") {
                    closeModals();
                }
            });
        });
        return {
            eventId,
            eventTitle,
            closeModals,
            r,
            answers,
            returnComma,
            questions,
            scheB,
            scheI,
            scheS,
            types,
            current_lang,
            editMode,
            changeCheckValue,
            changeNumber,
            cancelUpdate,
            updateRecordStatus,
            updateRecord,
            deleteRecord,
        };
    },
});
</script>

<template>
    <div id="recordDetailModal">
        <div class="overlay" v-if="showRecordModal.onoff"></div>
        <div class="modal width_1122" v-if="showRecordModal.onoff">
            <p class="icon_box close" @click="closeModals">
                <i class="icon-delete sub_text"></i>
            </p>
            <p class="modal_title size_26 b_700">{{ eventTitle }}</p>
            <div class="modal_overflow">
                <div class="record_flex_box">
                    <div>
                        <div class="secondary_text size_14">일지번호</div>
                        <div class="size_14">{{ r.cr_id }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">날짜</div>
                        <div class="size_14">{{ r.date }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">시간</div>
                        <div class="size_14">{{ r.booth_time }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">부스</div>
                        <div class="size_14">{{ r.booth_name }}</div>
                    </div>
                </div>
                <div class="record_title">참가자 정보</div>
                <div class="bis_box">
                    <div class="buyer" :class="r.created_by == r.interpreter_id">
                        <p class="bis_title">
                            바이어
                            <span v-if="r.created_by == r.interpreter_id">*작성자</span>
                        </p>
                        <div class="grid_inner">
                            <div class="fake_textbox">
                                <span>회사명</span>
                                <p>{{ scheB.company }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>이메일</span>
                                <p>{{ scheB.contact_email }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>연락처</span>
                                <p>{{ scheB.mobile }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>이름</span>
                                <p>{{ scheB.full_name }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>바이어 코드</span>
                                <p>{{ scheB.serial_number }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>국가</span>
                                <p>{{ scheB.country }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="seller" :class="r.created_by == r.interpreter_id">
                        <p class="bis_title">
                            셀러
                            <span v-if="r.created_by == r.interpreter_id">*작성자</span>
                        </p>
                        <div class="grid_inner">
                            <div class="fake_textbox">
                                <span>회사명</span>
                                <p>{{ scheS.company }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>이메일</span>
                                <p>{{ scheS.contact_email }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>연락처</span>
                                <p>{{ scheS.mobile }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>담당자</span>
                                <p>{{ scheS.full_name }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>셀러 코드</span>
                                <p>{{ scheS.serial_number }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="interpreter" :class="r.created_by == r.interpreter_id">
                        <p class="bis_title">
                            통역사
                            <span v-if="r.created_by == r.interpreter_id">*작성자</span>
                        </p>
                        <div class="grid_inner">
                            <div class="fake_textbox">
                                <span>이름</span>
                                <p>{{ scheI.full_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="record_title">상담 결과</div>
                <div class="survey_box" :class="{'edit_mode': editMode}">
                    <template v-for="item in questions">
                        <div class="survey_title" v-text="item.is_one_time ? item.ptc_title['ko'] : item.list_title"></div>
                        <div>
                            <template v-if="item.cq_type == 'SINGLE'">
                                <select :name="`question${item.cq_id}`" :id="`question${item.cq_id}`" :disabled="!editMode">
                                    <template v-for="(value, key) in item.ptc_answer_form">
                                        <option :value="key" :selected="key == answers[item.cq_id]">{{ value['ko'] }}</option>
                                    </template>
                                </select>
                            </template>
                            <template v-else-if="item.cq_type == 'MULTIPLE'">
                                <drop-down-multiple :id="`question${item.cq_id}`" :options="item.ptc_answer_form" :answers="answers[item.cq_id]" :disabled="!editMode" @change="(compAnswers) => {changeCheckValue(item.cq_id, compAnswers)}"></drop-down-multiple>
                            </template>
                            <template v-else>
                                <input type="text" :id="`question${item.cq_id}`" :value="answers[item.cq_id]" v-model="answers[item.cq_id]" class="fake_textbox" :disabled="!editMode">
                            </template>
                        </div>
                    </template>
                </div>
                <div class="record_title">세부 상담내용</div>
                <div class="detail_cont_box">
                    <textarea name="question6" id="question6" v-model="answers[42]" :disabled="!editMode"></textarea>
                </div>
                <div class="record_flex_box">
                    <div>
                        <div class="secondary_text size_14">상담액</div>
                        <input type="text" id="question1" :value="returnComma(answers[1])" class="fake_textbox" :disabled="!editMode" @change="changeNumber($event.target.value, answers[1])">
                    </div>
                    <div>
                        <div class="secondary_text size_14">계약추진액</div>
                        <input type="text" id="question2" :value="returnComma(answers[2])" class="fake_textbox" :disabled="!editMode" @change="changeNumber($event.target.value, answers[2])">
                    </div>
                </div>
                <div class="button_wrap">
                    <div class="ds_flex jus_between al_center gap12">
                        <div class="icon_box cancel_btn cs_pointer" @click="deleteRecord()"><i class="icon-trash size_20"></i></div>
                        <div v-if="!editMode" class="icon_box cancel_btn cs_pointer" @click="editMode = true"><i class="icon-edit size_20"></i></div>
                    </div>
                    <div v-if="!editMode" class="ds_flex jus_between al_center gap20">
                        <div class="block_main_btn cs_pointer" @click="updateRecordStatus(5)">
                            <img src="@/assets/img/survey/record_5.png" alt="status 5" width="24">
                            우수상담
                        </div>
                        <div class="block_main_btn cs_pointer" @click="updateRecordStatus(4)">
                            <img src="@/assets/img/survey/record_4.png" alt="status 4" width="24">
                            승인
                        </div>
                        <div class="block_main_btn cs_pointer" @click="updateRecordStatus(3)">
                            <img src="@/assets/img/survey/record_3.png" alt="status 3" width="24">
                            반려
                        </div>
                    </div>
                    <div v-else class="ds_flex jus_between al_center gap20">
                        <div class="block_main_btn cancel_btn" @click="cancelUpdate();">
                            취소
                        </div>
                        <div class="block_main_btn active_btn" @click="updateRecord()">
                            저장
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
