<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    showInfoDeskModal: Boolean,
    selectedUserData: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    // 팝업 닫기
    const closeModals = () => {
      emit('close');
    }

    // PROPS DATA
    const userId = computed(() => props.selectedUserData.info.account_id)
    const userType = computed(() => props.selectedUserData.info.account_type)
    const userComapny = computed(() => props.selectedUserData.info.company)
    const userName = computed(() => props.selectedUserData.info.full_name)

    const userScheduleData = ref({});
    const selectedDate = ref(''); // 현재 선택된 날짜


    watch(
      () => props.selectedUserData,
      async (newValue) => {
        if (newValue && newValue.info) {  // newValue가 존재하고 userId가 있을 때만 실행
          await fetchUserSchedule();
        }
      },
      { immediate: true }
    );

    // 시간을 HH:mm 형식으로 변환하는 함수
    const formatTime = (time) => {
      return time.slice(0, 5); // 'HH:mm:ss' 형태의 문자열에서 앞의 5자리를 잘라냅니다.
    };

    const fetchUserSchedule = async () => {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          account_id: userId.value,
          account_type: userType.value
        });
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_account_schedules?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          const rawSchedules = response.data.account_schedules;
          const formattedSchedules = {};
          // 데이터를 순회하며 시간을 변환하고 새로운 객체에 저장합니다.
          for (const [date, schedules] of Object.entries(rawSchedules)) {
            formattedSchedules[date] = schedules.map(schedule => ({
              ...schedule,
              start_time: formatTime(schedule.start_time),
              end_time: formatTime(schedule.end_time)
            }));
          }

          userScheduleData.value = formattedSchedules;
          selectedDate.value = Object.keys(formattedSchedules)[0]; // 첫 번째 날짜를 기본 선택
        } else {
          alert('스케줄을 불러오지 못했습니다.');
        }
      } catch {

      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }
    });
    return {
      closeModals,
      userId,
      userType,
      userComapny,
      userName,
      userScheduleData,
      selectedDate
    }
  }
});
</script>

<template>
  <div id="infoDeskModal">
    <div class="overlay" v-if="showInfoDeskModal"></div>
    <div class="modal narrow_width" v-if="showInfoDeskModal">
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <template v-if="showInfoDeskModal">
        <div class="ds_flex dir_column gap12">
          <p class="size_14 secondary_text">일정표</p>
          <p class="size_26 b_700">{{ userComapny }}</p>
          <p class="size_20 secondary_text">{{ userName }}</p>
        </div>
        <div class="info_schedule_wrap">
          <div class="ds_flex gap12 date_tab">
            <p v-for="(items, date) in userScheduleData" :key="date" class="inline_cont_btn"
              @click="selectedDate = date" :class="{ active: selectedDate === date }">
              {{ date }}
            </p>
          </div>
          <div v-if="selectedDate" class="scroll">
            <div v-for="(schedule, index) in userScheduleData[selectedDate]" :key="index" class="info_schedule_cont">
              <p class="secondary_text">{{ schedule.start_time }} - {{ schedule.end_time }}</p>
              <div class="user_info">
                <p class="b_700">{{ userType === "BUYER" ? schedule.seller_company : schedule.buyer_company }}</p>
                <p class="ds_flex gap10"><i class="icon-location icon_box sub_text"></i><span class="secondary_text">{{
                  schedule.booth_name }}</span>
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <div>지정된 스케줄이 없습니다.</div>
          </div>
        </div>
        <div class="info_bottom ds_flex jus_between">
          <div>
            <img src="@/assets/img/common/bisat_logo.png" />
          </div>
          <div class="icon_cont_btn">
            <p class="icon_box"><i class="icon-print sub_text"></i></p>
            <!-- TODO: #19 @eqquiybkang 프린트 기능 추가  -->
            <p class="sub_text">프린트</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
