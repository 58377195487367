<script>
import { computed, ref, watch } from "vue";
import * as survey_form from "@/assets/js/survey_form";

export default {
    props: {
        id: String,
        options: Object,
        answers: Array,
        radioAnswer: Object,
        mode: String, // filter or status: 현장운영 상태 필터 or 상태 일괄 변경하기
    },
    setup(props, { emit }) {
        let id = props.id;
        let options = ref({});
        let answers = ref([]);
        let radioAnswer = ref({});
        const selected = ref([]);
        const opened = ref(false);

        const openSelect = (off = false) => {
            if  (off) {
                opened.value = false;
            } else {
                if (opened.value == true) {
                    opened.value = false;
                } else {
                    opened.value = true;
                }
            }
        };

        const changeOptionAll = () => {
            if (allSelected.value) {
                emit("change", []);
            } else {
                emit("change", options.value);
            }
        };

        const changeOption = option => {
            if (answers.value.includes(option)) {
                answers.value = answers.value.filter((e) => e !== option);
            } else {
                answers.value.push(option);
            }
            emit("change", answers.value);
        };

        const switchOption = () => {
            emit("switch", radioAnswer.value);
        };

        const submitSwitchOption = () => {
            emit("submit");
        };

        watch(
            // 드롭다운 프롭 변경 디텍
            [() => props.options, () => props.answers, () => props.radioAnswer],
            ([newOptions, newAnswers, newRadioAnswer], [oldOptions, oldAnswers, oldRadioAnswer]) => {
                //options
                if (newOptions !== oldOptions) {
                    options.value = newOptions;
                }

                //answers
                if (newAnswers !== oldAnswers) {
                    answers.value = newAnswers;
                }

                //radioAnswer
                if (newRadioAnswer !== oldRadioAnswer) {
                    radioAnswer.value = newRadioAnswer;
                }
            },
            { immediate: true }
        );

        const allSelected = computed(()=>{
            return options.value.length === answers.value.length;
        });

        return {
            id,
            options, // 옵션 v-for 돌려야 하는 값 object 또는 array
            answers,
            radioAnswer,
            selected, // 선택된 옵션값
            changeOptionAll,
            changeOption, // 체크박스 선택값 변경 함수
            switchOption, // 라디오 선택값 변경 함수
            submitSwitchOption, // 라디오 선택값 변경 함수
            opened, // 드롭다운 오픈 체크
            openSelect, // 드롭다운 오픈 함수
            survey_form,
            focus,
            allSelected,
        };
    }
};
</script>
<template>
    <div class="fake_dropdown_wrap">
        <template v-if="mode == 'filter'">
            <div class="fake_dropdown ds_flex al_center gap4" :class="[opened ? 'active' : '']" @click="openSelect()">
                <div class="secondary_text size_14 b_500">
                    <template v-if="id == 'statusBuyer'">바이어</template>
                    <template v-if="id == 'statusSeller'">셀러</template>
                    <template v-if="id == 'statusInterpreter'">통역사</template>
                </div>
                <p>
                    <template v-if="allSelected">
                        전체 상태
                    </template>
                    <template v-else>
                        <template v-if="answers.length > 1">
                            {{ answers[0].label }} 외 {{ answers.length - 1 }}개
                        </template>
                        <template v-else-if="answers.length == 1">
                            {{ answers[0].label }}
                        </template>
                    </template>
                </p>
                <i :class="opened ? 'icon-up_arrow' : 'icon-down_arrow'"></i>
            </div>
            <transition name="dropdown">
                <div class="fake_dropdown_options" v-if="opened" style="width: 280px;">
                    <div class="fake_option" :class="{'active': allSelected}" @click="changeOptionAll()">
                        <label :for="`check_${id}_all`" class="fake_checkbox" :class="{'active': allSelected}">
                            <i v-if="allSelected" class="icon-checked"></i>
                        </label>
                        <p>전체 상태</p>
                    </div>
                    <div v-for="a in options" :key="a.status" class="fake_option" :class="answers.includes(a) ? 'active' : ''" @click="changeOption(a)">
                        <label :for="`check_${id}_${a.status}`" class="fake_checkbox" :class="{'active': answers.includes(a)}">
                            <i v-if="answers.includes(a)" class="icon-checked"></i>
                        </label>
                        <input type="checkbox" :id="`check_${id}_${a.status}`">
                        <div class="ds_flex al_center gap8">
                            <i :class="`icon-${a.icon}`" style="font-size: 16px"></i>
                            <p>{{ a.label }}</p>
                        </div>
                    </div>
                    <div class="dropdown_btn_wrap">
                        <div class="dropdown_submit active_btn" @click="opened = false">완료</div>
                        <div class="dropdown_submit cancel_btn" @click="answers = options; opened = false">취소</div>
                    </div>
                </div>
            </transition>
        </template>
        <template v-if="mode == 'status'">
            <div class="inline_cont_btn eqqui_btn fake_dropdown" @click="openSelect()">변경할 상태 선택</div>
            <transition name="dropdown">
                <div class="fake_dropdown_options" v-if="opened" style="width: 280px;">
                    <div v-for="a in options" :key="a.status" class="fake_option" :class="radioAnswer.status == a.status ? 'active' : ''">
                        <input type="radio" :id="`check_${id}_${a.status}`" :name="`check_${id}`" :value="a" v-model="radioAnswer" @change="switchOption(a)">
                        <label :for="`check_${id}_${a.status}`" class="cs_pointer" style="flex: 1">
                            <div class="ds_flex al_center gap8">
                                <i :class="`icon-${a.icon}`" style="font-size: 16px"></i>
                                <p>{{ a.label }}</p>
                            </div>
                        </label>
                    </div>
                    <div class="dropdown_btn_wrap">
                        <div class="dropdown_submit active_btn" @click="submitSwitchOption()">변경하기</div>
                        <div class="dropdown_submit cancel_btn" @click="opened = false">취소</div>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>
