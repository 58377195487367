<script>
import { ref, defineComponent, onMounted, inject, watch, computed, reactive } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatLang from '@/assets/js/bisatLang.json';

export default defineComponent({
  props: {
    showStatusModal: Boolean,
    selectedUserData: Object,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);

    // 유저 데이터
    const userInfo = reactive({ ...props.selectedUserData.userData });
    const userType = computed(() => props.selectedUserData.type);
    const pta_id = computed(() => props.selectedUserData.pta_id);
    const mode = computed(() => props.selectedUserData.mode);
    const userInfoForQr = ({});

    // props.selectedUserData.userData가 변경되면 userInfo를 업데이트
    watch(() => props.selectedUserData.userData, (newValue) => {
      Object.assign(userInfo, newValue);
    });

    const closeModals = () => {
      emit('close');
      userInfo.value = {}; // 초기값으로 빈 객체 설정
    }

    const getStatusList = computed(() => {
      if (userType.value && bisatStatus[userType.value]) {
        return bisatStatus[userType.value].attended || [];
      }
      return [];
    });

    const getLanguage = (code) => {
      const languageLabel = bisatLang.find(item => item.code === code);
      return languageLabel ? languageLabel.label : '';
    };


    // 상태 업데이트
    const changeStatus = async () => {
      const data = {
        piece_event_id: eventId.value,
        at_id: userInfo.at_id,
        code_name: userInfo.code_name,
      };
      const url = '/attendance/update_piece_to_account_status';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      getStatusList,
      changeStatus,
      userType,
      userInfo,
      getLanguage,
    }
  },
});
</script>

<template>
  <div id="operateAttendTableModal">
    <div class="overlay" v-if="showStatusModal"></div>
    <div class="modal status_width" v-if="showStatusModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <p class="user_type_bdg" :class="userType">{{ userType === "SELLER" ? "셀러" : "통역사" }}</p>
        <p class="size_26 b_700">{{ userType === "SELLER" ? userInfo.company : userInfo.full_name }}</p>
        <div class="user_cont" v-if="userInfo">
          <p v-if="userType === 'SELLER'"><label>이름</label> {{ userInfo.full_name }}</p>
          <p v-if="userInfo.contact_email"><label>이메일</label> {{ userInfo.contact_email }}</p>
          <p v-if="userInfo.mobile"><label>연락처</label> {{ userInfo.mobile }}</p>
          <p v-if="userInfo.lang1"><label>언어</label> {{ getLanguage(userInfo.lang1) }} <span
              v-if="userInfo.lang2">|</span> {{ getLanguage(userInfo.lang2) }}</p>
        </div>
        <p class="size_18 b_700">상태</p>
        <div class="user_status">
          <p class="status" v-for="status in getStatusList" :key="status.status"
            :class="{ 'active': userInfo.code_name === status.status }" @click="userInfo.code_name = status.status">
            <i :class="`icon-${status.icon}`"></i>{{ status.label }}
          </p>
        </div>
        <div class="btn_wrap ds_flex gap18 jus_end">
          <p class="inline_cont_btn cancel_btn" @click="closeModals">취소</p>
          <p class="inline_cont_btn active_btn" @click="changeStatus">저장</p>
        </div>
      </div>
    </div>
  </div>
</template>
