<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import subHeader from "@/components/admin/subHeader.vue";
// import { Chart } from 'highcharts-vue'
import { surveyDashboardOptions } from "@/assets/js/surveyDashboardChartOptions";
import bisatCountry from '@/assets/js/bisatCountry';
// import CountTo from 'vue-count-to';


export default {
  name: 'surveyDashboard',
  components: {
    subHeader,
    // CountTo,
  },
  setup() {
    // 차트 옵션
    const reactiveSurveyTypeOptions = ref(surveyDashboardOptions.surveyTypeOptions);
    const reactiveSurveyStatusOptions = ref(surveyDashboardOptions.surveyStatusOptions);
    const reactiveResultForSurveyByCountry = ref(surveyDashboardOptions.resultForSurveyByCountry);
    const reactiveEtcSurveyResult = ref(surveyDashboardOptions.etcSurveyResult);

    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const router = useRouter(); // router 인스턴스 사용
    const route = useRoute(); // 현재 라우트 정보 사용
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref("survey");


    const eventDateList = ref([])
    const dashboardDataForFee = ref([])
    const dashboardDataForSurvey = ref([])
    const dashboardDataForCount = ref([])

    async function fetchSurveyDashboardData() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          cr_type: "INTERPRETER"
        });
        const url = `/consultation/read_piece_dashboard?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          dashboardDataForFee.value = response.data.f_data;
          dashboardDataForSurvey.value = response.data.s_data;
          dashboardDataForCount.value = response.data.c_data;
          processSurveyTypeData('total')
          processSurveyStatusData('total')
          processBuyerCountryData()
          await fetchDate()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // 차트 가공 --------------------------------------------
    // 상담유형 데이터 가공
    function processSurveyTypeData(date) {
      const totalAnswers = dashboardDataForCount.value[3]?.answers?.[date];

      // 각 상태에 대한 값을 계산
      const canceledConsultation = (totalAnswers?.[0] || 0) + (totalAnswers?.[1] || 0);
      const preCoordination = totalAnswers?.[2] || 0;
      const onSiteCoordination = totalAnswers?.[3] || 0;

      // 반응형 변수의 series 값을 업데이트
      reactiveSurveyTypeOptions.value.series = [{
        colorByPoint: true,
        data: [{
          name: '현장주선',
          y: onSiteCoordination,
          sliced: true,
          selected: true
        }, {
          name: '사전주선',
          y: preCoordination
        }, {
          name: '상담취소',
          y: canceledConsultation
        }]
      }];
    }

    // 상담일지 데이터 가공
    function processSurveyStatusData(date) {
      const totalAnswers = dashboardDataForSurvey.value[date];

      // 반응형 변수의 series 값을 업데이트
      reactiveSurveyStatusOptions.value.series = [{
        colorByPoint: true,
        data: [{
          name: '우수상담',
          y: totalAnswers?.[5] || 0,
          sliced: true,
          selected: true
        }, {
          name: '승인됨',
          y: totalAnswers?.[4] || 0,
        }, {
          name: '검토중',
          y: totalAnswers?.[2] || 0,
        }, {
          name: '수정요청',
          y: totalAnswers?.[3] || 0,
        }, {
          name: '작성중',
          y: totalAnswers?.[1] || 0
        }, {
          name: '작성전',
          y: totalAnswers?.[7] || 0
        }]
      }];
    }

    // 성과지표 국가 트리맵 데이터 가공
    const getCountry = (code) => {
      const countryLabel = bisatCountry.find(item => item.code === code);
      return countryLabel ? countryLabel.label : '';
    };
    const activeLabelCountryData = ref('')
    // 성과지표 데이터 가공
    function processBuyerCountryData() {
      // activeTabForSec6 값에 따라 totalAnswers 설정
      let totalAnswers;
      if (activeTabForSec6.value === 'surveyCount') {
        totalAnswers = dashboardDataForCount.value.country_count;
      } else if (activeTabForSec6.value === 'exportAmount') {
        totalAnswers = dashboardDataForFee.value.country_c_dict;
      } else if (activeTabForSec6.value === 'contractAmount') {
        totalAnswers = dashboardDataForFee.value.country_p_dict;
      } else if (activeTabForSec6.value === 'tradeability') {
        totalAnswers = dashboardDataForCount.value[5].answers.percent.country;
      }

      // totalAnswers의 첫 번째 국가 이름 찾기
      const firstCountry = Object.keys(totalAnswers)[0];
      activeLabelCountryData.value = firstCountry;

      reactiveResultForSurveyByCountry.value = {
        plotOptions: {
          series: {
            point: {
              events: {
                click: function () {
                  activeLabelCountryData.value = this.options.countryCode;
                  // 또는 다른 동작 수행
                }
              }
            }
          }
        },
        tooltip: {
          formatter: function () {
            // activeTabForSec6 값에 따라 formattedValue 설정
            let formattedValue;
            if (activeTabForSec6.value === 'exportAmount' || activeTabForSec6.value === 'contractAmount') {
              formattedValue = `$${this.point.value.toLocaleString()} USD`; // 달러 표시
            } else if (activeTabForSec6.value === 'tradeability') {
              formattedValue = `${this.point.value}%`; // 퍼센트 표시
            } else {
              formattedValue = `${this.point.value}`; // 개수 표시
            }
            return `<b>${this.point.name}</b>: ${formattedValue}`;
          }
        },
      };

      // 반응형 변수의 series 값을 업데이트
      reactiveResultForSurveyByCountry.value.series = [{
        colorByPoint: true,
        data: Object.entries(totalAnswers).map(([country, count], index) => ({
          colorValue: index + 1,
          name: `${getCountry(country)}`, // 국가 이름
          countryCode: country, // 국가 코드 추가
          value: count || 0, // 개수
        }))
      }];
    }

    // 기타 설문 차트 가공
    const getModifiedChartOptions = (item, index) => {

      // item.answers_form에서 xAxis.categories 설정
      const xAxisCategories = Object.values(item.answer_form).map(answer => answer.ko);

      // item.answers.total에서 series.data 설정, 일치하지 않는 카테고리는 0으로
      const colors = [
        '#95A4FC', '#BAEDBD', '#1C1C1C', '#B1E3FF', '#C2C6CA', '#A8C5DA'
      ];
      const seriesData = Object.keys(item.answers.total).map((key, idx) => {
        return {
          y: item.answers.total[key] || 0, // total 객체에 대응하는 값이 없으면 0으로 처리
          color: colors[idx % colors.length] // 색상 배열에서 순차적으로 색상을 가져옴
        };
      });

      // 차트의 고유 옵션 객체를 반환하여 복제된 차트마다 다른 옵션을 적용
      return {
        ...reactiveEtcSurveyResult.value,
        xAxis: {
          categories: xAxisCategories,  // 1D 배열로 설정
        },
        series: [
          {
            data: seriesData  // 중첩되지 않도록 바로 설정
          }
        ]
      };
    };


    // 차트 가공 끝 ---------------------------------


    // 상담회 만족도 섹션 ----------------------------------------------
    const activeTabForSec3 = ref('buyer');
    const setActiveTabForSec3 = (tab) => {
      activeTabForSec3.value = tab;
    };
    // 평균 점수를 계산하는 computed 속성
    const averageScore = computed(() => {
      // 활성화된 탭에 따라 점수 배열 설정
      const scores = activeTabForSec3.value === 'buyer'
        ? [dashboardDataForCount.value[8]?.answers?.score, dashboardDataForCount.value[9]?.answers?.score, dashboardDataForCount.value[10]?.answers?.score, dashboardDataForCount.value[11]?.answers?.score]
        : [dashboardDataForCount.value[12]?.answers?.score, dashboardDataForCount.value[13]?.answers?.score, dashboardDataForCount.value[14]?.answers?.score];

      // 유효한 점수만 필터링
      const validScores = scores.filter(score => score !== undefined);
      // 총합 계산
      const total = validScores.reduce((acc, score) => acc + score, 0);
      // 평균 점수 반환 (유효한 점수가 없으면 0 반환)
      return validScores.length ? (total / validScores.length).toFixed(1) : 0;
    });


    // 텍스트 치환
    const satisfactionText = computed(() => {
      // 활성화된 탭에 따라 점수 가져오기
      const score = averageScore.value

      if (score >= 1 && score < 2) {
        return "불만족";
      } else if (score >= 2 && score < 3) {
        return "보통으로 응답";
      } else if (score >= 3 && score < 4) {
        return "만족";
      } else if (score >= 4 && score <= 5) {
        return "매우 만족";
      } else {
        return "-"; // 예상치 못한 값 처리
      }
    });

    // 차트 데이터를 계산하는 computed 속성
    const chartData = computed(() => {
      // 활성화된 탭에 따라 차트 데이터 반환
      if (activeTabForSec3.value === 'buyer') {
        return [
          { score: dashboardDataForCount.value[8]?.answers?.score || 0, label: '품질' },
          { score: dashboardDataForCount.value[9]?.answers?.score || 0, label: '가격' },
          { score: dashboardDataForCount.value[10]?.answers?.score || 0, label: '시장성' },
          { score: dashboardDataForCount.value[11]?.answers?.score || 0, label: '상담' },
        ];
      } else {
        return [
          { score: dashboardDataForCount.value[12]?.answers?.score || 0, label: '구매력' },
          { score: dashboardDataForCount.value[13]?.answers?.score || 0, label: '신뢰도' },
          { score: dashboardDataForCount.value[14]?.answers?.score || 0, label: '상담' },
        ];
      }
    });

    // 바이어 그룹 최대 응답 수
    const maxBuyerAnswerCount = computed(() => {
      const buyerKeys = [8, 9, 10, 11];
      const answerCounts = buyerKeys.map(key => dashboardDataForCount.value[key]?.answer_count_not_null || 0);
      return Math.max(...answerCounts);
    });

    // 셀러 그룹 최대 응답 수
    const maxSellerAnswerCount = computed(() => {
      const sellerKeys = [12, 13, 14];
      const answerCounts = sellerKeys.map(key => dashboardDataForCount.value[key]?.answer_count_not_null || 0);
      return Math.max(...answerCounts);
    });
    // 상담회 만족도 섹션 끝----------------------------------------------

    //상담유형 -----------------------------------------
    const activeTabForSec4_1 = ref('total');
    const setActiveTabForSec4_1 = (tab) => {
      activeTabForSec4_1.value = tab;
      processSurveyTypeData(tab)
    };
    // 상담유형 끝 -----------------------------------------

    //상담일지 -----------------------------------------
    const activeTabForSec4_2 = ref('total');
    const setActiveTabForSec4_2 = (tab) => {
      activeTabForSec4_2.value = tab;
      processSurveyStatusData(tab)
    };
    // 상담유형 끝 -----------------------------------------

    //실적 순위 -----------------------------------------
    const activeTabForSec5 = ref('buyer');
    const setActiveTabForSec5 = (tab) => {
      activeTabForSec5.value = tab;
    };
    //실적 순위 끝 -----------------------------------------

    //성과지표 -----------------------------------------
    const activeTabForSec6_2 = ref('country');
    const setActiveTabForSec6_2 = (tab) => {
      activeTabForSec6_2.value = tab;
      // processBuyerCountryData()
    };
    const activeTabForSec6 = ref('surveyCount');
    const setActiveTabForSec6 = (tab) => {
      activeTabForSec6.value = tab;
      processBuyerCountryData()
    };
    const activeTabForSec6Name = computed(() => {
      const tabNames = {
        surveyCount: '상담건수',
        exportAmount: '수출상담액',
        contractAmount: '계약추진액',
        tradeability: '거래가능성'
      };
      return tabNames[activeTabForSec6.value] || 'Unknown Tab';  // 값이 없는 경우 'Unknown Tab' 반환
    });
    //성과지표 끝 -----------------------------------------

    // 기타 질문 -----------------------------------------
    // 필터링된 데이터 계산 속성
    const filteredETCData = computed(() => {
      // 객체의 값을 배열로 변환하여 필터링
      const withoutIndices = Object.values(dashboardDataForCount.value)
        .filter((item, index) => index < 0 || index > 13); // 인덱스 1부터 14까지 제외

      const filtered = withoutIndices
        .filter((item) => item.cq_type === 'SINGLE' || item.cq_type === 'MULTIPLE'); // cq_type이 SINGLE 또는 MULTIPLE인 것만

      return filtered;
    });
    // 기타 질문 끝-----------------------------------------




    // 쉼표처리 포매팅 -----------------------------------------
    // 총 수출상담액 쉼표처리
    const formattedTotalCFee = computed(() => {
      return dashboardDataForFee.value.total_c_fee !== undefined
        ? dashboardDataForFee.value.total_c_fee.toLocaleString()
        : '0';
    });
    // 총 상담건수 쉼표처리
    const formattedTotalSurvey = computed(() => {
      const total = dashboardDataForSurvey.value.total;

      // total이 객체인 경우 모든 값을 더함
      if (total !== undefined && typeof total === 'object') {
        const sum = Object.values(total).reduce((acc, curr) => acc + curr, 0);
        return sum.toLocaleString();
      }

      // total이 정의되지 않았거나 객체가 아닐 경우 '0' 반환
      return '0';
    });
    // 총 계약추진액 쉼표처리
    const formattedTotalPFee = computed(() => {
      return dashboardDataForFee.value.total_p_fee !== undefined
        ? dashboardDataForFee.value.total_p_fee.toLocaleString()
        : '0';
    });
    // 총 현장계약액 쉼표처리
    const formattedTotalFFee = computed(() => {
      return dashboardDataForFee.value.total_f_fee !== undefined
        ? dashboardDataForFee.value.total_f_fee.toLocaleString()
        : '0';
    });
    // 쉼표처리 포매팅 끝-----------------------------------------

    onMounted(() => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token; // 인증된 경우 토큰 설정
        fetchSurveyDashboardData()
      }
    });

    return {
      eventId, // 사용하고자 하는 변수들을 반환
      subHeader,
      isAuthenticated,
      token,
      activeSub,
      eventDateList,

      //탭
      activeTabForSec3,
      setActiveTabForSec3,
      activeTabForSec4_1,
      setActiveTabForSec4_1,
      activeTabForSec4_2,
      setActiveTabForSec4_2,
      activeTabForSec5,
      setActiveTabForSec5,
      activeTabForSec6,
      setActiveTabForSec6,
      activeTabForSec6Name,
      activeTabForSec6_2,
      setActiveTabForSec6_2,

      // axios 데이터
      dashboardDataForFee,
      dashboardDataForSurvey,
      dashboardDataForCount,
      getModifiedChartOptions,

      //차트
      reactiveSurveyTypeOptions,
      reactiveSurveyStatusOptions,
      reactiveResultForSurveyByCountry,
      activeLabelCountryData,
      getCountry,

      // 컴퓨팅, 포맷팅
      averageScore,
      chartData,
      satisfactionText,
      formattedTotalCFee,
      formattedTotalSurvey,
      formattedTotalPFee,
      formattedTotalFFee,
      filteredETCData,
      maxBuyerAnswerCount,
      maxSellerAnswerCount
    };
  },
};
</script>

<template>
  <div id="surveyDashboard" class="back_light_gray3">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <div class="title_wrap">
          <p class="size_22 b_700">상담회 대시보드</p>
          <p class="sub_text">수출상담회 성과를 수치와 그래프로 확인하고 목표 달성 여부를 파악할 수 있어요.</p>
        </div>
        <!-- <p class="icon_cont_btn">PDF 다운로드 <i class="icon-download"></i></p> -->
      </div>
      <section class="section1">
        <div class="ds_flex al_center jus_between">
          <p class="size_32 b_700">총 수출 상담액</p>
          <p class="size_58 main_text b_700">$ {{ formattedTotalCFee }}</p>
          <!-- <count-to :from="0" :to="1000" :duration="2000" :autoplay="true"></count-to> -->
        </div>
      </section>
      <section class="section2">
        <div class="ds_flex al_center">
          <div class="cont_box">
            <p class="size_20 b_700 sec2_box_title">총 상담건수</p>
            <div class="ds_flex al_center jus_between">
              <p class="size_32 b_700">{{ formattedTotalSurvey }}<span class="b_500">건</span></p>
            </div>
          </div>
          <div class="cont_box">
            <p class="size_20 b_700 sec2_box_title">총 계약 추진액</p>
            <div class="ds_flex al_center jus_between">
              <p class="size_32 b_700"><span class="size_20 b_500">$</span>{{ formattedTotalPFee }}</p>
            </div>
          </div>
          <div class="cont_box">
            <p class="size_20 b_700 sec2_box_title">총 현장 계약액</p>
            <div class="ds_flex al_center jus_between">
              <p class="size_32 b_700"><span class="size_20 b_500">$</span>{{ formattedTotalFFee }}</p>
            </div>
          </div>
          <div class="cont_box">
            <p class="size_20 b_700 sec2_box_title">평균 거래가능성</p>
            <div class="ds_flex al_center jus_between">
              <p class="size_32 b_700">{{ dashboardDataForCount[5]?.answers?.percent?.total ?? 0 }}<span
                  class="b_500">%</span></p>
            </div>
          </div>
        </div>
      </section>
      <section class="section3">
        <div class="cont_box">
          <div class="ds_flex al_center jus_between">
            <div class="title_wrap">
              <p class="size_24 b_700">상담회 만족도</p>
              <p class="size_14 sub_text"><span class="b_700 sub_text">점수기준</span>~2점: 불만족, ~3점:보통, ~4점:만족, ~5점:매우 만족
              </p>
            </div>
            <div class="tab_wrap">
              <p @click="setActiveTabForSec3('buyer')" :class="{ active: activeTabForSec3 === 'buyer' }">바이어</p>
              <p @click="setActiveTabForSec3('seller')" :class="{ active: activeTabForSec3 === 'seller' }">셀러</p>
            </div>
          </div>
          <div class="ds_flex al_center jus_center sec3_cont_wrap">
            <p class="size_26 b_700"> {{ activeTabForSec3 === 'buyer' ? '바이어들은' : '셀러들은' }}<br />상담회에 <span
                :class="[averageScore < 2 ? 'warning_text' : 'main_text']">{{ satisfactionText }}</span>했어요
            </p>
            <div class="ds_flex al_center">
              <div class="sec3_cont_score">
                <p class="size_32 b_700">{{ averageScore }} <span class="size_20 sub_text">/ 5</span></p>
                <p class="size_14 b_400 sub_text">{{ activeTabForSec3 === 'buyer' ? maxBuyerAnswerCount :
                  maxSellerAnswerCount }}명 응답</p>
              </div>
              <div class="sec3_cont_chart_wrap">
                <div v-for="(item, index) in chartData" :key="index" class="sec3_cont_chart">
                  <p class="chart_score">{{ item.score }}</p>
                  <div class="chart_graph">
                    <!-- 점수 비율에 따라 높이 설정 -->
                    <p class="active" :class="[activeTabForSec3 === 'buyer' ? 'back_main' : 'back_seller']"
                      :style="`height: ${(item.score / 5) * 100}%`"></p>
                  </div>
                  <p class="chart_label">{{ item.label }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section4 ds_flex al_center jus_between">
        <div class="cont_box">
          <div class="title_wrap">
            <p class="size_24 b_700">상담유형</p>
            <p class="sub_text b_400 size_14">상담 취소·추가 현황과 현장 주선 비율을 파악하여 통역사 업무를 모니터링할 수 있어요.</p>
          </div>
          <div class="bar_tab_wrap">
            <p :class="{ 'active': activeTabForSec4_1 === 'total' }" @click="setActiveTabForSec4_1('total')">전체날짜</p>
            <p :class="{ 'active': activeTabForSec4_1 === item }" v-for="item, index in eventDateList" :key="index"
              @click="setActiveTabForSec4_1(item)">{{ item }}</p>
          </div>
          <highcharts :options="reactiveSurveyTypeOptions" ref="chart"></highcharts>
        </div>
        <div class="cont_box">
          <div class="title_wrap">
            <p class="size_24 b_700">상담일지</p>
            <p class="sub_text b_400 size_14">집계율로 데이터 완성도를 확인하고, 우수상담 비율을 통해 계약 성사율을 예측할 수 있어요.</p>
          </div>
          <div class="bar_tab_wrap">
            <p :class="{ 'active': activeTabForSec4_2 === 'total' }" @click="setActiveTabForSec4_2('total')">전체날짜</p>
            <p :class="{ 'active': activeTabForSec4_2 === item }" v-for="item, index in eventDateList" :key="index"
              @click="setActiveTabForSec4_2(item)">{{ item }}</p>
          </div>
          <highcharts :options="reactiveSurveyStatusOptions" ref="chart"></highcharts>
        </div>
      </section>
      <section class="section5">
        <div class="cont_box">
          <div class="title_wrap">
            <p class="size_24 b_700">실적순위</p>
            <p class="sub_text b_400 size_14">수출상담액을 기준으로 주요 성과 업체를 파악할 수 있어요.</p>
          </div>
          <div class="bar_tab_wrap">
            <p class="tab" :class="{ active: activeTabForSec5 === 'buyer' }" @click="setActiveTabForSec5('buyer')">바이어
            </p>
            <p class="tab" :class="{ active: activeTabForSec5 === 'seller' }" @click="setActiveTabForSec5('seller')">셀러
            </p>
          </div>
          <div class="sec5_rank_wrap">
            <div class="sec5_rank"
              v-for="(item, index, i) in (activeTabForSec5 === 'buyer' ? dashboardDataForFee.buyer_c_dict : dashboardDataForFee.seller_c_dict)"
              :key="index">
              <p class="rank_badge">{{ i + 1 }}위</p>
              <p class="size_18 b_700 company">{{ index }}</p>
              <p class="size_18 secondary_text fee">$ {{ item.toLocaleString() }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="section6">
        <div class="cont_box">
          <div class="ds_flex al_center jus_between">
            <div class="title_wrap">
              <p class="size_24 b_700">성과지표</p>
              <p class="sub_text b_400 size_14">각 기준별로 성과가 높은 국가와 주요 분야 및 품목을 파악할 수 있어요.</p>
            </div>
            <div class="tab_wrap">
              <p @click="setActiveTabForSec6_2('country')" :class="{ active: activeTabForSec6_2 === 'country' }">국가별 성과
              </p>
              <p @click="setActiveTabForSec6_2('product')" :class="{ active: activeTabForSec6_2 === 'product' }"
                v-if="Object.keys(dashboardDataForCount.country_product_count ?? {}).length">품목군별 성과</p>
            </div>
          </div>
          <div class="bar_tab_wrap">
            <p :class="{ active: activeTabForSec6 === 'surveyCount' }" @click="setActiveTabForSec6('surveyCount')">상담건수
            </p>
            <p :class="{ active: activeTabForSec6 === 'exportAmount' }" @click="setActiveTabForSec6('exportAmount')">
              수출상담액</p>
            <p :class="{ active: activeTabForSec6 === 'contractAmount' }"
              @click="setActiveTabForSec6('contractAmount')">계약추진액</p>
            <p :class="{ active: activeTabForSec6 === 'tradeability' }" @click="setActiveTabForSec6('tradeability')">
              거래가능성</p>
          </div>
          <highcharts :options="reactiveResultForSurveyByCountry" ref="chart" v-show="activeTabForSec6_2 === 'country'">
          </highcharts>
          <template
            v-if="activeTabForSec6_2 === 'country' && Object.keys(dashboardDataForCount.country_product_count ?? {}).length">
            <div class="sec6_cont">
              <p class="size_20 b_700"><span class="b_700 eqqui_text">{{ getCountry(activeLabelCountryData) }}</span>
                바이어의 품목군별 <span class="b_700 eqqui_text">{{ activeTabForSec6Name }}</span> 순위
              </p>
            </div>
            <div class="sec6_rank_wrap">
              <template v-if="activeTabForSec6 === 'surveyCount'">
                <div class="sec6_rank"
                  v-for="item, index, i in dashboardDataForCount.country_product_count?.[activeLabelCountryData]"
                  :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">{{ item }}건</p>
                </div>
              </template>
              <template v-else-if="activeTabForSec6 === 'exportAmount'">
                <div class="sec6_rank"
                  v-for="item, index, i in dashboardDataForFee.country_product_c_dict?.[activeLabelCountryData]"
                  :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">$ {{ item.toLocaleString() }}</p>
                </div>
              </template>
              <template v-else-if="activeTabForSec6 === 'contractAmount'">
                <div class="sec6_rank"
                  v-for="item, index, i in dashboardDataForFee.country_product_p_dict?.[activeLabelCountryData]"
                  :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">$ {{ item.toLocaleString() }}</p>
                </div>
              </template>
              <template v-else-if="activeTabForSec6 === 'tradeability'">
                <div class="sec6_rank"
                  v-for="item, index, i in dashboardDataForCount[5]?.answers?.percent?.country_product?.[activeLabelCountryData]"
                  :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">{{ item }}%</p>
                </div>
              </template>
            </div>
          </template>
          <template v-else-if="activeTabForSec6_2 === 'product'">
            <div class="sec6_rank_wrap">
              <template v-if="activeTabForSec6 === 'surveyCount'">
                <div class="sec6_rank" v-for="item, index, i in dashboardDataForCount.product_count" :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">{{ item }}건</p>
                </div>
              </template>
              <template v-else-if="activeTabForSec6 === 'exportAmount'">
                <div class="sec6_rank" v-for="item, index, i in dashboardDataForFee.product_c_dict" :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">$ {{ item.toLocaleString() }}</p>
                </div>
              </template>
              <template v-else-if="activeTabForSec6 === 'contractAmount'">
                <div class="sec6_rank" v-for="item, index, i in dashboardDataForFee.product_p_dict" :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">$ {{ item.toLocaleString() }}</p>
                </div>
              </template>
              <template v-else-if="activeTabForSec6 === 'tradeability'">
                <div class="sec6_rank" v-for="item, index, i in dashboardDataForCount[5]?.answers?.percent?.product"
                  :key="i">
                  <p class="rank_badge">{{ i + 1 }}위</p>
                  <p class="size_18 b_700 company">{{ index }}</p>
                  <p class="size_18 secondary_text fee">{{ item }}%</p>
                </div>
              </template>
            </div>
          </template>
        </div>
      </section>
      <section class="section7" v-if="filteredETCData.length > 0">
        <div class="cont_box">
          <div class="title_wrap">
            <p class="size_24 b_700">기타 설문 응답 결과</p>
            <p class="sub_text b_400 size_14">추가된 상담일지 질문의 객관식 응답 결과예요.</p>
          </div>
          <div class="sec7_cont_wrap">
            <div class="sec7_cont" v-for="item, index in filteredETCData" :key="index">
              <div class="text_wrap">
                <p class="b_700">Q. {{ item.title.ko }}</p>
                <p class="size_14 secondary_text">응답:{{ item.answer_count_not_null }}개</p>
              </div>
              <highcharts :options="getModifiedChartOptions(item, index)" ref="chart"></highcharts>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section8">
      <div class="mx_1160">
        <div class="ds_flex jus_between al_center">
          <p class="size_20 b_600 white_text">전체 수출상담회의 빅데이터는 메타 필드에서 확인할 수 있어요.</p>
          <p class="icon_cont_btn">Meta-Field에서 실적 분석하기 <i class="icon-up_arrow rotate90 white_text"></i></p>
        </div>
      </div>
    </section>
  </div>
</template>
