<script>
import { ref, defineComponent, onMounted, inject, watch, computed, reactive } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatLang from '@/assets/js/bisatLang.json';

export default defineComponent({
  props: {
    showMessageModal: Boolean,
    selectedUserData: Object,
    attendUserList: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);

    // 유저 데이터
    const userInfo = reactive({ ...props.selectedUserData.userData });
    const userType = computed(() => props.selectedUserData.type);


    const closeModals = () => {
      emit('close');
      userInfo.value = {}; // 초기값으로 빈 객체 설정
    }

    const getLanguage = (code) => {
      const languageLabel = bisatLang.find(item => item.code === code);
      return languageLabel ? languageLabel.label : '';
    };


    // 메시지 전송 예약
    const selectDate = ref('')
    const selectAll = ref(false)
    const exceptNoShow = ref(false)
    const localAttendUserList = ref([]);
    const sendMessageDate = ref([{ date: selectDate, time: '00:00' }]);
    const masks_date = ref({ modelValue: 'YYYY-MM-DD' });
    const masks_time = ref({ modelValue: 'HH:mm' });
    const rules = ref({ minutes: { interval: 1 } });

    // props와 관련된 watch 설정
    watch(
      () => props.attendUserList,
      (newList) => {
        localAttendUserList.value = newList.map(user => ({
          user: user,
          checked: false
        }));
      },
      { deep: true, immediate: true }
    );

    // 전체 선택/해제 토글 함수
    const toggleSelectAll = () => {
      const newValue = !isSelectAllChecked.value;
      localAttendUserList.value.forEach(item => {
        item.checked = newValue;
      });
      updateSelectAllStatus();
    };

    // 전체 선택 상태를 계산하는 computed 변수
    const isSelectAllChecked = computed(() => {
      return localAttendUserList.value.length > 0 && localAttendUserList.value.every(item => item.checked);
    });

    // 부분 선택 상태를 계산하는 computed 변수
    const isSelectAllPartial = computed(() => {
      return localAttendUserList.value.some(item => item.checked) && !isSelectAllChecked.value;
    });

    // selectAll 상태 업데이트 함수
    const updateSelectAllStatus = () => {
      selectAll.value = isSelectAllChecked.value;
    };

    // localAttendUserList의 항목 체크 상태 변경 시 selectAll 상태 업데이트
    const updateSelectAllStatusOnChange = () => {
      if (isSelectAllChecked.value) {
        selectAll.value = true;
      } else if (isSelectAllPartial.value) {
        selectAll.value = false; // 부분 선택 상태일 때는 false
      } else {
        selectAll.value = false;
      }
    };

    // watch를 사용하여 localAttendUserList의 체크 상태 변화 감지
    watch(isSelectAllChecked, (newVal) => {
      if (newVal) {
        exceptNoShow.value = false; // 
      }
    });

    // exceptNoShow 상태에 따라 NOSHOW 항목의 checked 값을 변경하는 함수
    const handleExceptNoShowChange = () => {
      if (exceptNoShow.value) {
        localAttendUserList.value.forEach(item => {
          if (item.user.code_name === 'NOSHOW') {
            item.checked = false;
          }
        });
      }
    };

    // exceptNoShow 상태 변화를 감지하여 처리
    watch(exceptNoShow, handleExceptNoShowChange);

    // 아이콘 클래스 반환 함수
    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };

    // 시간 추가 함수
    const add_time = () => {
      sendMessageDate.value.push({ date: selectDate, time: '00:00' });
    };

    // 시간 삭제 함수
    const delete_time = (index) => {
      if (index >= 0 && index < sendMessageDate.value.length) {
        sendMessageDate.value.splice(index, 1); // 해당 인덱스의 스택을 삭제
      }
    };

    const createKakaoQueue = async () => {
      if (localAttendUserList.value
        .filter(item => item.checked).length === 0) {
        alert('최소 한명의 사용자가 선택되어야 합니다.')
      } else {
        try {
          // datetime_list 배열 생성
          const datetimeList = sendMessageDate.value.map(item => ({
            date: item.date,
            send_time: item.time
          }));

          // 체크된 사용자들의 to_at_id_list 생성
          const toAtIdList = localAttendUserList.value
            .filter(item => item.checked)
            .map(item => item.user.at_id);

          const response = await axios.post('/attendance/create_kakao_queue', {
            piece_event_id: eventId.value,
            to_type: userType.value,
            datetime_list: datetimeList,
            to_at_id_list: toAtIdList
          }, {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          });

          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            alert("저장했습니다.");
            router.go(0)
          } else {
            alert("저장하지 못했습니다.");
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const kakaoQueues = ref([])
    async function fetchKakaoQueues() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          to_type: userType.value,
        });

        const url = `/attendance/read_kakao_queues?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          kakaoQueues.value = response.data.kakao_queues.map(item => {
            let scheduled_date, scheduled_time;
            if (item.scheduled_datetime) {
              const scheduled_datetime = item.scheduled_datetime;
              [scheduled_date, scheduled_time] = scheduled_datetime.split(' ');
            } else {
              scheduled_date = '';
              scheduled_time = '';
            }
            // 각 item의 필요한 데이터 반환
            return {
              ...item,
              scheduled_date,
              scheduled_time,
            };
          })
        } else {
          alert('예약내역 불러오기 오류');
        }
      } catch (error) {
        console.error('예약내역 불러오기 오류:', error);
      }
    }
    watch(
      () => props.showMessageModal, // props.selectedUserData가 없을 때도 안전하게 처리
      async (newValue) => { // 콜백 함수에 async 추가
        if (newValue) { // newValue가 존재하는 경우만 fetchKakaoQueues 실행
          selectAll.value = true;
          await fetchKakaoQueues();
        }
      },
      { immediate: true } // 컴포넌트가 처음 로드될 때도 감지해서 실행
    );

    const deleteKakaoQueue = async (queue_id) => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete('/attendance/delete_kakao_queue', {
            headers: {
              Authorization: `Bearer ${token.value}`
            },
            data: {
              kq_id: queue_id
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go(0);
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        selectDate.value = store.state.eventStore.eventDate;
        localAttendUserList.value = props.attendUserList.map(user => ({
          user: user,
          checked: false
        }));
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      userType,
      userInfo,
      getLanguage,
      selectAll,
      exceptNoShow,
      localAttendUserList,
      toggleSelectAll,
      isSelectAllChecked,
      isSelectAllPartial,
      getIconClass,
      sendMessageDate,
      masks_date,
      masks_time,
      rules,
      add_time,
      delete_time,
      createKakaoQueue,
      kakaoQueues,
      deleteKakaoQueue
    }
  },
});
</script>

<template>
  <div id="operateAttendTableModal">
    <transition name="slide-fade">
      <div class="sidemodal" v-if="showMessageModal">
        <p class="size_26 b_700">메시지 전송 예약</p>
        <p class="description">메시지를 보낼 대상과 시간을 선택하세요.</p>
        <div class="cont_wrap">
          <div class="cont">
            <p class="secondary_text b_700">보낼 대상</p>
            <div class="ds_flex dir_column gap12 w100">
              <div class="ds_flex jus_between">
                <div class="ds_flex al_center">
                  <label for="selectAll" class="fake_checkbox"
                    :class="{ 'active': isSelectAllChecked || isSelectAllPartial }">
                    <i v-if="isSelectAllChecked" class="icon-checked"></i>
                    <i v-else-if="isSelectAllPartial" class="icon-minus"></i>
                  </label>
                  <input type="checkbox" id="selectAll" v-model="selectAll" @change="toggleSelectAll" />
                  <label class="function_name" for="selectAll">전체 선택</label>
                </div>
                <div class="ds_flex al_center gap4">
                  <label class="except_noshow" for="exceptNoShow" :class="{ 'active': exceptNoShow }">불참 제외</label>
                  <i v-if="exceptNoShow" class="icon-checked"></i>
                  <input type="checkbox" id="exceptNoShow" v-model="exceptNoShow" />
                </div>
              </div>
              <p class="fake_textbox"><input type="text" placeholder="보낼 대상 검색" /><i class="icon-search"></i></p>
              <div class="user_list ds_flex dir_column gap16">
                <div v-for="item in localAttendUserList" :key="item.user.at_id" class="ds_flex user_list_cont">
                  <label :for="'checkbox-' + item.user.at_id" class="fake_checkbox" :class="{ 'active': item.checked }">
                    <i v-if="item.checked" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'checkbox-' + item.user.at_id" v-model="item.checked" />
                  <div>
                    <i v-if="item.user.code_name === 'NOSHOW'"
                      :class="`icon-${getIconClass(userType, 'attended', item.user.code_name)}`"></i>
                    <label class="function_name" :for="'checkbox-' + item.user.at_id">
                      {{ item.user.full_name }}
                      <span>( {{ getLanguage(item.user.lang1) }}<template v-if="item.user.lang2">, {{
                        getLanguage(item.user.lang2) }}</template>)
                      </span>
                    </label>
                    <p class="sub_text">{{ item.user.mobile }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="ds_flex dir_column send_time_wrap gap8">
              <p class="secondary_text b_700">예약한 시간</p>
              <div class="send_time_cont reserve ds_flex al_center jus_between" v-for="(item, index) in kakaoQueues"
                :key="index">
                <div class="ds_flex al_center jus_between">
                  <p class="size_14 b_700 secondary_text">{{ item.scheduled_date }}</p>
                  <p class="size_14 b_700 secondary_text">{{ item.scheduled_time }}</p>
                </div>
                <p class="icon_box" @click="deleteKakaoQueue(item.kq_id)"><i class="icon-trash sub_text"></i></p>
              </div>
            </div>
            <div class="ds_flex dir_column send_time_wrap gap8">
              <p class="secondary_text b_700">보낼 시간</p>
              <div class="send_time_cont ds_flex al_center jus_between" v-for="(item, index) in sendMessageDate"
                :key="index">
                <div class="ds_flex al_center jus_between">
                  <VDatePicker v-model.string="item.date" mode="date" :masks="masks_date">
                    <template #default="{ togglePopover }">
                      <p class="" @click="togglePopover">{{ item.date ? item.date : "YYYY-MM-DD" }}</p>
                    </template>
                  </VDatePicker>
                  <VDatePicker v-model.string="item.time" mode="time" is24hr :rules="rules" :masks="masks_time"
                    hide-time-header>
                    <template #default="{ togglePopover }">
                      <p class="time" @click="togglePopover">{{ item.time ? item.time : "HH:MM" }}</p>
                    </template>
                  </VDatePicker>
                </div>
                <p class="icon_box" @click="delete_time(index)"><i v-if="index > 0" class="icon-trash sub_text"></i></p>
              </div>
              <div class="add_time_table_body b_700" @click="add_time">+ 추가</div>
            </div>
          </div>
        </div>
        <div class="btn_wrap bottom">
          <p class="block_main_btn cancel_btn b_700" @click="closeModals">취소</p>
          <p class="block_main_btn active_btn b_700" @click="createKakaoQueue">저장하기</p>
        </div>
      </div>
    </transition>
  </div>
</template>
