<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import "@/assets/css/modal.css";
import { useStore } from "vuex";
import router from "@/router";
import dropDownLang from "@/components/admin/dropDown/dropDownLang.vue";

export default defineComponent({
	props: {
		showPreviewModal: Object,
	},
	components: {
		dropDownLang,
	},
	setup(props, { emit }) {
		// Axios 인스턴스와 Vuex 스토어 사용
		const axios = inject("axios");
		const store = useStore();
		const eventId = computed(() => store.state.eventStore.eventId);
		const cqId = props.showPreviewModal;
		// 페이지 상태 및 토큰 관리
		const isAuthenticated = ref(false);
		const token = ref("");
		const q = ref({});
		const current_lang = ref("ko");
		const types = ["cn", "en", "jp", "ko"];

		const cq_title = computed(() => {
			if (q.value.cq_title) {
				return q.value.cq_title[current_lang.value];
			} else {
				return "제목이 등록되어있지 않습니다.";
			}
		});

		const closeModals = () => {
			emit("close");
		};

		const questionPreview = async () => {
			try {
				// 전체 URL에 쿼리 스트링을 추가합니다.
				const url = `/consultation/read_question_detail`;
				// Axios 요청을 보냅니다.
				const response = await axios.get(url, {
					params: {
						cq_id: cqId.selected,
					},
					headers: {
						Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
					},
				});

				// 요청이 성공했고 결과가 true인 경우
				if (response.data.result === true) {
					// 결과값 선가공 후 ref 데이터에 집어넣는다.
					const json = response.data.question;
					// 생성일자 날짜 빼고 자르기
					json.created_at = json.created_at.split("T")[0];
					// 상태 색깔 지정
					if (json.is_essential == 1) {
						json.stat_class = "back_eqqui";
					} else {
						json.routable = true;
						if (json.is_in_use == 0) {
							json.stat_class = "back_not_entered";
						} else {
							json.stat_class = "back_main";
						}
					}
					json.cq_answer_form = Object.values(json.cq_answer_form);
					q.value = json; // 이벤트 리스트를 저장합니다.
					// pagination();
					// initializeTooltips();
				} else {
					alert("질문 리스트를 불러오지 못했습니다.");
				}
			} catch (error) {
				console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
			}
		};

		const changeLang = (lang) => {
			current_lang.value = lang;
		};

		watch(cqId, () => {
			if (cqId.onoff) {
				questionPreview(cqId.selected);
			}
		});

		const uploadedFileName = ref(''); // 업로드된 파일명을 저장할 ref
		const uploadedFile = ref(null);

		const handleFileUpload = (event) => {
			const file = event.target.files[0]; // 업로드된 파일 객체 가져오기
			if (file) {
				uploadedFileName.value = file.name; // 업로드된 파일명 설정
				// 파일 업로드 후 추가적인 작업을 수행할 수 있습니다.
				// 예를 들어, 서버로 파일을 전송하여 저장하는 등의 작업을 수행할 수 있습니다.
				// 여기서 서버로 파일 전송 등의 작업을 수행할 수 있습니다.
			}
		};

		// 컴포넌트 마운트 시 로직
		onMounted(async () => {
			isAuthenticated.value = store.state.userStore.isAuthenticated;
			if (!isAuthenticated.value) {
				router.push({ name: "home" });
			} else {
				token.value = store.state.userStore.token;
			}
			window.addEventListener("keyup", (event) => {
				if (event.code === "Escape") {
					closeModals();
				}
			});
		});
		return {
			eventId,
			closeModals,
			q,
			types,
			current_lang,
			changeLang,
			cq_title,
			handleFileUpload,
			uploadedFile,
			uploadedFileName,
		};
	},
});
</script>

<template>
	<div id="questionPreviewModal">
		<transition name="slide-fade">
			<div class="sidemodal" v-if="showPreviewModal.onoff == true">
				<div class="ds_flex al_center jus_between">
					<p class="size_26 b_700">질문지 미리보기</p>
					<drop-down-lang
						:options="types"
						:defaultValue="current_lang"
						@change="changeLang"
					></drop-down-lang>
				</div>
				<p class="description secondary_text">{{ q.list_title }}의 질문과 답변 형식을 확인하세요.</p>
				<div class="cont">
					<div class="ds_flex al_center gap12">
						<div class="item_status" :class="q.stat_class">
							<i class="icon-bullet_list"></i>
						</div>
						<div class="primary_text size_20 b_700">{{ cq_title }}</div>
					</div>
					<template v-if="q.cq_type == 'SINGLE'">
						<div v-for="(form, index) in q.cq_answer_form" :key="index">
							<input type="radio" name="cq_answer_form" :id="`answer_${index}`" class="radio_wrap_boxed" />
							<label :for="`answer_${index}`" class="radio_wrap_boxed b_700">
								<em></em>
								<div>{{ form[current_lang] }}</div>
							</label>
						</div>
					</template>
					<template v-else-if="q.cq_type == 'MULTIPLE'">
						<div v-for="(form, index) in q.cq_answer_form" :key="index">
							<input type="checkbox" name="cq_answer_form" :id="`answer_${index}`" class="check_wrap_boxed" />
							<label :for="`answer_${index}`" class="check_wrap_boxed b_700">
								<em><i class="icon-checked"></i></em>
								<div>{{ form[current_lang] }}</div>
							</label>
						</div>
					</template>
					<template v-else-if="q.cq_type == 'SHORT'">
						<input type="text" class="fake_textbox" placeholder="답변을 입력해주세요." />
					</template>
					<template v-else-if="q.cq_type == 'LONG'">
						<textarea cols="30" rows="10" placeholder="답변을 입력해주세요."></textarea>
					</template>
					<template v-else-if="q.cq_type == 'NUMBER'">
						<input type="number" class="fake_textbox" placeholder="숫자만 입력해주세요." />
					</template>
					<template v-else-if="q.cq_type == 'FILE'">
						<div class="file_upload ds_flex" :class="{'active' : uploadedFileName}">
							<template v-if="!uploadedFileName">
								<i class="icon-upload size_32 not_entered_text"></i>
								<div class="text_wrap t_center">
									<p class="b_600 secondary_text">파일을 업로드하세요.</p>
									<p class="sub_text size_14">첨부 가능한 파일: </p>
								</div>
								<label for="fileUpload" class="block_cont_btn secondary_text size_14">파일 업로드하기</label>
								<input ref="uploadedFile" id="fileUpload" type="file" style="display: none" @change="handleFileUpload" />
							</template>
							<template v-else>
								<div class="ds_flex gap8 al_center">
									<p class="secondary_text size_14 main_text b_700">{{ uploadedFileName }}</p>
									<i class="icon-delete"></i>
								</div>
							</template>
						</div>
					</template>
				</div>
				<div class="btn_wrap bottom">
					<p class="block_main_btn cancel_btn" @click="closeModals()">닫기</p>
				</div>
			</div>
		</transition>
	</div>
</template>
