<script>
import { ref, onMounted, computed, inject, reactive, watch } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import field from '@/assets/js/bisatField'
import EventSettingModal from '@/components/admin/eventSettingModal.vue';
import { offset } from '@popperjs/core';
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';


export default {
  name: 'eventSetting',
  components: {
    EventSettingModal,
    subHeader,
    tabHeader
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const userName = ref('');
    const token = ref('');
    const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
    const accountType = computed(() => store.state.userStore.accountType);
    const eventId = ref('');
    const activeSub = ref('setting'); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref('home'); // 여기에서 'home'이라는 값을 정의합니다.
    const isAdmin = ref(false)

    const checkPermissionsAndRedirect = () => {
      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 비인증 사용자를 홈으로 리다이렉션
        return false;
      } else if (accountType.value !== 'A1' && accountType.value !== 'A2') {
        isAdmin.value = true
        return true;
      }
      return true;
    };

    async function loadEventDetails() {
      userName.value = store.state.userStore.userName;
      token.value = store.state.userStore.token;
      eventId.value = router.currentRoute.value.params.eventId;
      store.commit('eventStore/setEventId', eventId.value);

      if (eventId.value) {
        try {
          await fetchEventDetail();
        } catch (error) {
          console.error('Error loading event details:', error);
          alert('이벤트를 불러오지 못했습니다.');
          router.push('/admin/event/select');
        }
      }
    }

    const isUpdateMode = computed(() => {
      // eventId가 null이나 undefined가 아니고, 빈 문자열이 아닌 경우에만 true를 반환
      return eventId.value != null && eventId.value !== '';
    });

    const eventData = ref({
      is_pre_arranged: 'N',
      is_reception: 'N',
      is_matched: 'N',
      is_video_link: 'N',
      status: 0,
      video_provider: 'OFF',
      info_desk_type: 'ALL'
    });

    const eventData_checkbox = reactive([
      { id: 'is_pre_arranged', label: '사전주선', checked: false },
      { id: 'is_reception', label: '영접', checked: false },
      { id: 'is_matched', label: '현장매칭', checked: false },
      { id: 'is_video_link', label: '화상상담', checked: false }
    ]);
    const newItem = ref('');
    const itemList = ref([]);
    const datetimeList = ref([]);

    //DATE SELECT
    const masks_date = ref({
      modelValue: 'YYYY-MM-DD',
    });

    const masks_time = ref({
      modelValue: 'HH:mm',
    });

    const rules = ref({
      minutes: { interval: 5 },
    });

    const fieldSelect = field.map(item => ({
      label: item.label,
      value: item.code
    }))
    const getFieldLabel = (fieldValue) => {
      const field = fieldSelect.find(item => item.value === fieldValue);
      return field ? field.label : '';
    };

    const statusSelect = ref([
      { label: '준비중', value: 0 },
      { label: '진행중', value: 1 },
      { label: '종료', value: 2 }
    ])
    const getStatusLabel = (statusValue) => {
      const status = statusSelect.value.find(item => item.value === statusValue);
      return status ? status.label : '';
    };

    const addItem = () => {
      if (newItem.value.trim() !== '') {
        itemList.value.push(newItem.value);
        newItem.value = ''; // Clear input after adding
        eventData.value['product_list'] = itemList.value;
      }
    };
    const deleteItem = (index) => {
      itemList.value.splice(index, 1);
      eventData.value['product_list'] = itemList.value;
    };

    const add_time_table = () => {
      const today = new Date();
      const formattedDate = today.toISOString().slice(0, 10);
      const formattedStartTime = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
      const formattedEndTime = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
      if (datetimeList.value.length < 1) {
        datetimeList.value.push({
          date: formattedDate,
          start_time: formattedStartTime,
          end_time: formattedEndTime
        });
      } else {
        const lastItem = datetimeList.value[datetimeList.value.length - 1];
        const lastDate = lastItem.date;
        const lastStartTime = lastItem.start_time.split(':');
        const lastEndTime = lastItem.end_time.split(':');

        // 이전 스택의 시간에 1시간을 더합니다.
        const nextStartTime = new Date(0, 0, 0, parseInt(lastStartTime[0]), parseInt(lastStartTime[1]) + 60);
        const nextEndTime = new Date(0, 0, 0, parseInt(lastEndTime[0]), parseInt(lastEndTime[1]) + 60);

        const formattedNextStartTime = ('0' + nextStartTime.getHours()).slice(-2) + ':' + ('0' + nextStartTime.getMinutes()).slice(-2);
        const formattedNextEndTime = ('0' + nextEndTime.getHours()).slice(-2) + ':' + ('0' + nextEndTime.getMinutes()).slice(-2);

        datetimeList.value.push({
          date: lastDate,
          start_time: formattedNextStartTime,
          end_time: formattedNextEndTime
        });
      }
      eventData.value['datetime_list'] = datetimeList.value;
    };

    const add_time_table_group = () => {
      if (datetimeList.value.length < 2) {
        // datetimeList가 비어있으면 아무것도 하지 않음        
        alert("2개 이상의 스케줄이 있어야합니다.");
        return '';
      }

      // datetimeList의 마지막 항목에서 날짜를 추출하고 하루를 더함
      const lastItemDate = new Date(datetimeList.value[datetimeList.value.length - 1].date);
      lastItemDate.setDate(lastItemDate.getDate() + 1);
      const formattedNewDate = lastItemDate.toISOString().slice(0, 10);

      // datetimeList에서 마지막 날짜와 일치하는 항목들을 찾음
      const lastDayItems = datetimeList.value.filter(item => item.date === datetimeList.value[datetimeList.value.length - 1].date);

      // 찾은 항목들을 복사하여 새 날짜로 갱신
      lastDayItems.forEach(item => {
        const newItem = {
          date: formattedNewDate,
          start_time: item.start_time,
          end_time: item.end_time
        };

        // 갱신된 항목을 datetimeList에 추가
        datetimeList.value.push(newItem);
        eventData.value['datetime_list'] = datetimeList.value;
      });
    };


    // 스택 삭제 기능 추가
    const delete_time_table = (index) => {
      if (index >= 0 && index < datetimeList.value.length) {
        datetimeList.value.splice(index, 1); // 해당 인덱스의 스택을 삭제
        eventData.value['datetime_list'] = datetimeList.value;
      }
    };


    const isSurvey = ref(false)
    const isSurveyI = ref(false);
    const isSatisfy = ref(false);
    const surveyRoute = ref("surveySetting");
    watch(
      // 사용중인 언어 변경 디텍
      () => store.state.eventStore.masterId,
      (newMasterId, oldMasterId) => {
        //MasterId
        if (newMasterId !== oldMasterId) {
          if (store.state.eventStore.masterId != null) {
            surveyRoute.value = "masterSurveySetting";
          } else {
            surveyRoute.value = "surveySetting";
          }
        }
      },
      { immediate: true }
    );

    const returnBadgeText = type => {
      if (type == 'INTERPRETER') {
        return "통역사"
      } else if (type == 'BUYER') {
        return "바이어"
      } else if (type == 'SELLER') {
        return "셀러"
      }
    }

    async function fetchEventDetail() {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/event/read_piece', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        const pieceEvent = response.data.piece_event;

        // product_list에서 제품 이름만 추출하여 저장
        itemList.value = pieceEvent.product_list ? pieceEvent.product_list.map(item => item.product) : [];

        // datetime_list가 있는 경우 datetime만 추출, 없는 경우 빈 배열
        datetimeList.value = pieceEvent.datetime_list ? pieceEvent.datetime_list.map(item => item) : [];
        if (response.data.piece_event.introduction_file) {
          getPresignedUrl(response.data.piece_event.introduction_file)
        }

        // 상담일지 유무 체크
        if (pieceEvent.exist_ptc_type.length > 0 || pieceEvent.exist_mtc_type.length > 0) {
          isSurvey.value = true;
          isSurveyI.value = pieceEvent.exist_ptc_type.includes('INTERPRETER') || pieceEvent.exist_mtc_type.includes('INTERPRETER');
        }

        // 사전 설문 유무 체크
        if (pieceEvent.exist_pts_type.length > 0) {
          isSatisfy.value = true;
        }
        // 나머지 이벤트 데이터 설정
        eventData.value = {
          ...pieceEvent,
          product_list: itemList.value,  // 조정된 product_list 사용
          datetime_list: datetimeList.value  // 조정된 datetime_list 사용
        };
        store.commit('eventStore/setEventTitle', eventData.value.piece_event_title);
        store.commit('eventStore/setMasterId', eventData.value.master_event_id);
        store.commit('eventStore/isActiveMenu', eventData.value);
        store.commit('eventStore/isActiveSurvey', eventData.value);
        store.commit('eventStore/setEventDate', '');
      } catch (error) {
        console.error('이벤트 데이터를 가져오는 중에 오류가 발생했습니다:', error);
        alert('이벤트를 불러오지 못했습니다.');
        router.push('/admin/event/select');  // 에러 발생 시 리다이렉션
      }
    }


    function updateCheckboxValue(checkboxId, isChecked) {
      console.log(eventData.value[checkboxId])
      eventData.value[checkboxId] = isChecked ? 'Y' : 'N';  // 조건에 따라 'Y' 또는 'N'으로 설정
    }

    const showImageUploadModal = ref(false);
    const showUserATypeModal = ref(false);
    const showUserBTypeModal = ref(false);

    const showModal = (type) => {
      showImageUploadModal.value = type === 'image';
      showUserATypeModal.value = type === 'userA';
      showUserBTypeModal.value = type === 'userB';
    };

    const closeAllModals = (type) => {
      showImageUploadModal.value = false;
      showUserATypeModal.value = false;
      showUserBTypeModal.value = false;
    };

    const updateEventAccountList = (newSelectedPM) => {
      console.log("Received data from child:", newSelectedPM);
      eventData.value.a_account_list = newSelectedPM;
    }

    const updateEventClientList = (newSelectedClient) => {
      console.log("Received data from child:", newSelectedClient);
      eventData.value.b_account_list = newSelectedClient;
    }

    const isProcessing = ref(false);
    const processEditModeOrSaveMode = async (event) => {
      event.preventDefault(); // 클릭 이벤트의 기본 동작 방지
      if (isProcessing.value) {
        alert("처리 중입니다.")
        return; // 이미 처리 중이면 아무것도 하지 않습니다.
      }
      isProcessing.value = true; // 처리 중 상태를 활성화합니다.

      try {
        if (isUpdateMode.value) {
          await updateEvent(); // 수정 모드에서의 이벤트 처리
        } else {
          await saveEvent(); // 저장 모드에서의 이벤트 처리
        }
      } catch (error) {
        console.error("Error processing the event:", error);
        alert("이벤트 처리 중 오류가 발생했습니다."); // 사용자에게 오류 알림
      } finally {
        isProcessing.value = false; // 비동기 작업이 완료된 후에 처리 상태를 비활성화
      }
    };

    const saveEvent = async () => {
      // API 엔드포인트 URL
      const url = "/event/create_piece";

      const postData = {
        ...eventData.value,  // 기존의 eventData.value의 다른 데이터를 유지
        a_account_list: eventData.value.a_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
        b_account_list: eventData.value.b_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
      };

      try {
        // axios POST 요청
        const response = await axios.post(url, postData, {
          headers: {
            // 토큰을 헤더에 포함하여 인증 구현
            'Authorization': `Bearer ${token.value}`
          }
        });
        if (response.data.result === true) {
          // 요청이 성공적으로 완료되면 실행
          console.log("Event saved successfully:", response.data);
          store.commit('eventStore/setEventTitle', eventData.piece_event_title);
          store.commit('eventStore/isActiveMenu', eventData);
          alert("이벤트가 성공적으로 저장되었습니다.");  // 사용자에게 성공 알림
          router.push(`/admin/event/setting/${response.data.piece_event_id}`).then(() => {
            // 라우터 이동 후 새로고침
            window.location.reload();
          });
        } else {
          console.error("Error saving the event:", error);
          alert("이벤트 저장 중 오류가 발생했습니다.");  // 사용자에게 오류 알림
        }
      } catch (error) {
        // 에러 처리
        console.error("Error saving the event:", error);
        alert("이벤트 저장 중 오류가 발생했습니다.");  // 사용자에게 오류 알림
      }
    };

    const updateEvent = async () => {
      const url = "/event/update_piece";
      const postData = {
        ...eventData.value,
        a_account_list: eventData.value.a_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
        b_account_list: eventData.value.b_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
      };

      try {
        const response = await axios.put(url, postData, {
          headers: {
            'Authorization': `Bearer ${token.value}`
          }
        });
        if (response.data.result == true) {
          console.log("Event updated successfully:", response.data);
          store.commit('eventStore/setEventTitle', eventData.value.piece_event_title);
          store.commit('eventStore/isActiveMenu', eventData.value);
          alert("이벤트가 성공적으로 수정되었습니다.");
          router.go()
        } else {
          console.error("Error updating the event:", response.data);
          alert("이벤트 수정 중 오류가 발생했습니다.");
        }
      } catch (error) {
        console.error("Error updating the event:", error);
        alert("이벤트 수정 중 오류가 발생했습니다.");
      }
    };


    async function deleteEvent() {
      confirm("정말 이벤트를 삭제하시겠습니까? 삭제한 데이터는 되돌릴 수 없습니다.")
      try {
        // axios.delete를 사용하여 이벤트 삭제 요청을 보냅니다.
        const response = await axios.delete('/event/delete_piece', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`  // 헤더에 인증 토큰 추가
          }
        });

        // 응답 확인
        if (response.data.result) {
          alert('이벤트가 성공적으로 삭제되었습니다.');
          router.push('/admin/event/select'); // 성공 시 이벤트 목록 페이지로 리다이렉션
        } else {
          alert('이벤트를 삭제하지 못했습니다.');
        }
      } catch (error) {
        console.error('이벤트 삭제 중 오류가 발생했습니다:', error);
        alert('이벤트 삭제 과정에서 오류가 발생했습니다.');
      }
    }

    const goBack = () => {
      router.go(-1);
    }

    // 상담회 옵션
    const tooltipVideoLink = ref(false)
    const tooltipInfodesk = ref(false)
    const tooltipEventFile = ref(false)

    // 파일 관련 기능
    const uploadedFileName = ref(''); // 업로드된 파일명을 저장할 ref

    const handleFileDelete = () => {
      uploadedFileName.value = '';
      document.getElementById('file-upload').value = ''; // 파일 입력 필드 값 초기화
    };
    const handleFileUpload = async (event) => {
      const file = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (file) {
        uploadedFileName.value = file.name;
        // 여기서 서버로 파일 전송 등의 작업을 수행할 수 있습니다.
        uploadEventFile(file); // 파일 객체를 전달
      }
    };

    const uploadEventFile = async (file) => {
      console.log(file)
      try {
        if (!file) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        // piece_event_id를 URL 파라미터로 추가
        const response = await axios.post(`/file/piece_event?piece_event_id=${eventId.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        console.log('Upload response:', response.data);
        if (response.data.result === true) {
          eventData.value.introduction_file = response.data.file_path; // 업로드된 파일 경로 설정
          getPresignedUrl(response.data.file_path)
        } else {
          alert('파일을 저장하지 못했습니다.');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail === 'Fail upload file - None') {
          uploadedFileName.value = ''
          alert("중복된 파일이 있거나 파일명에 띄어쓰기가 있습니다. 파일명 수정 후 다시 시도해주세요.")
        }
        console.error('Error uploading file:', error);
      }
    };

    const presignedUrl = ref([])
    async function getPresignedUrl(filePath) {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/file/presigned_url', {
          params: {
            file_path: filePath
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        if (response.data.result === true) {
          presignedUrl.value = response.data.file_info
        }

      } catch (error) {
        console.error('파일 경로를 가져오지 못했습니다.:', error);
      }
    }

    const deleteEventFile = async () => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/file/piece_event?piece_event_id=${eventId.value}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go(0);
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };


    onMounted(async () => {
      if (checkPermissionsAndRedirect()) {
        await loadEventDetails();
      }
    });

    return {
      eventId,
      surveyRoute,
      isUpdateMode,
      itemList,
      datetimeList,
      rules,
      masks_date,
      masks_time,
      eventData,
      fieldSelect,
      statusSelect,
      addItem,
      newItem,
      deleteItem,
      eventData_checkbox,
      updateCheckboxValue,
      add_time_table,
      add_time_table_group,
      delete_time_table,
      isSurvey,
      isSurveyI,
      isSatisfy,
      returnBadgeText,
      showImageUploadModal,
      showUserATypeModal,
      showUserBTypeModal,
      showModal,
      closeAllModals,
      updateEventAccountList,
      updateEventClientList,
      isProcessing,
      processEditModeOrSaveMode,
      deleteEvent,
      goBack,
      activeSub,
      activeHeadTab,
      tooltipVideoLink,
      tooltipInfodesk,
      tooltipEventFile,
      uploadedFileName,
      handleFileDelete,
      handleFileUpload,
      deleteEventFile,
      presignedUrl,
      getStatusLabel,
      getFieldLabel,
      isAdmin
    };
  },
}

</script>

<template>
  <div id="eventSetting">
    <sub-header v-if="isUpdateMode" :activeSub="activeSub"></sub-header>
    <div class="evetSetting_wrap mx_1160">
      <div class="common_top" v-if="!isUpdateMode">
        <div class="ds_flex gap18">
          <i class="icon-down_arrow goback size_32" @click="goBack"></i>
          <p class="size_32 b_700 ">상담회 세팅</p>
        </div>
        <router-link to="/admin/event/setting/master/new" class="icon_cont_btn">
          <p class="icon_box"><i class="icon-crown"></i></p>
          <p>마스터 생성하기</p>
        </router-link>
      </div>
      <div class="header_tab_wrap" v-if="isUpdateMode">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="eventSetting_cont_wrap" :class="{ 'new': !isUpdateMode }">
        <div class="eventSetting_info">
          <div class="eventSetting_info ds_flex">
            <div class="eventSetting_info_cont_wrap ds_flex">
              <div class="eventSetting_info_title_wrap ds_flex">
                <div class="eventSetting_info_title ds_flex">
                  <img src="@/assets/img/event/event_setting_icon1.png" class="title_img" />
                  <input type="text" v-model="eventData.piece_event_title" placeholder="행사명을 입력하세요" :disabled="isAdmin">
                </div>
              </div>
              <div class="eventSetting_info_cont ds_flex">
                <label class="eventSetting_info_cont_label">장소<span class="require">*</span></label>
                <input type="text" placeholder="장소를 입력하세요" class="underline_textbox" v-model="eventData.place"
                  :disabled="isAdmin">
              </div>
              <div class="eventSetting_info_cont ds_flex">
                <label class="eventSetting_info_cont_label">고객사<span class="require">*</span></label>
                <div class="ds_flex">
                  <template v-if="eventData.b_account_list">
                    <p class="eventSetting_info_box" v-for="(item, index) in eventData.b_account_list" :key="index">{{
                      item.company
                    }} {{ item.full_name }}</p>
                    <p class="inline_cont_btn" @click="showModal('userB')" v-if="!isAdmin"><i class="icon-edit"></i></p>
                  </template>
                  <template v-else>
                    <p class="block_cont_btn" @click="showModal('userB')">선택</p>
                  </template>
                </div>
              </div>
              <div class="eventSetting_info_cont ds_flex">
                <label class="eventSetting_info_cont_label">담당 PM<span class="require">*</span></label>
                <div class="ds_flex">
                  <template v-if="eventData.a_account_list">
                    <p class="eventSetting_info_box" v-for="(item, index) in eventData.a_account_list" :key="index">{{
                      item.company
                    }} {{ item.full_name }}</p>
                    <p class="inline_cont_btn" @click="showModal('userA')" v-if="!isAdmin"><i class="icon-edit"></i></p>
                  </template>
                  <template v-else>
                    <p class="block_cont_btn" @click="showModal('userA')">선택</p>
                  </template>
                </div>
              </div>
              <div class="eventSetting_info_cont ds_flex">
                <label class="eventSetting_info_cont_label">상담회 상태</label>
                <v-select :options="statusSelect" v-model="eventData.status" label="label" :reduce="item => item.value"
                  :clearable="false" v-if="!isAdmin"></v-select>
                <p v-if="isAdmin" class="secondary_text b_700">{{ getStatusLabel(eventData.status) }}</p>
              </div>
              <div class="eventSetting_info_cont ds_flex">
                <label class="eventSetting_info_cont_label">상담회 분야</label>
                <v-select :options="fieldSelect" v-model="eventData.category" label="label" :reduce="item => item.value"
                  :clearable="false" v-if="!isAdmin" />
                <p v-if="isAdmin" class="secondary_text b_700">{{ getFieldLabel(eventData.category) }}</p>
              </div>
              <div class="eventSetting_info_cont ds_flex">
                <label class="eventSetting_info_cont_label">상담회 품목군</label>
                <div class="item_wrap ds_flex">
                  <input type="text" v-model="newItem" @keyup.enter="addItem" placeholder="입력 후 엔터를 눌러 추가하세요"
                    v-if="!isAdmin">
                  <div class="item_list ds_flex w100">
                    <p v-for="(item, index) in itemList" :key="index" class="item">
                      {{ item }}
                      <span class="delete" @click="deleteItem(index)" v-if="!isAdmin"><i class="icon-delete"></i></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="eventSetting_info_cont_wrap ds_flex">
              <p class="size_18 b_700">상담회 옵션</p>
              <p class="size_14 sub_text">상담회에 필요한 추가 기능을 선택하세요. 체크된 항목으로 상담회가 세팅돼요.</p>
              <div class="eventSetting_info_cont ds_flex jus_between" v-for="(checkbox, index) in eventData_checkbox"
                :key="index">
                <label class="eventSetting_info_cont_label">{{ checkbox.label }}<span class="tooltip_btn"
                    v-if="index === 3" @mouseover="tooltipVideoLink = true"
                    @mouseleave="tooltipVideoLink = false">?</span><span v-if="index === 3" class="tooltip"
                    :class="{ 'active': tooltipVideoLink }">상담
                    링크를 생성할 프로그램을 선택하세요.</span>
                </label>
                <input type="checkbox" :id="checkbox.id" :checked="eventData[checkbox.id] === 'Y'"
                  @change="updateCheckboxValue(checkbox.id, $event.target.checked)" />
                <label class="toggle_box" :for="checkbox.id"
                  :class="{ active: eventData[checkbox.id] === 'Y' }"></label>
                <select v-if="eventData['is_video_link'] === 'Y' && index === 3" v-model="eventData.video_provider">
                  <option value="OFF">프로그램을 선택하세요.</option>
                  <option value="ZOOM">ZOOM</option>
                  <option value="TEAMS">TEAMS</option>
                </select>
              </div>
              <hr />
              <div class="eventSetting_info_cont ds_flex dir_column gap12">
                <label class="eventSetting_info_cont_label w100">인포데스크 리스트
                  <span class="tooltip_btn" @mouseover="tooltipInfodesk = true"
                    @mouseleave="tooltipInfodesk = false">?</span><span class="tooltip"
                    :class="{ 'active': tooltipInfodesk }">인포데스크 리스트에 보여질 대상자를 선택하세요.</span>
                </label>
                <select v-model="eventData.info_desk_type">
                  <option value="ALL">바이어, 셀러 보기</option>
                  <option value="BUYER">바이어만 보기</option>
                  <option value="SELLER">셀러만 보기</option>
                </select>
              </div>
              <hr />
              <div class="eventSetting_info_cont ds_flex dir_column gap12">
                <label class="eventSetting_info_cont_label w100">상담회 안내 파일
                  <span class="tooltip_btn" @mouseover="tooltipEventFile = true"
                    @mouseleave="tooltipEventFile = false">?</span><span class="tooltip"
                    :class="{ 'active': tooltipEventFile }">통역사에게 상담회를 안내할 파일을 첨부하세요.</span>
                </label>
                <template v-if="!presignedUrl.presigned_url">
                  <label v-if="!uploadedFileName" for="file-upload"
                    class="block_cont_btn secondary_text size_14 w100">파일
                    업로드하기</label>
                  <input id="file-upload" type="file" style="display: none" @change="handleFileUpload" />
                  <!-- <p v-if="uploadedFileName" class="secondary_text size_14">{{ uploadedFileName }}<i
                    @click="handleFileDelete" class="icon-delete"></i></p> -->
                </template>
                <template v-else>
                  <a :href="presignedUrl.presigned_url" class="inline_cont_btn size_14 main_text">{{
                    presignedUrl.file_name }}</a>
                  <p @click="deleteEventFile" class="inline_cont_btn cancel_btn">파일 삭제</p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event_tables">
      <div class="eventSetting_wrap mx_1160 survey" v-if="isUpdateMode">
        <div class="eventSetting_info_title ds_flex">
          <img src="@/assets/img/event/event_setting_icon2.png" class="title_img" />
          <p class="size_22 b_700">질문과 설문 관리</p>
        </div>
        <div class="table_wrap">
          <p class="size_18 b_700">상담일지</p>
          <div class="time_table ds_flex">
            <div class="ds_flex gap18">
              <p class="survey_badge icon_box" :class="{ 'active': isSurvey }"><i class="icon-flow"></i></p>
              <div class="text_wrap">
                <template v-if="!isSurvey">
                  <p class="b_700">등록된 상담일지가 없습니다.</p>
                </template>
                <template v-else>
                  <p class="b_700">등록된
                    <template v-if="eventData.exist_mtc_type.length > 0">
                      <template v-for="type in eventData.exist_mtc_type"><span class="type_badge">{{
                        returnBadgeText(type) }}</span></template>
                    </template>
                    <template v-else>
                      <template v-for="type in eventData.exist_ptc_type"><span class="type_badge">{{
                        returnBadgeText(type) }}</span></template>
                    </template>
                    상담일지가 있어요
                  </p>
                </template>
                <template v-if="!isSurveyI">
                  <p class="size_14 mgt">통역사 상담일지 등록은 필수에요.</p>
                </template>
              </div>
            </div>
            <router-link custom v-slot="{ navigate, href }" :to="{ name: surveyRoute, params: { eventId: eventId } }"
              v-if="!isAdmin">
              <a :href="href" @click="navigate" class="block_cont_btn">설문구조 바로가기 ></a>
            </router-link>
          </div>
          <p class="size_18 b_700">사전 설문</p>
          <div class="time_table ds_flex">
            <div class="ds_flex gap18">
              <p class="survey_badge icon_box" :class="{ 'active': isSatisfy }"><i class="icon-like"></i></p>
              <div class="text_wrap">
                <template v-if="!isSatisfy">
                  <p class="b_700">등록된 사전 설문이 없습니다.</p>
                </template>
                <template v-else>
                  <p class="b_700">등록된
                    <template v-for="type in eventData.exist_pts_type"><span class="type_badge">{{ returnBadgeText(type)
                        }}</span></template>
                    사전 설문이 있어요
                  </p>
                </template>
              </div>
            </div>
            <router-link custom v-slot="{ navigate, href }"
              :to="{ name: 'satisfySetting', params: { eventId: eventId } }">
              <a :href="href" @click="navigate" class="block_cont_btn" v-if="!isAdmin">사전 설문 바로가기 ></a>
            </router-link>
          </div>
        </div>
      </div>
      <div class="eventSetting_wrap mx_1160 timetable">
        <div class="eventSetting_info_title ds_flex">
          <img src="@/assets/img/event/event_setting_icon3.png" class="title_img" />
          <p class="size_22 b_700">테이블 설정</p>
        </div>
        <div class="table_wrap">
          <p class="size_18 b_700">상담 테이블</p>
          <div class="time_table">
            <div class="time_table_head ds_flex">
              <p class="time_table_label">날짜</p>
              <p class="time_table_label">시간</p>
            </div>
            <div class="time_table_body_wrap">
              <div class="time_table_body" v-for="(item, index) in datetimeList" :key="index">
                <div class="time_table_date_wrap ds_flex">
                  <div class="time_table_date">
                    <VDatePicker v-model.string="item.date" mode="date" :masks="masks_date">
                      <template #default="{ togglePopover }">
                        <p class="" @click="togglePopover">{{ item.date ? item.date : "YYYY-MM-DD" }}</p>
                      </template>
                    </VDatePicker>
                  </div>
                  <div class="time_table_time_wrap">
                    <div class="time_table_time ds_flex">
                      <label>시작</label>
                      <VDatePicker v-model.string="item.start_time" mode="time" is24hr :rules="rules"
                        :masks="masks_time" hide-time-header>
                        <template #default="{ togglePopover }">
                          <p class="time" @click="togglePopover">{{ item.start_time ? item.start_time : "HH:MM" }}</p>
                        </template>
                      </VDatePicker>
                    </div>
                    <p>-</p>
                    <div class="time_table_time ds_flex">
                      <label>종료</label>
                      <VDatePicker v-model.string="item.end_time" mode="time" is24hr :rules="rules" :masks="masks_time"
                        hide-time-header>
                        <template #default="{ togglePopover }">
                          <p class="time" @click="togglePopover">{{ item.end_time ? item.end_time : "HH:MM" }}</p>
                        </template>
                      </VDatePicker>
                    </div>
                  </div>
                </div>
                <p @click="delete_time_table(index)" v-if="!isAdmin"><i class="icon-trash"></i></p>
              </div>
            </div>
            <div class="time_table_btn_wrap ds_flex" v-if="!isAdmin">
              <div class="add_time_table_body b_700" @click="add_time_table">+ 추가</div>
              <div class="add_time_table_group b_700" @click="add_time_table_group" v-if="datetimeList.length > 1">+
                다음
                날짜로
                추가</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="floating_btn" v-if="!isAdmin">
      <div class="mx_1160">
        <div class="ds_flex">
          <p class="block_main_btn delete" @click="deleteEvent">삭제</p>
          <p class="block_main_btn save" @click="processEditModeOrSaveMode" :disabled="isProcessing">저장</p>
        </div>
      </div>
    </div>
    <event-setting-modal :show-user-a-type-modal="showUserATypeModal" :show-user-b-type-modal="showUserBTypeModal"
      @close="closeAllModals" :a_account_list="eventData.a_account_list" :b_account_list="eventData.b_account_list"
      @updateSelectedPM="updateEventAccountList" @updateSelectedClient="updateEventClientList" />
  </div>
</template>
