<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import router from '@/router';

export default defineComponent({
  name: "checkAttend",
  setup() {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject("axios");
    const CryptoJS = inject('cryptojs');

    // ATTEND DATA
    const attendance = ref('')
    const etaTime = ref('00:00')
    const masks_time = ref({ modelValue: 'HH:mm' });
    const rules = ref({ minutes: { interval: 5 } });

    const token = ref('');

    const piece_event_id = ref('');
    const at_id = ref('');

    // attendance 값이 바뀔 때 etaTime 초기화
    watch(attendance, (newValue) => {
      if (newValue === 'N' || newValue === 'Y') {
        etaTime.value = '00:00'; // 새로운 값이 선택되면 etaTime 초기화
      }
    });


    const secretKey = `b'f}Z\xa6\x83\xbe\xe71E\xb8\xc1\xf8*k\xd8\xe0\x08\xa3\x0cJ\x08\xf2\xae\x12\xd2y\xa3i1\xc5\x8f\xb7'`;
    const ivGlobal = `b'Nh>\xe0\xc62\x1fx\x06\x142\xb9\xb3\xc6[O'`;

    const base64Encode = (data) => {
      let decodeText = data.replace("b'", '');
      decodeText = decodeText.replace("'", '');
      const ciphertext = Buffer.from(decodeText, 'binary'); // 또는 암호문 바이트 데이터로 변환

      // Base64로 인코딩
      const encodedCiphertext = ciphertext.toString('base64');
      return encodedCiphertext;
    }

    const decrypteData = () => {
      // Base64로 인코딩된 암호문
      const urlParams = new URL(location.href).searchParams;
      const base64Ciphertext = urlParams.get('aes').replaceAll(" ", '+');

      // AES 키와 IV (초기화 벡터) 설정
      const key = CryptoJS.enc.Base64.parse(base64Encode(secretKey));
      const iv = CryptoJS.enc.Base64.parse(base64Encode(ivGlobal));  // AES는 IV 필요

      // Base64로 인코딩된 암호문을 디코딩
      const ciphertext = CryptoJS.enc.Base64.parse(base64Ciphertext);

      // 암호문 복호화
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        key,
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );

      // 복호화된 데이터의 결과를 문자열로 변환
      const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

      const result = JSON.parse(plaintext);
      piece_event_id.value = result.piece_event_id
      at_id.value = result.at_id

      fetchEventData(plaintext)
      // 다음 스텝
    }

    const eventInfo = ref([])
    const fetchEventData = async () => {
      const urlParams = new URL(location.href).searchParams;
      const base64Ciphertext = urlParams.get('aes')
      if (!base64Ciphertext) {
        alert("데이터가 올바르지 않습니다.")
      } else {
        try {
          const queryParams = new URLSearchParams({
            check_attend_data: base64Ciphertext
          });

          const url = `/attendance/read_check_attend_data?${queryParams.toString()}`;

          const response = await axios.get(url);
          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            eventInfo.value = response.data.data; // 이벤트 리스트를 저장합니다.
          } else {
            alert('데이터를 불러오지 못했습니다.');
          }
        } catch (error) {
          console.error('데이터를 가져오는 중에 오류가 발생했습니다:', error);
        }
      }
    }

    const postOngoingData = async () => {
      const data = {
        piece_event_id: piece_event_id.value,
        at_id: at_id.value,
        status: attendance.value,
        eta: etaTime.value
      };
      const url = '/attendance/update_self_response';
      try {
        const response = await axios.put(url, data);
        if (response.data.result === true) {
          alert("제출이 완료되었습니다.")
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      decrypteData();
    });

    return {
      attendance,
      masks_time,
      rules,
      etaTime,
      eventInfo,
      piece_event_id,
      at_id,
      postOngoingData
    };
  },
});
</script>

<template>
  <div id="checkAttend" class="no_header">
    <div class="logo">
      <router-link to="/bis/dashboard">
        <img src="@/assets/img/common/bis_logo.svg" />
      </router-link>
    </div>
    <div class="content ds_flex dir_column al_start gap32">
      <p class="size_20 b_700">{{ eventInfo.full_name }} {{ eventInfo.account_type === "SELLER" ? "셀러" : "통역사"
        }}님<br />오늘 예정된 상담회가 있어요.</p>
      <div class="event_info ds_flex dir_column al_start gap12">
        <p class="b_700">{{ eventInfo.piece_evnet_title }}</p>
        <div class="ds_flex al_center gap6">
          <p><i class="icon-location size_12 sub_text b_700"></i></p>
          <p class="size_14 secondary_text b_500">{{ eventInfo.place }}</p>
        </div>
      </div>
      <div class="answer_wrap">
        <p class="b_700">참석 여부를 선택해주세요.</p>
        <div class="select_wrap">
          <div class="fake_radio">
            <label class="ds_flex gap12 al_center">
              <input type="radio" value="Y" v-model="attendance" />
              <p class="b_700">지금 가고 있어요!</p>
            </label>
          </div>
          <div class="fake_radio">
            <label class="ds_flex gap12 al_center">
              <input type="radio" value="N" v-model="attendance" />
              <p class="b_700">불참합니다</p>
            </label>
          </div>
          <div class="warning" v-if="attendance === 'N'">
            <i class="icon-information_reverse"></i>
            <p>통역사님, 당일 갑작스러운 불참은 행사에 큰 문제가 될 수 있습니다. 앞으로 에퀴와의 활동이 어려워질 수 있으니, 참석 여부를 다시 한 번 확인해 주시기 바랍니다.</p>
          </div>
        </div>
      </div>
      <div class="insert_time w100" v-if="attendance === 'Y'">
        <p class="b_700">예상 도착 시간을 알려주세요.</p>
        <div class="caution">
          <i class="icon-information_reverse"></i>
          <p class="">지각 절대 금지! 행사에 차질이 없도록 사전에 안내 받은 시간에 늦지 않도록 해주세요.</p>
        </div>
        <VDatePicker v-model.string="etaTime" mode="time" is24hr :rules="rules" :masks="masks_time" hide-time-header>
          <template #default="{ togglePopover }">
            <p class="fake_textbox w100 w_600 sub_text" @click="togglePopover">{{ etaTime ? etaTime : "HH:MM" }}</p>
          </template>
        </VDatePicker>
      </div>
      <p class="block_main_btn" @click="postOngoingData()"
        :class="{ 'active': attendance === 'N' || (attendance === 'Y' && etaTime !== '00:00') }">
        제출하기
      </p>
    </div>
  </div>
</template>
