<script>
import { ref, onMounted, watch, computed, inject } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import Pagination from "@/components/admin/pagination.vue";

export default {
  name: "satisfyRecord",
  props: {
    event: Object,
  },
  components: {
    subHeader,
    tabHeader,
    Pagination
  },
  setup(props) {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const eventId = computed(() => store.state.eventStore.eventId);
    const userName = ref("");
    const token = ref("");
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const activeSub = ref("survey"); // 여기에서 'survey'이라는 값을 정의합니다.
    const activeHeadTab = ref("satisfy"); // 여기에서 'satisfy'이라는 값을 정의합니다.

    const pathName = route.name;
    let BIS = "INTERPRETER";
    const checkBIS = () => {
      if (pathName == "satisfyRecordInterpreter") {
        BIS = "INTERPRETER";
      } else if (pathName == "satisfyRecordBuyer") {
        BIS = "BUYER";
      } else if (pathName == "satisfyRecordSeller") {
        BIS = "SELLER";
      }
    }

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수

    const totalPages = computed(() => Math.ceil(recordList.value.length / itemsPerPage.value));
    const paginatedList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return recordList.value.slice(start, end);
    });
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };

    // 리스트 정보 호출과 검색, 필터결과 가공
    const recordList = ref([]);
    const searchText = ref("");
    const listOnLoading = ref(false);
    const page = ref(1);
    const limit = 10;
    const readRecordList = async () => {
      listOnLoading.value = true;
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const queryParams = new URLSearchParams({
          event_id: eventId.value,
          sf_type: "PIECE",
          sr_type: BIS,
          page: page.value,
          limit: limit,
        });
        if (selectedPf.value != 0) {
          queryParams.append('sf_id', selectedPf.value);
        }
        const url = `/satisfaction/read_record_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        recordList.value = response.data.record_list;
        recordList.value.map(item => {
          item.newMemo = '';
        })
      } catch (error) {
        console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
      } finally {
        listOnLoading.value = false;
      }
    };

    // 엑셀 다운로드

    const recordDownload = async () => {
      try {
        const response = await axios.get(`/satisfaction/download_satisfaction_list_excel?piece_event_id=${eventId.value}&sr_type=${BIS}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);
        // 현재 날짜와 시간을 포맷
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더함
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `record_survey_form${formattedDateTime}.xlsx`); // 파일명에 날짜와 시간을 추가
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    };

    const returnDate = (date) => {
      const days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
      let value = date.split("T")[0];
      let day = new Date(value).getDay();
      return `${value} ${days[day]}`;
    };

    const returnTime = (date) => {
      return date.substring(11, 16);
    };

    // 설문구조 다중 찾기
    const activeSatisList = ref([]); // 설문구조
    const selectedPf = ref(0);
    const readPieceToFormList = async (event = eventId.value) => {
      // BIS 별 질문 받아오기
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/satisfaction/read_piece_to_form_list`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          params: {
            piece_event_id: event,
            pts_type: BIS,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          // 결과값 선가공 후 ref 데이터에 집어넣는다.)
          if (response.data.pform_list.length > 0) {
            activeSatisList.value = response.data.pform_list;
            readRecordList();
          }
        }
      } catch (error) {
        console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
      }
    };

    const updateBool = ref(true);
    const updateRecordMemo = async (index, sr_id) => {
      if (updateBool.value == true) {
        updateBool.value = false;
        const memo = recordList.value[index].newMemo;
        if (memo != '') {
          try {
            // Axios 요청을 보냅니다.
            const response = await axios.put(
              "/satisfaction/update_record_memo",
              {
                sr_id: sr_id,
                memo: memo,
              },
              {
                headers: {
                  Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                },
              }
            );
    
            // 요청이 성공했고 결과가 true인 경우
            if (response.data.result === true) {
              recordList.value[index].newMemo = '';
              readRecordList();
              return true;
            } else {
              alert("질문을 수정하지 못했습니다.");
            }
          } catch (error) {
            console.error(error);
          } finally {
            updateBool.value = true;
          }
        }
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
        checkBIS();
        readPieceToFormList();
      }
    });
    return {
      isAuthenticated,
      userName,
      activeSub,
      activeHeadTab,
      currentPage,
      totalPages,
      handleClickPage,
      paginatedList,
      BIS,
      activeSatisList,
      selectedPf,
      listOnLoading,
      selectedPf,
      searchText,
      recordList,
      readRecordList,
      recordDownload,
      returnDate,
      returnTime,
      updateRecordMemo,
    };
  },
};
</script>

<template>
  <div id="record" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <div class="date_picker_wrap">
              <div class="date_picker">
                <select id="selectedPf" name="selectedPf" v-model="selectedPf"
                  @change="readRecordList()">
                  <option value="0">모든 설문조사</option>
                  <option v-for="(item, index) in activeSatisList" :key="index" :value="item.pf_id">{{ item.pf_title }}</option>
                </select>
              </div>
            </div>
            <div class="user_table_top_cont_wrap">
              <div class="user_table_top_cont ds_flex al_center gap8">
                <!-- <p class="icon_box" @click="recordDownloadList"><i class="icon-download"></i></p> -->
                <div class="search">
                  <input type="text" v-model="searchText" placeholder="검색어" />
                  <i :class="searchText != '' ? 'icon-delete cs_pointer' : 'icon-search'" @click="searchText != '' ? searchClear() : readRecordList()"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <!-- 5개 -->
            <thead>
              <tr>
                <th>설문조사 제목</th>
                <th>작성자</th>
                <th>연락처</th>
                <th>제출 일시</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedList.length > 0">
                <tr v-for="(r,index) in paginatedList" :key="r.sr_id">
                  <td>{{ r.sf_title }}</td>
                  <td>{{ r.created_full_name }}</td>
                  <td>{{ r.created_mobile }}</td>
                  <td>
                    {{ returnDate(r.created_at) }}<br />
                    <span class="secondary_text">{{ returnTime(r.created_at) }}</span>
                  </td>
                  <td class="memo">
                    <input type="text" class="fake_textbox" placeholder="비고 입력" v-model="r.newMemo" @keyup.enter="updateRecordMemo(index, r.sr_id)">
                    <transition name="fade-alert">
                      <p v-if="r.newMemo != ''" class="float_alert">엔터를 눌러 저장
                      </p>
                    </transition>
                    <div class="memo_log_wrap">
                      <div v-for="(memo, idx) in r.memo" :key="idx">
                        <p class="memo_cont">
                          <i class="icon-talk"></i>
                          <span>{{ memo.memo }}</span>
                        </p>
                        <p class="created ds_flex gap8">{{ memo.created_by }} {{ memo.created_at }}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-if="listOnLoading">
                  <tr>
                    <td colspan="8" class="t_center">로딩 중...</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="8" class="t_center">제출된 상담일지가 없습니다.</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
  </div>
</template>
