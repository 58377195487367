// 상담회 정보에 대해 저장하는 store
import router from '@/router'

const eventStore = {
  namespaced: true,
  state: () => ({
    masterId: null,
    eventId: null,
    eventTitle: null,
    eventDate: null,
    isActivePreMatch: null,
    isActiveReception: null,
    isActiveLiveMatch: null,
    isActiveOnlineLink: null,
    surveyArray: [], // 설문구조 유무
    satisfyArray: [], // 사전 설문 유무
    sfId: null, // 사전 설문 id
    sfTitle: null, // 사전 설문 제목
    srId: null, // 사전 설문 제목
  }),
  mutations: {
    setMasterId(state, mID) {
      state.masterId = mID;
    },
    setEventId(state, ID) {
      state.eventId = ID;
    },
    setEventDate(state, date) {
      state.eventDate = date;
    },
    setEventTitle(state, title) {
      state.eventTitle = title;
    },
    setSatisfactionTitle(state, title) {
      state.sfTitle = title;
    },
    setSatisfactionId(state, ID) {
      state.sfId = ID;
    },
    setSatisfactionRecordId(state, ID) {
      state.srId = ID;
    },
    isActiveMenu(state, payload) {
      // console.log(payload);
      state.isActivePreMatch = payload.is_pre_arranged;
      state.isActiveReception = payload.is_reception;
      state.isActiveLiveMatch = payload.is_matched;
      state.isActiveOnlineLink = payload.is_video_link;
    },
    isActiveSurvey(state, payload) {
      if (payload.exist_mtc_type.length < 1) {
        state.surveyArray = payload.exist_ptc_type;
      } else {
        state.surveyArray = payload.exist_mtc_type;
      }
      state.satisfyArray = payload.exist_pts_type;
    },
    resetState(state) {
      state.masterId = null,
      state.eventId = null,
      state.eventTitle = null,
      state.eventDate = null,
      state.isActivePreMatch = null,
      state.isActiveReception = null,
      state.isActiveLiveMatch = null,
      state.isActiveOnlineLink = null,
      state.surveyArray = null, // 설문구조 유무
      state.satisfyArray = null // 사전 설문 유무
      state.sfId = null // 사전 설문 id
      state.sfTitle = null // 사전 설문 제목
    }
  }
}

export default eventStore
