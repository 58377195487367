import Highcharts from 'highcharts';
import HighchartsTreemap from 'highcharts/modules/treemap';

HighchartsTreemap(Highcharts); // Treemap 모듈 등록

export const surveyDashboardOptions = {
  surveyTypeOptions: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: false,
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        fontSize: '14px',
        fontWeight: 'bold'
      },
      itemDistance: 50,
      labelFormatter: function () {
        return this.name + " " + this.y;
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        // allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    colors: ['#FF4D4D', '#F90', '#C2C6CA'],
    series: [{
      colorByPoint: true,
      data: [{
        name: '현장주선',
        y: 0,
        sliced: true,
        selected: true
      }, {
        name: '사전주선',
        y: 0
      }, {
        name: '상담취소',
        y: 0
      }]
    }]
  },
  surveyStatusOptions: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: false,
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        fontSize: '14px',
        fontWeight: 'bold'
      },
      itemDistance: 50,
      labelFormatter: function () {
        return this.name + " " + this.y;
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        // allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    colors: ['#FFC700', '#00D354', '#57B4FF', '#FF4D4D', '#797EF5', '#C2C6CA'],
    series: [{
      colorByPoint: true,
      data: [{
        name: '우수상담',
        y: 0,
        sliced: true,
        selected: true
      }, {
        name: '승인됨',
        y: 0
      }, {
        name: '제출됨',
        y: 0
      },
      {
        name: '작성중',
        y: 0
      },
      {
        name: '작성예정',
        y: 0
      }]
    }]
  },
  resultForSurveyByCountry: {
    styledMode: true,
    chart: {
      type: 'treemap',
    },
    title: false,
    mapNavigation: {
      enabled: false,
      enableButtons: false,
    },
    colorAxis: {
      minColor: '#161B50', // 최소값 색상
      maxColor: '#9CBDFF', // 최대값 색상
    },
    dataLabels: {
      enabled: true,
      format: '{point.name}',
    },
    tooltip: {
      valueDecimals: 2,
    },
    legend: false,
    series: [{
      layoutAlgorithm: 'squarified',
      clip: false,
    }]
  },
  etcSurveyResult: {
    styledMode: true,
    chart: {
      type: 'column'
    },
    title: false,
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: false
    },
    legend: false,
    plotOptions: {
      column: {
        pointPadding: 0.3,
        borderWidth: 0
      }
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>'
    },
    series: [
      {
        data: [

        ]
      }
    ]
  }
}