<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import router from '@/router';

export default defineComponent({
  props: {
    showUserBISModal: Boolean,
    showUserAdminModal: Boolean,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const selectType = ref('BUYER');
    const excelUploadModal = ref(false);
    const addBISInfo = ref({});
    const addAdminInfo = ref({
      account_type: 'A1'
    });
    const eventId = computed(() => store.state.eventStore.eventId);
    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const changeType = (type) => {
      selectType.value = type
      addBISInfo.value = []
    }

    const saveBISUser = async () => {
      try {
        addBISInfo.value.account_type = selectType.value
        // Axios 요청을 보냅니다.
        const response = await axios.post('/user/create_account', {
          "email": addBISInfo.value.contact_email,
          "full_name": addBISInfo.value.full_name,
          "mobile": addBISInfo.value.mobile,
          "company": addBISInfo.value.company,
          "account_type": selectType.value
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          pushUserToEvent(response.data.at_id)
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }

    const saveAdminUser = async () => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post('/user/create_account', {
          "email": addAdminInfo.value.contact_email,
          "full_name": addAdminInfo.value.full_name,
          "mobile": addAdminInfo.value.mobile,
          "company": addAdminInfo.value.company,
          "account_type": addAdminInfo.value.account_type
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          alert('저장이 완료되었습니다.')
          router.go()
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }


    const pushUserToEvent = async (at_id) => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post('/user/create_piece_to_accounts', {
          "piece_event_id": eventId.value,
          "at_id_list": [at_id]
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          alert("저장이 완료되었습니다.")
          router.go()
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }

    const closeModals = () => {
      addBISInfo.value = []
      addAdminInfo.value = []
      emit('close');
    }

    const uploadedFileName = ref(''); // 업로드된 파일명을 저장할 ref

    const handleFileUpload = (event) => {
      const file = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (file) {
        uploadedFileName.value = file.name; // 업로드된 파일명 설정
        // 파일 업로드 후 추가적인 작업을 수행할 수 있습니다.
        // 예를 들어, 서버로 파일을 전송하여 저장하는 등의 작업을 수행할 수 있습니다.
        console.log('Uploaded file:', file);
        // 여기서 서버로 파일 전송 등의 작업을 수행할 수 있습니다.
      }
    };

    const uploadExcel = async () => {
      try {
        const file = document.getElementById('file-upload').files[0]; // 파일 가져오기

        if (!file) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('piece_event_id', eventId.value);

        const response = await axios.post('/user/upload_accounts_excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        console.log('Upload response:', response.data);
        if (response.data.result === true) {
          alert("저장이 완료되었습니다.")
          router.go()
        } else {
          alert('저장하지 못했습니다.');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };

    // BIS 사용자 업로드 샘플파일 다운로드 API
    const downloadSampleBIS = async () => {
      try {
        const response = await axios.get('/user/download_sample_accounts_excel', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob' // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'BIS_upload_sample.xlsx'); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    };

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }
      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });
    return {
      excelUploadModal,
      selectType,
      changeType,
      closeModals,
      addBISInfo,
      saveBISUser,
      addAdminInfo,
      saveAdminUser,
      uploadedFileName,
      handleFileUpload,
      uploadExcel,
      downloadSampleBIS
    }
  },
});
</script>

<template>
  <div id="addUserModal">
    <div class="overlay" v-if="excelUploadModal"></div>
    <div class="modal" v-if="excelUploadModal">
      <p class="icon_box close" @click="excelUploadModal = false">
        <i class="icon-delete sub_text"></i>
      </p>
      <div class="modal_top">
        <div class="text_wrap">
          <p class="modal_title size_28 b_700">사용자 업로드</p>
          <p class="secondary_text">추가할 사용자 리스트를 일괄 추가할 수 있어요.</p>
        </div>
        <a class="download" @click="downloadSampleBIS" download>샘플 파일 다운로드 <i class="icon-download"></i></a>
      </div>
      <div class="img_upload ds_flex">
        <i class="icon-file size_32"></i>
        <div class="text_wrap t_center">
          <p class="b_700 secondary_text">XLS 파일을 업로드하세요.</p>
          <p class="main_text size_14">*업로드 전 샘플 파일 양식을 꼭 확인하세요.</p>
        </div>
        <label for="file-upload" class="block_cont_btn secondary_text size_14">파일 업로드하기</label>
        <input id="file-upload" type="file" style="display: none" @change="handleFileUpload" />
        <p v-if="uploadedFileName" class="secondary_text size_14">업로드된 파일: {{ uploadedFileName }}</p>
      </div>
      <div class="btn_wrap">
        <p class="block_main_btn active_btn" @click="uploadExcel">저장</p>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="sidemodal" v-if="showUserBISModal || showUserAdminModal">
        <template v-if="showUserBISModal">
          <div class="top">
            <p class="inline_cont_btn upload" @click="excelUploadModal = true">사용자 업로드</p>
          </div>
          <p class="size_26 b_700">BIS 사용자 추가</p>
          <p class="description">추가할 유형을 선택 후 정보를 입력해주세요.</p>
          <div class="cont">
            <div class="input_cont">
              <label>유형</label>
              <div class="btn_wrap">
                <p class="block_cont_btn" :class="{ 'active': selectType == 'BUYER' }" @click="changeType('BUYER')">바이어
                </p>
                <p class="block_cont_btn" :class="{ 'active': selectType == 'INTERPRETER' }"
                  @click="changeType('INTERPRETER')">통역사
                </p>
                <p class="block_cont_btn" :class="{ 'active': selectType == 'SELLER' }" @click="changeType('SELLER')">
                  셀러
                </p>
              </div>
            </div>
            <div class="input_cont">
              <label>이름</label>
              <input type="text" placeholder="이름" class="fake_textbox" v-model="addBISInfo.full_name" />
            </div>
            <div class="input_cont">
              <label>이메일</label>
              <input type="text" placeholder="이메일" class="fake_textbox" v-model="addBISInfo.contact_email" />
            </div>
            <div class="input_cont">
              <label>연락처</label>
              <input type="text" placeholder="국가번호 입력 어쩌구" class="fake_textbox" v-model="addBISInfo.mobile" />
            </div>
            <div class="input_cont">
              <label>회사명</label>
              <input type="text" placeholder="회사명" class="fake_textbox" v-model="addBISInfo.company"
                :disabled="selectType == 'INTERPRETER'" />
            </div>
          </div>
          <div class="btn_wrap bottom">
            <p class="block_main_btn cancel_btn" @click="closeModals">취소</p>
            <p class="block_main_btn active_btn" @click="saveBISUser">추가하기</p>
          </div>
        </template>
        <template v-if="showUserAdminModal">
          <p class="size_26 b_700">관리자 추가</p>
          <p class="description">추가할 유형을 선택 후 정보를 입력해주세요.</p>
          <div class="cont">
            <div class="input_cont">
              <label>유형</label>
              <select id="accountType" v-model="addAdminInfo.account_type">
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
              </select>
            </div>
            <div class="input_cont">
              <label>이름</label>
              <input type="text" placeholder="이름" class="fake_textbox" v-model="addAdminInfo.full_name" />
            </div>
            <div class="input_cont">
              <label>이메일</label>
              <input type="text" placeholder="이메일" class="fake_textbox" v-model="addAdminInfo.contact_email" />
            </div>
            <div class="input_cont">
              <label>연락처</label>
              <input type="text" placeholder="국가번호 입력 어쩌구" class="fake_textbox" v-model="addAdminInfo.mobile" />
            </div>
            <div class="input_cont">
              <label>회사명</label>
              <input type="text" placeholder="회사명" class="fake_textbox" v-model="addAdminInfo.company" />
            </div>
          </div>
          <div class="btn_wrap bottom">
            <p class="block_main_btn cancel_btn" @click="closeModals">취소</p>
            <p class="block_main_btn active_btn" @click="saveAdminUser">추가하기</p>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>
