<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import { useRoute } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
    name:"surveyEmail",
    setup() {
        // Axios 인스턴스와 Vuex 스토어 사용
        const axios = inject("axios");
        const CryptoJS = inject('cryptojs');
        const piece_event_id = ref(0);
        const master_event_id = ref(0);
        const schedule_id = ref(0);
        const cr_type = ref('');
        const eventTitle = ref('');
        const token = ref('');

        const secretKey = `b'f}Z\xa6\x83\xbe\xe71E\xb8\xc1\xf8*k\xd8\xe0\x08\xa3\x0cJ\x08\xf2\xae\x12\xd2y\xa3i1\xc5\x8f\xb7'`;
        const ivGlobal = `b'Nh>\xe0\xc62\x1fx\x06\x142\xb9\xb3\xc6[O'`;

        const base64Encode = (data) => {
            let decodeText = data.replace("b'", '');
            decodeText = decodeText.replace("'", '');
            const ciphertext = Buffer.from(decodeText, 'binary'); // 또는 암호문 바이트 데이터로 변환

            // Base64로 인코딩
            const encodedCiphertext = ciphertext.toString('base64');
            return encodedCiphertext;
        }
        
        const decrypteData = () => {
            // Base64로 인코딩된 암호문
            const urlParams = new URL(location.href).searchParams;
            const base64Ciphertext = urlParams.get('aes').replaceAll(" ", '+');

            // AES 키와 IV (초기화 벡터) 설정
            const key = CryptoJS.enc.Base64.parse(base64Encode(secretKey));
            const iv = CryptoJS.enc.Base64.parse(base64Encode(ivGlobal));  // AES는 IV 필요

            // Base64로 인코딩된 암호문을 디코딩
            const ciphertext = CryptoJS.enc.Base64.parse(base64Ciphertext);

            // 암호문 복호화
            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: ciphertext },
                key,
                { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
            );

            // 복호화된 데이터의 결과를 문자열로 변환   
            const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

            const result = JSON.parse(plaintext);

            piece_event_id.value = result.piece_event_id;
            master_event_id.value = result.master_event_id;
            schedule_id.value = result.schedule_id;
            cr_type.value = result.cr_type;

            createUserTokenFromCrEmail();
        }

        const createUserTokenFromCrEmail = async () => {
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/user/create_user_token_from_cr_email`;
                // Axios 요청을 보냅니다.
                const response = await axios.post(url, {
                    "schedule_id": schedule_id.value,
                    "cr_type": cr_type.value,
                    }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    token.value = response.data.access_token;
                    callQuestionList();
                    readRecord();
                    readPiece();
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        }

        const readPiece = async () => {
            try {
                // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
                const response = await axios.get('/event/read_piece', {
                    params: {
                        piece_event_id: piece_event_id.value
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                if (response.data.result === true) {
                    eventTitle.value = response.data.piece_event.piece_event_title;
                }
            } catch (error) {
                console.error('이벤트 데이터를 가져오는 중에 오류가 발생했습니다:', error);
                alert('이벤트를 불러오지 못했습니다.');
            }
        }

        // 상담일지 상세
        const r = ref({});
        const answers = ref({});
        const readRecord = async () => {
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_record`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        schedule_id: schedule_id.value,
                        cr_type: cr_type.value,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    r.value = response.data.record;
                    answers.value = response.data.record.answers;
                    let start_time = r.value.start_time.split(":");
                    let end_time =  r.value.end_time.split(":");
                    r.value.booth_time = `${start_time[0]}:${start_time[1]} ~ ${end_time[0]}:${end_time[1]}`;
                    readAccountDetailInfo('BUYER');
                    readAccountDetailInfo('SELLER');
                    readAccountDetailInfo('INTERPRETER');
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        const returnComma = money => {
            return Number(money).toLocaleString("ko-KR")
        }

        // 설문구조
        const questions = ref([]);
        const callQuestionList = async () => {
            if (master_event_id.value != null) {
                await readMasterToQuestionList(cr_type.value);
            } else {
                await readPieceToQuestionList(cr_type.value);
            }
        }
        const readPieceToQuestionList = async () => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_piece_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: piece_event_id.value,
                        ptc_type: cr_type.value,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    questions.value = response.data.question_list;
                    questions.value.map((item,index) => {
                        if (item.cq_id == 1 || item.cq_id == 2 || item.cq_id == 6) {
                            questions.value.splice(index,1);
                        }
                    })
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };
        const readMasterToQuestionList = async () => {
            // BIS 별 질문 받아오기
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/consultation/read_master_to_question_list`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        master_event_id: master_event_id.value,
                        mtc_type: cr_type.value,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.)
                    questions.value = response.data.question_list;
                    questions.value.map((item,index) => {
                        if (item.cq_id == 1 || item.cq_id == 2 || item.cq_id == 6) {
                            questions.value.splice(index,1);
                        }
                    })
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        };

        // 유저정보
        const scheB = ref({});
        const scheI = ref({});
        const scheS = ref({});
        const readAccountDetailInfo = async _bis => {
            let id = "";
            if (_bis == "INTERPRETER") {
                id = r.value.interpreter_id;
            } else if (_bis == "BUYER") {
                id = r.value.buyer_id;
            } else if (_bis == "SELLER") {
                id = r.value.seller_id;
            }
            try {
                // 전체 URL에 쿼리 스트링을 추가합니다.
                const url = `/user/read_account_info_in_piece`;
                // Axios 요청을 보냅니다.
                const response = await axios.get(url, {
                    params: {
                        piece_event_id: piece_event_id.value,
                        account_id: id,
                        account_type: _bis,
                    },
                    headers: {
                        Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                    },
                });

                // 요청이 성공했고 결과가 true인 경우
                if (response.data.result === true) {
                    // 결과값 선가공 후 ref 데이터에 집어넣는다.
                    if (_bis == "INTERPRETER") {
                        scheB.value = response.data.account_info;
                    } else if (_bis == "BUYER") {
                        scheI.value = response.data.account_info;
                    } else if (_bis == "SELLER") {
                        scheS.value = response.data.account_info;
                    }
                } else {
                    alert("질문 리스트를 불러오지 못했습니다.");
                }
            } catch (error) {
                console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
            }
        }

        // 컴포넌트 마운트 시 로직
        onMounted(async () => {
            decrypteData();
            window.addEventListener("keyup", (event) => {
                if (event.code === "Escape") {
                    closeModals();
                }
            });
        });
        return {
            eventTitle,
            r,
            answers,
            returnComma,
            questions,
            scheB,
            scheI,
            scheS,
        };
    },
});
</script>

<template>
    <div id="recordDetailModal">
        <div class="overlay"></div>
        <div class="modal width_1122">
            <p class="modal_title size_26 b_700">{{ eventTitle }}</p>
            <div class="modal_overflow">
                <div class="record_flex_box">
                    <div>
                        <div class="secondary_text size_14">일지번호</div>
                        <div class="size_14">{{ r.cr_id }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">날짜</div>
                        <div class="size_14">{{ r.date }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">시간</div>
                        <div class="size_14">{{ r.booth_time }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">부스</div>
                        <div class="size_14">{{ r.booth_name }}</div>
                    </div>
                </div>
                <div class="record_title">참가자 정보</div>
                <div class="bis_box">
                    <div class="buyer" :class="r.created_by == r.interpreter_id">
                        <p class="bis_title">
                            바이어
                            <span v-if="r.created_by == r.interpreter_id">*작성자</span>
                        </p>
                        <div class="grid_inner">
                            <div class="fake_textbox">
                                <span>회사명</span>
                                <p>{{ scheB.company }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>이메일</span>
                                <p>{{ scheB.contact_email }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>연락처</span>
                                <p>{{ scheB.mobile }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>이름</span>
                                <p>{{ scheB.full_name }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>바이어 코드</span>
                                <p>{{ scheB.serial_number }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>국가</span>
                                <p>{{ scheB.country }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="seller" :class="r.created_by == r.interpreter_id">
                        <p class="bis_title">
                            셀러
                            <span v-if="r.created_by == r.interpreter_id">*작성자</span>
                        </p>
                        <div class="grid_inner">
                            <div class="fake_textbox">
                                <span>회사명</span>
                                <p>{{ scheS.company }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>이메일</span>
                                <p>{{ scheS.contact_email }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>연락처</span>
                                <p>{{ scheS.mobile }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>담당자</span>
                                <p>{{ scheS.full_name }}</p>
                            </div>
                            <div class="fake_textbox">
                                <span>셀러 코드</span>
                                <p>{{ scheS.serial_number }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="interpreter" :class="r.created_by == r.interpreter_id">
                        <p class="bis_title">
                            통역사
                            <span v-if="r.created_by == r.interpreter_id">*작성자</span>
                        </p>
                        <div class="grid_inner">
                            <div class="fake_textbox">
                                <span>이름</span>
                                <p>{{ scheI.full_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="record_title">상담 결과</div>
                <div class="survey_box">
                    <template v-for="item in questions">
                        <div class="survey_title" v-text="item.is_one_time ? item.ptc_title['ko'] : item.list_title"></div>
                        <div>
                            <template v-if="item.cq_type == 'SINGLE'">
                                <div class="fake_textbox">
                                    
                                </div>
                                <select :name="item.cq_id" :id="item.cq_id" disabled>
                                    <template v-for="(value, key) in item.ptc_answer_form">
                                        <option :value="key" :selected="key == answers[item.cq_id]">{{ value["ko"] }}</option>
                                    </template>
                                </select>
                            </template>
                            <template v-else-if="item.cq_type == 'MULTIPLE'">
                                <div class="fake_textbox">
                                    <template v-for="(a, index) in answers[item.cq_id]">
                                        <template v-if="index != 0">, </template>
                                        {{ item.ptc_answer_form[a]['ko'] }}
                                    </template>
                                </div>
                                <input type="text" :id="item.cq_id" :value="answers[item.cq_id]" v-model="answers[item.cq_id]" class="fake_textbox" disabled />
                            </template>
                            <template v-else>
                                <input type="text" :id="item.cq_id" :value="answers[item.cq_id]" v-model="answers[item.cq_id]" class="fake_textbox" disabled />
                            </template>
                        </div>
                    </template>
                </div>
                <div class="record_title">세부 상담내용</div>
                <div class="detail_cont_box">
                    <textarea name="question6" id="question6" disabled>{{ answers[6] }}</textarea>
                </div>
                <div class="record_flex_box">
                    <div>
                        <div class="secondary_text size_14">상담액</div>
                        <div>{{ returnComma(answers[1]) }}</div>
                    </div>
                    <div>
                        <div class="secondary_text size_14">계약추진액</div>
                        <div>{{ returnComma(answers[2]) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
