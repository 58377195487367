<script>
import { ref, onMounted, inject, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import surveyInform from "@/components/bis/surveyInform.vue";

export default {
  name: "BISSurvey",
  components: {
    surveyInform
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref("");
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userId, userType } = props.user;
    const { eventId, masterId } = props.event;

    const scheduleId = ref(router.currentRoute.value.params.schedule_id);

    // 상담일지
    const currentLang = "ko";
    const allPages = ref([
      {
        index: "start", 
        type: "-",
        answer: "-",
      },
    ]);
    const onPage = ref(0); // 현재 위치한 상담일지
    const surveyBISList = ref([]);
    const readPieceToQuestionList = async (BIS, event = eventId.value) => {
      // BIS 별 질문 받아오기
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/consultation/read_piece_to_question_list`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          params: {
            piece_event_id: event,
            ptc_type: BIS,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          // 결과값 선가공 후 ref 데이터에 집어넣는다.)
          surveyBISList.value = response.data.question_list;
          if (readBool.value) {
            surveyBISList.value.forEach((item, index) => {
              if (item.cq_type == 'MULTIPLE') {
                let a = [];
                if (r.value[item.cq_id] != undefined) {
                  a = r.value[item.cq_id].split(',').filter(itm => itm != '');
                }
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: a,
                  isMust: item.is_must,
                });
              } else if (item.cq_type == 'FILE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  fileObject: r.value[item.cq_id],
                  answer: null,
                  path: null,
                  isMust: item.is_must,
                });
                if (r.value[item.cq_id] != undefined){
                  createAbsoluteUrl(index+1, 'read')
                }
              } else {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: r.value[item.cq_id],
                  isMust: item.is_must,
                });
              }
            });
          } else {
            surveyBISList.value.forEach((item, index) => {
              if (item.cq_type == 'MULTIPLE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: [],
                  isMust: item.is_must,
                });
              } else if (item.cq_type == 'SINGLE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: "",
                  isMust: item.is_must,
                });
              } else if (item.cq_type == 'FILE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  fileObject: null,
                  answer: null,
                  path: null,
                  isMust: item.is_must,
                });
              } else {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: "",
                  isMust: item.is_must,
                });
              }
            });
          }
          allPages.value.push({
            index: 'finish',
            type: "-",
            answer: "-",
          });
        } else {
          alert("상담일지 질문을 불러오지 못했습니다.");
        }
      } catch (error) {
        console.error("상담일지 질문을 가져오는 중에 오류가 발생했습니다:", error);
      }
    };
    const readMasterToQuestionList = async (BIS, event = masterId.value) => {
      // BIS 별 질문 받아오기
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/consultation/read_master_to_question_list`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          params: {
            master_event_id: Number(event),
            mtc_type: BIS,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          // 결과값 선가공 후 ref 데이터에 집어넣는다.)
          surveyBISList.value = response.data.question_list;
          if (readBool.value) {
            surveyBISList.value.forEach((item, index) => {
              if (item.cq_type == 'MULTIPLE') {
                let a = [];
                if (r.value[item.cq_id] != undefined) {
                  a = r.value[item.cq_id].split(',').filter(itm => itm != '');
                }
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: a,
                  isMust: item.is_must,
                });
              } else if (item.cq_type == 'FILE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  fileObject: r.value[item.cq_id],
                  answer: null,
                  path: null,
                  isMust: item.is_must,
                });
                if (r.value[item.cq_id] != undefined){
                  createAbsoluteUrl(index+1, 'read')
                }
              } else {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: r.value[item.cq_id],
                  isMust: item.is_must,
                });
              }
            });
          } else {
            surveyBISList.value.forEach((item, index) => {
              if (item.cq_type == 'MULTIPLE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: [],
                  isMust: item.is_must,
                });
              } else if (item.cq_type == 'SINGLE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: "",
                  isMust: item.is_must,
                });
              } else if (item.cq_type == 'FILE') {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  fileObject: null,
                  answer: null,
                  path: null,
                  isMust: item.is_must,
                });
              } else {
                allPages.value.push({
                  index: item.cq_id,
                  type: item.cq_type,
                  answer: "",
                  isMust: item.is_must,
                });
              }
            });
          }
          allPages.value.push({
            index: 'finish',
            type: "-",
            answer: "-",
          });
        } else {
          alert("상담일지 질문을 불러오지 못했습니다.");
        }
      } catch (error) {
        console.error("상담일지 질문을 가져오는 중에 오류가 발생했습니다:", error);
      }
    };
    const surveyOnPage = computed(() => {
      // 출력되고 있는 질문 object
      if (surveyBISList.value.length > 0) {
        return surveyBISList.value[onPage.value - 1];
      } else {
        return "-";
      }
    });
    const surveyOnPageTitle = (index = '-') => {
      // 질문 타이틀 반환 (마스터, 피스 구분)
      if (surveyBISList.value.length > 0) {
        if (index == '-') {
          if (masterId.value != null) {
            return surveyBISList.value[onPage.value - 1].mtc_title[currentLang];
          } else {
            return surveyBISList.value[onPage.value - 1].ptc_title[currentLang];
          }
        } else {
          if (masterId.value != null) {
            return surveyBISList.value[index].mtc_title[currentLang];
          } else {
            return surveyBISList.value[index].ptc_title[currentLang];
          }
        }
      } else {
        return "-";
      }
    };
    const surveyOnPageForm = computed(() => {
      // 단일, 다중 질문 옵션 object array 반환 (마스터, 피스 구분)
      if (surveyBISList.value.length > 0) {
        if (masterId.value != null) {
          return surveyBISList.value[onPage.value - 1].mtc_answer_form;
        } else {
          return surveyBISList.value[onPage.value - 1].ptc_answer_form;
        }
      } else {
        return "-";
      }
    });
    const returnOption = (index,selected) => {
      // 제출 전 확인 단일, 다중 체크한 답변 반환
      if (surveyBISList.value.length > 0) {
        if (masterId.value != null) {
          return surveyBISList.value[index].mtc_answer_form[selected][currentLang];
        } else {
          return surveyBISList.value[index].ptc_answer_form[selected][currentLang];
        }
      }
    }
    const nextActionForStart = async () => {
      // 상담일지 작성하기 버튼 함수
      const created = await createRecord();
      if (created) {
        // createRecord에서 record 생성 되어있는지 readBool로 확인. 생성 안되어있으면 생성해서 반환
        onPage.value = 1;
        updateRecordStatus(1);
        actionedIndex.push(allPages.value[onPage.value].index);
      } else {
        alert("상담일지를 시작하는데 오류가 발생했습니다.");
      }
    }
    const nextAction = async () => {
      if (validateMust) {
        // 입력값 있을 때 통과
        // 다중선택이면 array.length가 0 이상일 때, 이외 빈 스트링 값이 아닐 때
        const updated = await updateRecord();
        if (updated) {
          if (surveyOnPage.value.cq_type == "SINGLE") {
              if (surveyOnPage.value.next_action[allPages.value[onPage.value].answer] == "next") {
                onPage.value++;
                actionedIndex.push(allPages.value[onPage.value].index);
              } else if (surveyOnPage.value.next_action[allPages.value[onPage.value].answer] == "finish") {
                onPage.value = allPages.value.length - 1;
              } else {
                onPage.value = surveyOnPage.value.next_action[allPages.value[onPage.value].answer];
                actionedIndex.push(allPages.value[onPage.value].index);
              }
          } else {
            if (surveyOnPage.value.next_action[0] == "next") {
              onPage.value++;
              actionedIndex.push(allPages.value[onPage.value].index);
            } else if (surveyOnPage.value.next_action[0] == "finish") {
              onPage.value = allPages.value.length - 1;
            } else {
              onPage.value = surveyOnPage.value.next_action[0];
              actionedIndex.push(allPages.value[onPage.value].index);
            }
          }
        }
      } else {
        alert('현재 질문의 답변을 입력해 주세요.');
      }
    };
    const validateMust = () => {
      // 다음장으로 넘어가도 되는지 확인
      if (allPages.value[onPage.value].isMust == 1) {
        if (allPages.value[onPage.value].answer != '' || allPages.value[onPage.value].answer.length > 0) { 
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    const prevAction = () => {
      if (onPage.value == 1) {
        onPage.value--;
      } else {
        const isIncludeSq = idx => idx.index == actionedIndex[actionedIndex.length - 1];
        onPage.value = allPages.value.findIndex(isIncludeSq);
        actionedIndex = actionedIndex.filter(idx => idx != allPages.value[onPage.value].index);
      }
    };
    let actionedIndex = [];
    const actionedPage = computed(() => {
      return surveyBISList.value.filter(item => actionedIndex.includes(item.cq_id))
    });
    const submitConfirm = () => {
      // 정말로 제출하시겠습니까? 띄우기
      showSurveyInform.value = true;
    }
    const submitAction = async () => {
      // 제출하기
      updateRecordStatus(2);
      await updateRecord();
      alert("상담일지가 제출되었습니다.");
      router.push({ name: 'BISSchedule' });
    }

    // 스케줄
    const schedule = ref({});
    const readEachScheduleForBis = async () => {
      try {
        const url = `/schedule/read_each_schedule_for_bis`;
        const response = await axios.get(url, {
          params: {
            schedule_id: scheduleId.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data != null) {
          schedule.value = response.data;
        }
      } catch (error) {
        console.error("스케줄 정보를 불러오는 중에 오류가 발생했습니다:", error);
      }
    };
    const sellerInSche = computed(() => {
      if (Object.keys(schedule.value).length > 0) {
        const seller = schedule.value.seller;
        return seller;
      } else {
        return "-";
      }
    });
    const buyerInSche = computed(() => {
      if (Object.keys(schedule.value).length > 0) {
        const buyer = schedule.value.buyer;
        return buyer;
      } else {
        return "-";
      }
    });


    // 상담일지 조회
    const r = ref({});
    const crId = ref(0); // consultation record id
    const isEmail = ref(0); // 상담일지 이메일 전송 여부 체크박스
    const rejectReason = ref(''); // 상담일지 반려사유
    const readBool = ref(false); // 상담일지 생성 여부 체크
    const readRecord = async () => {
        try {
            // 전체 URL에 쿼리 스트링을 추가합니다.
            const url = `/consultation/read_record_for_bis`;
            // Axios 요청을 보냅니다.
            const response = await axios.get(url, {
                params: {
                    schedule_id: scheduleId.value,
                    cr_type: userType.value,
                },
                headers: {
                    Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
                },
            });

            // 요청이 성공했고 결과가 true인 경우
            if (response.data.result === true) {
                // 결과값 선가공 후 ref 데이터에 집어넣는다.
                if(response.data.record != null) {
                  readBool.value = true;
                  crId.value = response.data.record.cr_id;
                  r.value = response.data.record.answers;
                  rejectReason.value = response.data.record.reject_reason;
                  isEmail.value = response.data.record.is_email;
                }
            } else {
                alert("오류가 발생했습니다.");
            }
        } catch (error) {
            console.error("오류가 발생했습니다.:", error);
        } finally {
          if (rejectReason.value != '' && rejectReason.value != null) {
            showSurveyReject.value = true;
          }
        }
    };

    // 상담일지 생성, 수정
    const createRecord = async () => {
      if (!readBool.value) {
        try {
          // Axios 요청을 보냅니다.
          const response = await axios.post(
            "/consultation/create_record_for_bis",
            {
              schedule_id: scheduleId.value,
              answers: {},
              cr_type: userType.value,
              is_email: isEmail.value
            },
            {
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            }
          );
  
          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            readRecord();
            return true;
          } else {
            alert("상담일지를 시작하는데 오류가 발생했습니다.");
          }
        } catch (error) {
          console.error("상담일지를 시작하는데 오류가 발생했습니다.:", error);
        }
      } else {
        return true;
      }
    };

    // 상담일지 수정
    const updateRecord = async () => {
      if (validateOnPage()) { // 답변이 이전과 똑같니?
        const answers = {};
        allPages.value.forEach((t,index) => {
          if (t.index == 'start' || t.index == 'finish') {
            return false;
          }
          if (t.type == 'MULTIPLE') {
            answers[t.index] = t.answer.toString();
          } else if (t.type == 'FILE') {
            answers[t.index] = t.fileObject;
          } else {
            answers[t.index] = t.answer;
          }
        })
        try {
          // Axios 요청을 보냅니다.
          const response = await axios.put(
            "/consultation/update_record_for_bis",
            {
              schedule_id: scheduleId.value,
              answers: answers,
              cr_type: userType.value,
              is_email: isEmail.value
            },
            {
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            }
          );
  
          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            return true;
          } else {
            alert("상담일지를 시작하는데 오류가 발생했습니다.");
          }
        } catch (error) {
          console.error("상담일지를 시작하는데 오류가 발생했습니다.:", error);
        }
      } else {
        return true;
      }
    };

    // 각 질문 validate
    const validateOnPage = () => {
      const cq_id = allPages.value[onPage.value].index;
      const answer = allPages.value[onPage.value].answer;
      if (r.value[cq_id] == answer) {
        return false;
      } else {
        return true;
      }
    }

    // 파일 업로드
		const uploadedFileName = computed(() => {
      // 업로드된 파일명을 저장할 ref
      const file = allPages.value[onPage.value].fileObject;
      if (allPages.value[onPage.value].answer != null) {
        return allPages.value[onPage.value].answer
      } else if (file != undefined) {
        return file.name;
      } else {
        return null;
      }
    })
		const uploadedFile = ref(null); // input ref

		const handleFileUpload = event => {
			const file = event.target.files[0]; // 업로드된 파일 객체 가져오기
			if (file) {
        allPages.value[onPage.value].fileObject = file;
        handleFileUploadS3(onPage.value);
			}
		};

    const handleFileUploadS3 = async indexPage => {
      try {
        const file = allPages.value[indexPage].fileObject; // 파일 가져오기

        if (!file) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        let url = '';
        if (masterId.value != null) {
          url = `/file/consultation_record?account_id=${userId.value}&cr_id=${crId.value}&master_event_id=${masterId.value}`;
        } else {
          url = `/file/consultation_record?account_id=${userId.value}&cr_id=${crId.value}&piece_event_id=${eventId.value}`;
        }

        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        if (response.data.result === true) {
          allPages.value[indexPage].fileObject = response.data.file_path;
          createAbsoluteUrl(indexPage,'read')
        } else {
          alert('저장하지 못했습니다.');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    const createAbsoluteUrl = async (index, mode) => {
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/file/presigned_url?file_path=${allPages.value[index].fileObject}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          if (mode == 'read') {
            allPages.value[index].answer = response.data.file_info.file_name
            allPages.value[index].path = response.data.file_info.presigned_url
          }
        } else {
          alert("오류가 발생했습니다.");
        }
      } catch (error) {
        console.error("오류가 발생했습니다.:", error);
      }
    }

		const deleteFile = async () => {
      try {
        // Axios 요청을 보냅니다.
        let url = `/file/consultation_record?file_path=${allPages.value[onPage.value].fileObject}`;
        // Axios 요청을 보냅니다.
        const response = await axios.delete(
          url,
          {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          }
        );

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          allPages.value[onPage.value].fileObject = null;
          allPages.value[onPage.value].answer = null;
          updateRecord();
          return true;
        } else {
          alert("질문을 삭제하지 못했습니다.");
        }
      } catch (error) {
        console.error("질문 삭제하는 중에 오류가 발생했습니다:", error);
      }
		};

    const updateRecordStatus = async status => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.put(
          "/consultation/update_record_status",
          {
            schedule_id: Number(scheduleId.value),
            cr_type: userType.value,
            cr_status: Number(status),
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          }
        );

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          return true;
        } else {
          alert("질문을 수정하지 못했습니다.");
        }
      } catch (error) {
        console.error(error);
      }
    }

    // 모달 
    const showSurveyInform = ref(false);
    const showSurveyReject = ref(false);
    const closeAllModals = () => {
      showSurveyInform.value = false;
      showSurveyReject.value = false;
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        readRecord();
        readEachScheduleForBis();
        if (masterId.value != null) {
          await readMasterToQuestionList(userType.value, masterId.value);
        } else {
          await readPieceToQuestionList(userType.value, eventId.value);
        }
      }
    });

    return {
      userId,
      router,
      userType,
      currentLang, // 언어 설정값 추가시 여기도 수정
      scheduleId,
      surveyBISList,
      allPages,
      onPage,
      surveyOnPage,
      surveyOnPageTitle,
      surveyOnPageForm,
      returnOption,
      nextActionForStart,
      nextAction,
      prevAction,
      actionedPage,
      submitConfirm,
      submitAction,
      schedule,
      sellerInSche,
      buyerInSche,
      r,
      isEmail,
      rejectReason,
			handleFileUpload,
			uploadedFile,
			uploadedFileName,
			deleteFile,
      showSurveyInform,
      showSurveyReject,
      closeAllModals,
    };
  },
};
</script>

<template>
  <div class="no_header">
    <div class="bis_survey">
      <i class="icon-delete primary_text b_700 cs_pointer" @click="router.push({ name: 'BISSchedule' })"></i>
      <template v-if="onPage == 0">
        <!-- 첫 장 -->
        <div class="button_placer">
          <div>
            <div class="survey_title">상담일지 작성 전<br />확인해주세요.</div>
            <div v-if="userType != 'SELLER'" class="survey_cont ds_flex gap12 al_start">
              <p class="size_14 secondary_text b_600 w100">상담한 셀러</p>
              <div class="user_type_bdg size_12 SELLER">셀러</div>
              <div>
                <div class="title">{{ sellerInSche.company }}</div>
                <div class="sub_title">{{ sellerInSche.full_name }}</div>
              </div>
            </div>
            <div v-else class="survey_cont ds_flex gap12 al_start">
              <p class="size_14 secondary_text b_600 w100">상담한 바이어</p>
              <div class="user_type_bdg size_12 BUYER">바이어</div>
              <div>
                <div class="title">{{ buyerInSche.company }}</div>
                <div class="sub_title">{{ buyerInSche.full_name }}</div>
              </div>
            </div>
            <div class="ds_flex dir_column gap12">
              <p class="sub_text size_14">작성중인 상담일지는 자동으로 저장되기 때문에 페이지를 나가도 이어 쓸 수 있어요.</p>
              <p class="sub_text size_14">제출한 상담일지는 다시 수정할 수 없으니 꼼꼼히 확인 후 제출해주세요.</p>
              <p v-if="userType == 'INTERPRETER'" class="sub_text size_14">단, 상담일지가 반려된 경우에는 추가 수정후 다시 제출해야해요.</p>
            </div>
          </div>
          <button class="block_main_btn save" @click="nextActionForStart()">상담일지 작성하기</button>
          <!-- <button class="block_main_btn save" @click="showSurveyInform = true">상담일지 작성하기</button> -->
        </div>
      </template>
      <template v-else-if="Number(onPage) == Number(allPages.length - 1)">
        <!-- 마지막 장 -->
        <div class="button_placer">
          <div>
            <div class="progress_wrap">
              <template v-if="allPages.length != 1 && onPage != 0">
                <div class="progress_bar" style="width: 100%"></div>
              </template>
            </div>
            <div class="survey_title">제출 전 검토해주세요.</div>
            <div class="survey_cont">
              <div v-for="(item,index) in actionedPage" :key="index">
                <hr v-if="index != 0">
                <div class="sub_title">{{ surveyOnPageTitle(index) }}</div>
                <div v-if="item.cq_type == 'MULTIPLE'" class="title">
                  <span v-for="(a,idx) in allPages[index+1].answer" :key="idx"><template v-if="idx != 0">, </template>{{ returnOption(index, idx) }}</span>
                </div>
                <div v-else-if="item.cq_type == 'SINGLE'" class="title">{{ returnOption(index, allPages[index+1].answer) }}</div>
                <div v-else-if="item.cq_type == 'FILE'" class="title"><a :href="allPages[index+1].path" class="main_text">{{ allPages[index+1].answer }}</a></div>
                <div v-else class="title">{{ allPages[index+1].answer }}</div>
              </div>
            </div>
            <div v-if="userType == 'INTERPRETER'" class="ds_flex dir_column gap12">
              <p class="sub_text size_14">작성한 상담일지를 셀러의 메일로 전송할까요?</p>
              <p class="sub_text size_14">* 상담 내용은 관리자 검토 후에 보내져요</p>
              <div>
                <input type="checkbox" name="is_email" id="is_email" class="check_wrap_boxed" :true-value="1" :false-value="0" v-model="isEmail"/>
                <label for="is_email" class="check_wrap_boxed b_700">
                  <em><i class="icon-checked"></i></em>
                  <div>샐러에게 상담일지 전송하기</div>
                </label>
              </div>
            </div>
          </div>
          <div class="btn_wrap">
            <div class="inline_main_btn cancel_btn" @click="prevAction()">이전</div>
            <div class="inline_main_btn active_btn" @click="submitConfirm()">제출하기</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="button_placer">
          <div>
            <div class="progress_wrap">
              <template v-if="allPages.length != 1 && onPage != 0">
                <div class="progress_bar" :style="`width: ${(100 / allPages.length) * (onPage + 1)}%`"></div>
              </template>
            </div>
            <div class="survey_title">{{ surveyOnPageTitle() }} <span v-if="allPages[onPage].isMust" class="eqqui_text">*</span></div>
            <template v-if="surveyOnPage.cq_type == 'SINGLE'">
              <div class="ds_flex dir_column gap12">
                <div v-for="(form, index) in surveyOnPageForm" :key="index">
                  <input type="radio" name="answer_form" :id="`answer_${index}`" class="radio_wrap_boxed" :value="index" v-model="allPages[onPage].answer" />
                  <label :for="`answer_${index}`" class="radio_wrap_boxed">
                    <em></em>
                    <div>{{ form[currentLang] }}</div>
                  </label>
                </div>
              </div>
            </template>
            <template v-else-if="surveyOnPage.cq_type == 'MULTIPLE'">
              <div class="ds_flex dir_column gap12">
                <div v-for="(form, index) in surveyOnPageForm" :key="index">
                  <input type="checkbox" name="answer_form" :id="`answer_${index}`" class="check_wrap_boxed" :value="index" v-model="allPages[onPage].answer"/>
                  <label :for="`answer_${index}`" class="check_wrap_boxed b_700">
                    <em><i class="icon-checked"></i></em>
                    <div>{{ form[currentLang] }}</div>
                  </label>
                </div>
              </div>
            </template>
            <template v-else-if="surveyOnPage.cq_type == 'SHORT'">
              <input type="text" class="fake_textbox" placeholder="답변을 입력해주세요." v-model="allPages[onPage].answer"/>
            </template>
            <template v-else-if="surveyOnPage.cq_type == 'LONG'">
              <textarea cols="30" rows="10" placeholder="답변을 입력해주세요." v-model="allPages[onPage].answer"></textarea>
            </template>
            <template v-else-if="surveyOnPage.cq_type == 'NUMBER'">
              <input type="number" class="fake_textbox" placeholder="숫자만 입력해주세요." v-model="allPages[onPage].answer"/>
            </template>
            <template v-else-if="surveyOnPage.cq_type == 'FILE'">
              <div class="file_upload ds_flex" :class="{'active' : (uploadedFileName != null)}">
                <template v-if="(uploadedFileName == null)">
                  <i class="icon-upload size_32 not_entered_text"></i>
                  <div class="text_wrap t_center">
                    <p class="b_600 secondary_text">파일을 업로드하세요.</p>
                    <p class="sub_text size_14">첨부 가능한 파일: </p>
                  </div>
                  <label for="fileUpload" class="block_cont_btn secondary_text size_14">파일 업로드하기</label>
                  <input id="fileUpload" type="file" style="display: none" @change="handleFileUpload" />
                </template>
                <template v-else>
                  <div class="ds_flex gap8 al_center">
                    <p class="secondary_text size_14 main_text b_700"><a :href="allPages[onPage].path" class="main_text">{{ uploadedFileName }}</a></p>
                    <i class="icon-delete cs_pointer" @click="deleteFile"></i>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div class="btn_wrap">
            <div class="inline_main_btn cancel_btn" @click="prevAction()">이전</div>
            <div class="inline_main_btn active_btn" @click="nextAction()">다음</div>
          </div>
        </div>
      </template>
    </div>
    <survey-inform :show-survey-inform="showSurveyInform" :show-survey-reject="showSurveyReject" :reject-reason="rejectReason" @close="closeAllModals" @submit="submitAction"></survey-inform>
  </div>
</template>
