<script>
import { ref, onMounted, computed, inject, reactive } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatLang from '@/assets/js/bisatLang.json';
import attendTableModal from '@/components/admin/operateAttendTableModal.vue';
import attendMessageModal from '@/components/admin/operateAttendMessageModal.vue';

export default {
  name: 'operateAttend',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    attendTableModal,
    attendMessageModal
  },
  props: {
    event: Object,
  },
  setup(props) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('operate');
    const activeHeadTab = ref('attend');

    //이벤트 일시 관련 변수
    const { selectDate, handleSelectDate } = props.event;
    const eventDateList = ref([]);

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(attendListTotal.value / itemsPerPage.value));
    // const paginatedAttendList = computed(() => {
    //   const start = (currentPage.value - 1) * itemsPerPage.value;
    //   const end = start + itemsPerPage.value;
    //   return attendList.value.slice(start, end);
    // });

    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
        fetchAttendList(); // 페이지 변경 시 API 호출
      }
    };

    // 모달 관련 변수
    const showStatusModal = ref(false);
    const showMessageModal = ref(false);
    const selectedUserData = ref({});
    const closeAllModals = (type) => {
      showStatusModal.value = false;
      showMessageModal.value = false;
    };
    const openAttendModal = (userData) => {
      selectedUserData.value = { userData, type: 'SELLER' };
      showStatusModal.value = true;
    };

    const openMessageModal = () => {
      selectedUserData.value = { type: 'SELLER' };
      showMessageModal.value = true;
    };

    // 출석 데이터 관련 변수
    const attendList = ref([])
    const attendListTotal = ref(0)

    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0];
          }
          createAttendList()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // STEP2. 출석 리스트 생성
    async function createAttendList() {
      store.commit('eventStore/setEventDate', selectDate.value)
      try {
        const response = await axios.post('/attendance/create_list_by_date', {
          date: selectDate.value,
          piece_event_id: eventId.value,
          account_type: 'SELLER',
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          fetchAttendList()
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }

    const searchBy = ref('full_name')
    const searchText = ref('')
    // STEP3. 출석 리스트 불러오기
    async function fetchAttendList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
          account_type: 'SELLER',
          page: currentPage.value, // 현재 페이지 번호 추가
          limit: itemsPerPage.value // 페이지당 아이템 수 추가
        });
        if (searchBy.value) {
          queryParams.append('search_by', searchBy.value);
        }
        if (searchText.value) {
          queryParams.append('search_text', searchText.value);
        }

        const url = `/attendance/read_list_by_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          fetchStatusCount()
          attendListTotal.value = response.data.all_total
          attendList.value = response.data.ar_list.map(item => {

            let etaDate, etaTime;
            if (item.eta) {
              const eta = item.eta;
              [etaDate, etaTime] = eta.split(' ');
            } else {
              etaDate = '';
              etaTime = '';
            }

            let attendDate, attendTime;
            if (item.attend_at) {
              const attendAt = item.attend_at;
              [attendDate, attendTime] = attendAt.split(' ');
            } else {
              attendDate = '';
              attendTime = '';
            }

            let offDate, offTime;
            if (item.offduty_at) {
              const offAt = item.offduty_at;
              [offDate, offTime] = offAt.split(' ');
            } else {
              offDate = '';
              offTime = '';
            }

            // text_send_list 내의 각 항목에 대해 messageDate, messageTime 처리
            const updatedTextSendList = item.text_send_list.map(textItem => {
              let messageDate = '', messageTime = '';
              if (textItem.scheduled_datetime) {
                const scheduled_message = textItem.scheduled_datetime;
                [messageDate, messageTime] = scheduled_message.split(' ');
              }
              return {
                ...textItem,
                messageDate: messageDate, // 메시지 전송 날짜 부분
                messageTime: messageTime // 메시지 전송 시간 부분
              };
            });


            return {
              ...item,
              etaDate: etaDate, // 날짜 부분
              etaTime: etaTime, // 시간 부분
              attendDate: attendDate, // 날짜 부분
              attendTime: attendTime, // 시간 부분
              offDate: offDate, // 날짜 부분
              offTime: offTime, // 시간 부분
              text_send_list: updatedTextSendList

            };
          });
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type][category];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };

    const getLanguage = (code) => {
      const languageLabel = bisatLang.find(item => item.code === code);
      return languageLabel ? languageLabel.label : '';
    };

    const toggleShouldSend = (attend) => {
      // attend.should_send 값을 토글합니다.
      attend.should_send = attend.should_send === 'Y' ? 'N' : 'Y';

      // 이후 API 호출 등의 저장 로직을 실행합니다.
      saveShouldSend(attend.at_id, attend.should_send);
    };

    const saveShouldSend = (at_id, should_send) => {
      updateOption(at_id, null, should_send, null, null)
    };

    const showAlertListForMemo = reactive({});
    const attendInputMemo = ref([]);
    const handleInputForMemo = (ar_id) => {
      showAlertListForMemo[ar_id] = true;
    };

    // 옵션 업데이트
    const updateOption = async (at_id, employ_type, should_send, evaluation, ar_id) => {
      const memo = attendInputMemo.value[ar_id]
      const data = {
        date: selectDate.value,
        piece_event_id: eventId.value,
        at_id: at_id,
        employ_type: employ_type,
        should_send: should_send,
        evaluation: evaluation,
        memo: memo,
      };
      const url = '/attendance/update_record';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          if (memo) {
            router.go(0)
          }
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('옵션 업데이트 오류:', error);
      }
    }


    // 시스템 날짜를 YYYY-MM-DD 형식의 문자열로 반환하는 함수
    const getFormattedToday = () => {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더합니다.
      const dd = String(today.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    };

    // 상태 업데이트
    const changeAttendStatus = async (at_id, name) => {
      const today = getFormattedToday();
      if (today !== selectDate.value) {
        alert('행사 당일이 아닙니다');
        return;
      }
      const userConfirmed = confirm(`"${name}" 셀러를 출석 처리 할까요?`);
      if (!userConfirmed) {
        return;
      }
      const data = {
        piece_event_id: eventId.value,
        at_id: at_id,
        code_name: "ATTENDED_CHECK",
      };
      const url = '/attendance/update_piece_to_account_status';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }


    // FUNCTION BAR
    const selectedStatuses = ref([]);
    const filteredAttendList = computed(() => {
      if (selectedStatuses.value.length === 0) {
        return attendList.value;
      }

      // 각 attend 객체의 code_name을 selectedStatuses와 비교하여 필터링
      return attendList.value.filter(attend =>
        selectedStatuses.value.includes(attend.code_name)
      );
    });

    const attendTableDownload = async () => {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          account_type: "SELLER",
          date: selectDate.value
        });

        const requestUrl = `/attendance/download_attendance_excel?${queryParams.toString()}`;
        const response = await axios.get(requestUrl, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`,
          },
          responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        // 파일 URL 생성
        const downloadUrl = URL.createObjectURL(blob);
        // 현재 날짜와 시간을 포맷
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;
        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `attended_table_${formattedDateTime}.xlsx`); // 파일명에 날짜와 시간을 추가
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    };

    const statusCountData = ref({})
    // 상태로그 카운트
    async function fetchStatusCount() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          account_type: "SELLER",
          date: selectDate.value
        });
        // if (!showAllDate.value) {
        //   queryParams.append('date', selectDate.value);
        // }
        const url = `/attendance/read_count_by_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          statusCountData.value = response.data.count_info.status_count;
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    function statusCount(status) {
      return statusCountData.value[status] || 0;
    }

    const handleSearch = () => {
      currentPage.value = 1;  // 검색 시 페이지를 1로 설정
      fetchAttendList();      // 검색 결과를 가져옴
    };

    const clearSearch = () => {
      searchText.value = '';   // 검색어 초기화
      currentPage.value = 1;   // 페이지도 1로 설정
      fetchAttendList();       // 검색어를 초기화한 상태로 데이터 재로드
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      selectDate.value = store.state.eventStore.eventDate;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate()
      }
    });
    return {
      activeSub,
      activeHeadTab,
      fetchDate,
      eventDateList,
      createAttendList,
      fetchAttendList,
      selectDate,
      handleSelectDate,
      currentPage,
      totalPages,
      handleClickPage,
      attendList,
      filteredAttendList,
      getIconClass,
      getLanguage,
      toggleShouldSend,
      updateOption,
      showAlertListForMemo,
      handleInputForMemo,
      attendInputMemo,
      showStatusModal,
      showMessageModal,
      selectedUserData,
      closeAllModals,
      openAttendModal,
      openMessageModal,
      changeAttendStatus,
      bisatStatus,
      selectedStatuses,
      statusCount,
      attendTableDownload,
      searchBy,
      searchText,
      handleSearch,
      clearSearch
    };
  },
}

</script>

<template>
  <div id="operateAttend">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <div class="header_tab gap18">
          <p class="inline_cont_btn b_700" @click="openMessageModal">메시지 전송 예약</p>
          <p class="inline_cont_btn b_700">
            <router-link custom v-slot="{ navigate, href, isActive }"
              :to="{ name: 'operateAttendQr', params: { type: 'SELLER' } }">
              <a :href="href" target="_blank" @click="navigate">QR코드 촬영</a>
            </router-link>
          </p>
        </div>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="handleSelectDate">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="attendTableDownload()"><i class="icon-download"></i></p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="full_name">셀러명</option>
                  <option value="booth_name">부스명</option>
                  <option value="company">바이어 회사명</option>
                </select>
                <input type="text" v-model="searchText" placeholder="검색어 입력" @keyup.enter="handleSearch()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = ''; clearSearch()"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" ds_flex gap8 al_center">
              <label class="size_14 secondary_text">상태로그 필터</label>
              <div class="status_wrap ds_flex gap10">
                <div v-for="(item, index) in bisatStatus.SELLER.attended" :key="index" class="ds_flex al_center">
                  <!-- 체크박스의 active 상태를 올바르게 처리 -->
                  <label :for="'checkbox-' + item.status" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes(item.status) }">
                    <i v-if="selectedStatuses.includes(item.status)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'checkbox-' + item.status" :value="item.status"
                    v-model="selectedStatuses" />
                  <label :for="'checkbox-' + item.status"
                    :class="{ 'active': selectedStatuses.includes(item.status) }">{{
                      item.label }} {{
                      statusCount(item.status) }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <thead>
              <tr>
                <th>근무형태</th>
                <th>이름</th>
                <th>부스</th>
                <th>메시지 수신</th>
                <th>출석</th>
                <th>퇴근</th>
                <th>근태평가</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredAttendList.length > 0">
                <tr v-for="attend in filteredAttendList" :key="attend.ar_id">
                  <td>
                    <select v-model="attend.employ_type"
                      @change="updateOption(attend.at_id, attend.employ_type, null, null, null)">
                      <option value="FT">정규</option>
                      <option value="PT">예비</option>
                    </select>
                  </td>
                  <td class="name" @click="openAttendModal(attend)">
                    <div class="status_wrap">
                      <i :class="`icon-${getIconClass('SELLER', 'logs', attend.code_name)}`"></i>
                      <div class="cont">
                        <p class="b_700">{{ attend.full_name }}</p>
                        <p class="lang">
                          {{ getLanguage(attend.lang1) }}<template v-if="attend.lang2">, {{ getLanguage(attend.lang2)
                            }}</template>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="booth">{{ attend.booths }}</td>
                  <td class="message_time">
                    <div>
                      <template v-for="item in attend.text_send_list">
                        <template v-if="item.sent_datetime && item.is_sent">
                          <!-- 보냈을때 -->
                          <div class="ds_flex gap4 al_center">
                            <p class="time">{{ item.sent_datetime }}</p><i class="icon-checked"></i>
                          </div>
                        </template>
                        <!-- 예약만 되어있을때 -->
                        <template v-else-if="!item.sent_datetime && !item.is_sent && item.scheduled_datetime">
                          <p class="time badge">{{ item.messageTime }} 예약됨</p>
                        </template>
                        <!-- 안보내거나 데이터가 없을때 -->
                        <template v-else>
                          <i class="icon-do_not_send time"></i>
                        </template>
                      </template>
                    </div>
                  </td>
                  <td class="attend_time">
                    <template v-if="attend.attend_at">
                      <p class="time highlight">{{ attend.attendTime }}</p>
                      <p class="date">{{ attend.attendDate }} ({{ attend.attend_by === "SCHEDULETABLE" ? "수동" : "QR" }})
                      </p>
                    </template>
                    <template v-else>
                      <p class="block_cont_btn time" @click="changeAttendStatus(attend.at_id, attend.full_name)">출석</p>
                      <div class="ds_flex gap8">
                        <label class="secondary_text">도착 예정</label>
                        <p class="b_700">{{ attend.etaTime ? attend.etaTime : '-' }}</p>
                      </div>
                    </template>
                  </td>
                  <td>
                    <template v-if="attend.offDate">
                      <p class="time highlight">{{ attend.offTime }}</p>
                      <p class="date">{{ attend.offDate }} ({{ attend.offduty_by }})</p>
                    </template>
                    <template v-else>
                      <p class="time"></p>-
                    </template>
                  </td>
                  <td>
                    <select v-model="attend.evaluation"
                      @change="updateOption(attend.at_id, null, null, attend.evaluation, null)">
                      <option value="OK">정상근무</option>
                      <option value="NOSHOW">불참</option>
                      <option value="LATE">지각</option>
                      <option value="LEAVE">무단이탈</option>
                      <option value="OTHER">기타</option>
                    </select>
                  </td>
                  <td class="memo">
                    <input type="text" class="fake_textbox" v-model="attendInputMemo[attend.ar_id]"
                      @input="handleInputForMemo(attend.ar_id)"
                      @keyup.enter="updateOption(attend.at_id, null, null, null, attend.ar_id)" />
                    <transition name="fade-alert">
                      <p v-if="showAlertListForMemo[attend.ar_id] && attend.memo != ''" class="float_alert">엔터를 눌러 저장
                      </p>
                    </transition>
                    <div class="memo_log_wrap">
                      <div v-for="(memo, index) in attend.memo" :key="index">
                        <p class="memo_cont">
                          <i class="icon-talk"></i>
                          <span>{{ memo.memo }}</span>
                        </p>
                        <p class="created ds_flex gap8">{{ memo.created_by }} {{ memo.created_at }}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
    </div>
    <attend-table-modal :show-status-modal="showStatusModal" @close="closeAllModals"
      :selected-user-data="selectedUserData"></attend-table-modal>
    <attend-message-modal :show-message-modal="showMessageModal" @close="closeAllModals" :attend-user-list="attendList"
      :selected-user-data="selectedUserData"></attend-message-modal>
  </div>
</template>