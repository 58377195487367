// 카드인지 리스트인지 저장하는 store
import router from '@/router'

const customStore = {
  namespaced: true,
  state: () => ({
    eventListType: 'card',
  }),
  mutations: {
    setEventListType(state, listType) {
      state.eventListType = listType;
    },
  }
}

export default customStore
