<script>
import { ref, onMounted, computed, inject } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import { return_language } from "@/assets/js/survey_form";
import { types } from "@/assets/js/survey_form";
import dropDownCq from "@/components/admin/dropDown/dropDownCqType.vue";

export default {
  name: "questionDetail",
  components: {
    subHeader,
    tabHeader,
    dropDownCq,
  },
  setup() {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = ref("");
    const token = ref("");
    const eventId = computed(() => store.state.eventStore.eventId);
    const cqId = ref(router.currentRoute.value.params.cq_id);
    const activeSub = ref("setting"); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref("question"); // 여기에서 'question'이라는 값을 정의합니다.
    const question = ref({
      "list_title": "",
      "is_essential": 0,
      "is_in_use": 1,
      "cq_title": {
        'ko': '',
        'en': '',
        'cn': '',
        'jp': ''
      },
      cq_type: "SINGLE",
      cq_answer_form: [
        {
          ko: "",
          en: "",
          cn: "",
          jp: "",
        },
      ],
      created_at: "",
      created_by: {
        account_id: "",
        account_name: "",
      },
      last_use_date: "",
    });
    const type_cq_title = (key, value) => {
      question.value.cq_title[key] = value;
    };
    const type_cq_answer_form = (index, key, value) => {
      question.value.cq_answer_form[index][key] = value;
    };
    const add_answer_form = () => {
      const new_index = {
        ko: "",
        en: "",
        cn: "",
        jp: "",
      };
      question.value.cq_answer_form.push(new_index);
    };
    const sub_answer_form = (index) => {
      question.value.cq_answer_form.splice(index, 1);
    };
    const changeCqType = (type) => {
      question.value.cq_type = type;
    };
    const validateCqType = computed(() => {
      return ["SINGLE", "MULTIPLE"].includes(question.value.cq_type);
    });

    const questionDetail = async () => {
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/consultation/read_question_detail`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          params: {
            cq_id: cqId.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          // 결과값 선가공 후 ref 데이터에 집어넣는다.
          const json = response.data.question;
          // 생성일자 날짜 빼고 자르기
          json.created_at = json.created_at.split("T")[0];
          // null 유효성
          if (json.last_use_date == null) {
            json.last_use_date = "-";
          }
          if (Object.keys(json.cq_title).length < 1) {
            json.cq_title = {
              ko: "",
              en: "",
              cn: "",
              jp: "",
            };
          } else {
            const _cq_title = {
              ko: json.cq_title.ko,
              en: json.cq_title.en,
              cn: json.cq_title.cn,
              jp: json.cq_title.jp,
            };
            json.cq_title = {};
            json.cq_title = _cq_title;
          }
          const cq_answer_form = [];
          Object.values(json.cq_answer_form).map((element) => {
            cq_answer_form.push({
              ko: element.ko,
              en: element.en,
              cn: element.cn,
              jp: element.jp,
            });
          });
          json.cq_answer_form = cq_answer_form;
          question.value = json; // 이벤트 리스트를 저장합니다.
        } else {
          alert("질문 리스트를 불러오지 못했습니다.");
        }
      } catch (error) {
        console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
      }
    };

    const validateData = () => {
      const q = question.value;

      if (q.list_title == "" || q.list_title == null || q.list_title == undefined || typeof q.list_title != "string") {
        return "질문명";
      }

      if (typeof q.is_in_use != "number") {
        return "사용여부";
      }

      if (q.cq_type == "" || q.cq_type == null || q.cq_type == undefined || typeof q.cq_type != "string") {
        return "답변 유형";
      }

      if (q.cq_type == "SINGLE" || q.cq_type == "MULTIPLE") {
        if (q.cq_answer_form == "" || q.cq_answer_form == null || q.cq_answer_form == undefined || typeof q.cq_answer_form != "object") {
          return "답변";
        }
      }

      if (q.cq_title == "" || q.cq_title == null || q.cq_title == undefined || typeof q.cq_title != "object") {
        return "질문";
      }

      return "validated";
    };

    const createData = async () => {
      if (validateData() == "validated") {
        try {
          // Axios 요청을 보냅니다.
          const response = await axios.post(
            "/consultation/create_question",
            {
              list_title: question.value.list_title,
              is_essential: Number(question.value.is_essential),
              is_in_use: Number(question.value.is_in_use),
              cq_title: question.value.cq_title,
              cq_type: question.value.cq_type,
              cq_answer_form: Object.assign({}, question.value.cq_answer_form),
            },
            {
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            }
          );

          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            alert("질문이 생성되었습니다.");
            // 질문지 목록으로 돌아간다
            router.push({ name: "questionSetting", params: { eventId: eventId } });
          } else {
            alert("질문을 생성하지 못했습니다.");
          }
        } catch (error) {
          console.error("질문 생성하는 중에 오류가 발생했습니다:", error);
        }
      } else {
        alert(`${validateData()} 입력값을 다시 확인해주세요.`);
      }
    };

    const saveIsInUseData = async () => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.put(
          "/consultation/update_question",
          {
            is_in_use: Number(question.value.is_in_use)
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          }
        );

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          if (Number(question.value.is_in_use) == 1) {
            alert("사용이 중지 되었습니다.");
          } else {
            alert("사용이 재개 되었습니다.");
          }
          // 질문지 목록으로 돌아간다
        } else {
          alert("사용 중지하기를 수정하지 못했습니다.");
        }
      } catch (error) {
        console.error("사용 중지하기를 수정하는 중에 오류가 발생했습니다:", error);
      }
    };

    const saveData = async () => {
      if (validateData() == "validated") {
        try {
          // Axios 요청을 보냅니다.
          const response = await axios.put(
            "/consultation/update_question",
            {
              cq_id: question.value.cq_id,
              list_title: question.value.list_title,
              is_essential: Number(question.value.is_essential),
              is_in_use: Number(question.value.is_in_use),
              cq_title: question.value.cq_title,
              cq_type: question.value.cq_type,
              cq_answer_form: Object.assign({}, question.value.cq_answer_form),
            },
            {
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            }
          );

          // 요청이 성공했고 결과가 true인 경우
          if (response.data.result === true) {
            alert("질문이 수정되었습니다.");
            // 질문지 목록으로 돌아간다
            router.push({ name: "questionSetting", params: { eventId: eventId } });
          } else {
            alert("질문을 수정하지 못했습니다.");
          }
        } catch (error) {
          console.error("질문 수정하는 중에 오류가 발생했습니다:", error);
        }
      } else {
        alert(`${validateData()} 입력값을 다시 확인해주세요.`);
      }
    };

    const deleteQuestion = async () => {
      if (confirm("삭제한 정보는 복구할 수 없습니다. 정말로 삭제하시겠습니까?")) {
        if (question.value.cq_id) {
          try {
            // Axios 요청을 보냅니다.
            const response = await axios.delete(`/consultation/delete_question?cq_id=${question.value.cq_id}`, {
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            });

            // 요청이 성공했고 결과가 true인 경우
            if (response.data.result === true) {
              alert("질문이 삭제되었습니다.");
              // 질문지 목록으로 돌아간다
              router.push({ name: "questionSetting", params: { eventId: eventId } });
            } else {
              alert("질문을 삭제하지 못했습니다.");
            }
          } catch (error) {
            console.error("질문 삭제하는 중에 오류가 발생했습니다:", error);
          }
        }
      }
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
        if (cqId.value != undefined) {
          questionDetail();
        }
      }
    });

    return {
      eventId,
      isAuthenticated,
      userName,
      activeSub,
      activeHeadTab,
      cqId,
      question,
      return_language,
      types,
      changeCqType,
      validateCqType,
      type_cq_title,
      type_cq_answer_form,
      add_answer_form,
      sub_answer_form,
      validateData,
      createData,
      saveData,
      deleteQuestion,
    };
  },
};
</script>

<template>
  <div id="questionDetail" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'questionSetting', params: { eventId: eventId } }">
        <a :href="href" @click="navigate" class="ds_flex gap12 al_center">
          <i class="icon-down_arrow goback size_24 sub_text"></i>
          <div class="size_22 b_700">
            <template v-if="cqId != undefined"> 질문지 수정 </template>
            <template v-else> 질문지 생성 </template>
          </div>
        </a>
      </router-link>
      <div class="ds_flex al_start gap24">
        <div class="event_tables">
          <div class="survey">
            <div class="table_wrap">
              <p class="size_18 b_700">질문명</p>
              <input type="text" class="fake_textbox" placeholder="질문명을 입력해주세요." v-model="question.list_title" :disabled="cqId != undefined && question.is_essential == 1" />
              <p class="size_18 b_700">질문</p>
              <div class="time_grid">
                <div class="grid_inner">
                  <template v-for="(value, key) in question.cq_title" :key="`cq_title_${key}`">
                    <label :for="`cq_title_${key}`" class="secondary_text b_600">{{ return_language(key) }}</label>
                    <input
                      type="text"
                      :id="`cq_title_${key}`"
                      :placeholder="`${return_language(key)} 질문을 입력해 주세요.`"
                      :value="value"
                      @keyup="type_cq_title(key, $event.target.value)"
                      :disabled="cqId != undefined"
                    />
                  </template>
                </div>
              </div>
              <div class="ds_flex al_center jus_between">
                <p class="size_18 b_700">답변</p>
                <drop-down-cq
                  :defaultValue="question.cq_type"
                  :disabled="cqId != undefined"
                  @change="changeCqType"
                ></drop-down-cq>
              </div>
              <template v-if="validateCqType">
                <div class="time_grid">
                  <template v-for="(form, index) in question.cq_answer_form">
                    <div class="ds_flex">
                      <div>
                        <div class="eqqui_text b_700">선택지.{{ index + 1 }}</div>
                        <div class="grid_inner">
                          <template v-for="(value, key) in form" :key="key">
                            <label :for="`cq_answer_${key}_index`" class="secondary_text b_600">{{ return_language(key) }}</label>
                            <input
                              type="text"
                              :id="`cq_answer_${key}_index`"
                              :placeholder="`${return_language(key)} 옵션을 입력해 주세요.`"
                              :value="value"
                              :disabled="cqId != undefined"
                              @keyup="type_cq_answer_form(index, key, $event.target.value)"
                            />
                          </template>
                        </div>
                      </div>
                      <template v-if="cqId == undefined && question.cq_answer_form.length > 1">
                        <button @click="sub_answer_form(index)">
                          <i class="icon-sub_in_circle"></i>
                        </button>
                      </template>
                    </div>
                  </template>
                  <template v-if="cqId == undefined">
                    <div class="ds_flex jus_center">
                      <button @click="add_answer_form()">
                        <i class="icon-add_in_circle size_20"></i>
                      </button>
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="divide_line"></div>
              </template>
            </div>
          </div>
          <div v-if="cqId == undefined" class="btn_wrap">
            <div class="inline_main_btn active_btn" @click="createData()">저장</div>
          </div>
        </div>
        <div v-if="cqId != undefined && question.is_essential == 0" class="info_box">
          <div class="ds_flex jus_between al_center">
            <div class="size_20 b_700 primary_text">질문지 정보</div>
            <div>
              <label class="secondary_text cs_pointer" for="is_in_use">사용 중지하기</label>
              <label class="fake_checkbox" for="is_in_use" :class="{ active: question.is_in_use == 0 }">
                <i v-if="question.is_in_use == 0" class="icon-checked"></i>
              </label>
              <input type="checkbox" id="is_in_use" v-model="question.is_in_use" :true-value="1" :false-value="0" />
            </div>
          </div>
          <div class="info_box_inner">
            <!-- <div class="divide_line"></div> -->
            <p class="title">생성자</p>
            <p>{{ question.created_by.account_name }}</p>
            <p class="title">생성일</p>
            <p>{{ question.created_at }}</p>
            <p class="title">최근 사용일</p>
            <p>{{ question.last_use_date }}</p>
          </div>
          <button class="block_sub_btn back_not_entered" @click="deleteQuestion()">삭제</button>
          <!-- <button class="block_sub_btn back_main" @click="saveData()">저장</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
