import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import commonHeader from './components/admin/commonHeader.vue'
import commonBisHeader from './components/bis/commonBisHeader.vue'
import './assets/css/app.css'
import axios from 'axios'
import store from "./store"
import vSelect from 'vue-select'
import VueCryptojs from 'vue-cryptojs'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Accessibility from 'highcharts/modules/accessibility';
import HighchartsTreemap from 'highcharts/modules/treemap'; // 트리맵 모듈 추가
import HighchartsHeatmap from 'highcharts/modules/heatmap'; // 트리맵 모듈 추가

import AOS from 'aos';
import "aos/dist/aos.css";
import Vue3Marquee from 'vue3-marquee'




HighchartsTreemap(Highcharts);
HighchartsHeatmap(Highcharts);
Accessibility(Highcharts);

const app = createApp(App)

app.component('common-header', commonHeader)
app.component('common-bis-header', commonBisHeader)

// DEV
axios.defaults.baseURL = 'https://lxcd-server.tplinkdns.com/bisat_dev'
// PROD
// axios.defaults.baseURL = 'https://lxcd-server.tplinkdns.com/bisat_dev'
app.provide('axios', axios)

const options = {
  position: 'bottom-center', // 팝업 위치 설정 (bottom-right, top-right 등)
  timeout: 1000, // 팝업이 사라지는 시간 (ms)
  closeOnClick: true, // 팝업을 클릭하면 사라지도록 설정
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: true,
};

app.use(router).use(store).component("v-select", vSelect).use(setupCalendar, {}).use(VueCryptojs).use(Toast, options).use(HighchartsVue).use(Vue3Marquee);

app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)

app.config.globalProperties.axios = axios;
app.mixin({
  created() {
    AOS.init()
  }
})

app.mount('#app')
