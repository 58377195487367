import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import userStore from "@/store/modules/userStore.js";
import eventStore from "@/store/modules/eventStore.js";
import customStore from "@/store/modules/customStore.js";
import bisStore from "@/store/modules/bisStore.js";

export default createStore({
  state: {
    // 상태 변수들을 정의합니다.
    noticeList: [],
  },
  mutations: {

  },
  actions: {
    // 필요한 액션들을 정의합니다.
  },
  getters: {
    // 필요한 게터들을 정의합니다.
  },
  modules: {
    userStore,
    eventStore,
    customStore,
    bisStore
  },
  plugins: [
    createPersistedState({
      paths: ['userStore', 'eventStore', 'customStore', 'bisStore']
    })
  ]
});
