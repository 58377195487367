<script>
import { ref, defineComponent, onMounted, inject, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showMessageModal: Boolean,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    // 공지 메시지 및 리스트 관리
    const noticeMsg = ref('');
    const noticeList = ref([]);

    // 공지 타입 및 이름을 합쳐서 관리
    const noticeTypes = ref([
      { selected: false, name: 'BUYER', label: '바이어' },
      { selected: false, name: 'INTERPRETER', label: '통역사' },
      { selected: false, name: 'SELLER', label: '셀러' },
      { selected: false, name: 'FM', label: '현장요원' }
    ]);

    let sentNoticeBox = ref(false);
    let noticeDateArr = ref([]);

    // 모달 닫기 이벤트
    const closeModals = () => {
      emit('close');
    }

    // 공지 리스트 가져오기
    async function getNoticeList() {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: computed(() => store.state.eventStore.eventId).value,
        });

        const url = `/notice/read_notice_list?${params.toString()}`;
        const res = await axios.get(url, headers);

        if (res.data.result) {
          store.state.noticeList.value = res.data.notice_list;
          noticeList.value = store.state.noticeList.value;
          noticeDateArr.value = extractUniqueDate();
          if (!highlightedDate.value) {
            highlightedDate.value = noticeDateArr.value[0]
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    // 날짜 및 시간 분리
    const splitDateTime = (dateTimeString, type) => {
      const dateString = dateTimeString.split('T')[type];
      return type === 1 ? dateString.split(':').slice(0, 2).join(':') : dateString;
    }

    // 공지 타입을 한글로 변환
    const modifyNoticeType = (noticeType) => {
      const typeMap = {
        'BUYER': '바이어',
        'INTERPRETER': '통역사',
        'SELLER': '셀러',
        'FM': '현장요원'
      };

      return noticeType
        .map(type => typeMap[type] || '')
        .filter(Boolean)
        .join(', ');
    }
    const highlightedDate = ref(null);

    // 고유한 날짜 추출
    const extractUniqueDate = () => {
      const uniqueDates = new Set();
      store.state.noticeList.value.forEach(notice => {
        const date = splitDateTime(notice.created_at, 0);
        uniqueDates.add(date);
      });
      return Array.from(uniqueDates).reverse();
    }

    // 날짜 필터링
    const filterByDate = (targetDate) => {
      const filteredList = store.state.noticeList.value.filter(notice => {
        return splitDateTime(notice.created_at, 0) === targetDate;
      });
      noticeList.value = filteredList;
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;

        if (store.state.noticeList.value === undefined) {
          await getNoticeList();
        }
        noticeList.value = store.state.noticeList.value;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      noticeList,
      noticeTypes, // noticeType과 noticeTypeName을 합쳐서 noticeTypes로 리팩토링
      noticeMsg,
      highlightedDate,
      sentNoticeBox,
      noticeDateArr,
      splitDateTime,
      modifyNoticeType,
      filterByDate,
    }
  },
});
</script>

<template>
  <div id="sendMessageHistory">
    <transition name="slide-fade">
      <div class="sidemodal" v-if="showMessageModal">
        <div class="top">
          <p class="sub_text" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <p class="size_26 b_700">보낸 공지함</p>
        <div class="cont_wrap modal_overflow">
          <div class="sent_date_container ds_flex al_center gap12">
            <p class="sent_date secondary_text size_14" v-for="(date, index) in noticeDateArr" :key="index"
              @click="filterByDate(date)" :class="{ selected: date === highlightedDate }">
              {{ date }}
            </p>
          </div>
          <template v-if="noticeList.length > 0">
            <div class="sent_notice_container" v-for="(notice, index) in noticeList" :key="index">
              <div class="ds_flex jus_between notice_target_title">
                <p class="size_16 primary_text b_700">{{ modifyNoticeType(notice.notice_type) }}</p>
                <span class="size_14 sub_text">{{ splitDateTime(notice.created_at, 1) }}</span>
              </div>
              <p class="back_light_gray2 secondary_text size_14 notice_contents">{{ notice.notice_text }}</p>
              <p class="notice_sender sub_text size_14">{{ notice.created_by }}</p>
            </div>
          </template>
          <template v-else>
            <p class="size_14 sub_text">정보가 없습니다.</p>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
