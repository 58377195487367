<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';

export default {
  name: 'bisSetting',
  components: {
    Pagination,
    subHeader
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = ref('');
    const token = ref('');
    const activeSub = ref('setting'); // 여기에서 'setting'이라는 값을 정의합니다.


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
      }
    });
    return {
      isAuthenticated,
      userName,
      activeSub
    };
  },
}

</script>

<template>
  <div id="preMatch">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160 on_event">
      preMatch
    </div>
  </div>
</template>
