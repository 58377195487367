<script>
import { ref, defineComponent, onMounted, inject, watch, computed, onBeforeUnmount } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import jsQR from "jsqr";
import { useToast } from 'vue-toastification';

export default defineComponent({
  props: {
    showUserQrModal: Boolean,
    activeTab: String
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const userName = computed(() => store.state.userStore.userName);
    const toast = useToast(); // Toast 호출 함수
    const isAuthenticated = ref(false);
    const token = ref('');

    const closeModals = () => {
      emit('close');
    }
    watch(() => props.showUserQrModal, (newValue, oldValue) => {
      // 속성 이름을 올바르게 참조
      // eventListForBIS.value = { ...newValue };
      startCamera();
    }, { deep: true });

    const tabActive = ref('scan');  // 기본값 'scan'

    // props.activeTab이 변경될 때마다 tabActive 업데이트
    watch(() => props.activeTab, (newValue) => {
      tabActive.value = newValue;  // props.activeTab을 tabActive에 동기화
    }, { immediate: true });  // 컴포넌트가 처음 마운트될 때도 실행
    
    const isAttended = ref(false)
    async function fetchUserAttendCheck() {
      try {
        const response = await axios.get('/user/check_today_attended', {
          params: {
            'piece_event_id': eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          isAttended.value = response.data.check
        } else {
          console.log("사용자 정보 로드 실패")
        }
      } catch (error) {
      }
    }

    // QR 관련 변수
    const video = ref(null);
    const canvas = ref(null);
    const qrCodeData = ref('');
    let animationFrameId = null;
    let isScanning = false; // QR 코드 스캔 중 여부

    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
        if (video.value) {
          video.value.srcObject = stream;
          video.value.setAttribute("playsinline", true); // iOS 사용시 전체 화면을 사용하지 않음을 전달
          video.value.play();
        }
        isScanning = true;
        scanQRCode();
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    };

    const drawLine = (begin, end, color) => {
      if (canvas.value) {
        const context = canvas.value.getContext('2d');
        context.beginPath();
        context.moveTo(begin.x, begin.y);
        context.lineTo(end.x, end.y);
        context.lineWidth = 4;
        context.strokeStyle = color;
        context.stroke();
      }
    };

    const drawRect = (location, color) => {
      drawLine(location.topLeftCorner, location.topRightCorner, color);
      drawLine(location.topRightCorner, location.bottomRightCorner, color);
      drawLine(location.bottomRightCorner, location.bottomLeftCorner, color);
      drawLine(location.bottomLeftCorner, location.topLeftCorner, color);
    };

    const drawFinderPattern = (location, color) => {
      drawLine(location.topLeftFinderPattern, location.topRightFinderPattern, color);
      drawLine(location.topRightFinderPattern, location.bottomLeftFinderPattern, color);
      drawLine(location.bottomLeftFinderPattern, location.topLeftFinderPattern, color);
    };

    const scanQRCode = () => {
      if (video.value && canvas.value && isScanning) {
        const context = canvas.value.getContext('2d', { willReadFrequently: true });
        context.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height);
        const imageData = context.getImageData(0, 0, canvas.value.width, canvas.value.height);

        // QR 코드 해독 로직
        try {
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            isScanning = false; // 스캔 중지
            qrCodeData.value = code.data;

            // QR 코드의 위치에 경계 그리기
            drawRect(code.location, "#FF3B58");

            // Finder 패턴에 경계 그리기
            drawFinderPattern(code.location, "#3BFF58");

            // Bottom Right Alignment 패턴이 있을 경우 그리기
            if (code.location.bottomRightAlignmentPattern) {
              drawLine(code.location.bottomRightAlignmentPattern, code.location.bottomRightAlignmentPattern, "#3B58FF");
            }

            // API 호출
            setTimeout(() => {
              // console.log(qrCodeData.value)
              sendQRDataToAPI(qrCodeData.value)
            }, 1000);
          }
        } catch (error) {
          alert("Error decoding QR code:", error);
        }
      }
      if (isScanning) {
        animationFrameId = requestAnimationFrame(scanQRCode);
      }
    };

    const sendQRDataToAPI = async (qrCodeData) => {
      if (qrCodeData) {
        try {
          const statusData = "ATTENDED_CHECK";
          // const statusData = isAttended.value ? "OFF_DUTY" : "ATTENDED_CHECK";

          const queryParams = new URLSearchParams({
            qr_data: qrCodeData,
            status: statusData
          });
          const url = `/event/scan_qr_for_status?${queryParams.toString()}`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          });

          if (response.data.result === true) {
            toast.success('출석이 완료되었습니다.');
            // router.go(0)
          } else {
            alert('QR 데이터 전송 실패');
            startCamera()
          }
        } catch (error) {
          console.error('QR 데이터 전송 중 오류 발생:', error);
        }
      } else {
        alert("데이터가 정확하지 않습니다.")
        startCamera()
      }
    };

    const stopScan = () => {
      isScanning = false;
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    const userQr = ref('')
    const fetchUserQr = async () => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.get('/user/read_my_qr', {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userQr.value = response.data.qr
        } else {
          alert('QR코드를 불러오지 못했습니다.');
        }
      } catch {

      }
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        tabActive.value = props.activeTab || 'scan';  // 'scan'이 기본 탭
        token.value = store.state.userStore.token;
        startCamera();
        fetchUserAttendCheck();
        fetchUserQr();
      }
    });

    onBeforeUnmount(() => {
      stopScan();
    });

    return {
      closeModals,
      video,
      canvas,
      qrCodeData,
      startCamera,
      stopScan,
      tabActive,
      userQr,
      userName,
      isAttended
    }
  },
});
</script>

<template>
  <div id="bisQr">
    <div class="overlay" v-if="showUserQrModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showUserQrModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <p class="t_center size_22 b_700">{{ isAttended ? "퇴근" : "출석" }} QR</p>
          <div class="tab_wrap">
            <p :class="{ 'active': tabActive === 'scan' }" @click="tabActive = 'scan'">QR코드 스캔</p>
            <p :class="{ 'active': tabActive === 'code' }" @click="tabActive = 'code'">내 QR코드</p>
          </div>
          <div class="tab_cont" v-if="tabActive === 'scan'">
            <p class="size_15 secondary_text t_center">출석 QR코드를 스캔하여 출석을 확인하세요.</p>
            <video ref="video" autoplay style="display: none;"></video>
            <canvas ref="canvas" width="350" height="260"></canvas>
          </div>
          <div class="tab_cont t_center" v-if="tabActive === 'code'">
            <img :src="userQr" />
            <p class="name_bdg">{{ userName }}</p>
            <p class="size_15 sub_text">QR코드로 출퇴근을 체크할 수 있어요.<br />현장 요원이 스캔할 수 있도록 QR코드를 저장해 주세요.</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
