<script>
import { computed, ref, watch } from "vue";
import * as survey_form from "@/assets/js/survey_form";

export default {
    props: {
        options: Array,
        defaultValue: null,
        disabled: false,
    },
    setup(props, { emit }) {
        let options = ref([]);
        let default_value = ref("");
        let disabled = ref(props.disabled);
        const selected = ref(default_value);
        const opened = ref(false);
        const focus = ref(null);

        const openSelect = () => {
            if (opened.value == true) {
                return false;
            } else {
                focus.value.focus();
            }
        };

        const blurClose = () => {
            // 드롭다운 자신 눌렀을때 닫히도록 타임아웃 걸기
            // 타임아웃 없으면 자신 눌렀을 때 닫기는 척만 함
            setTimeout(() => {
                opened.value = false;
            }, 90);
        };

        const changeOption = (option) => {
            if (selected.value != option.key) {
                selected.value = option.key;
                emit("change", selected.value);
            }
            opened.value = false;
        };

        const selectedIndex = computed(() => {
            let option = options.value.find((item) => item.key == selected.value);
            return option.name;
        });

        watch(
            // 드롭다운 프롭 변경 디텍
            [() => props.options, () => props.defaultValue, () => props.disabled],
            ([newOptions, newdefaultValue, newDisabled], [oldOptions, olddefaultValue, oldDisabled]) => {
                //options
                if (newOptions !== oldOptions) {
                    options.value = newOptions;
                }

                //defaultValue
                if (newdefaultValue !== olddefaultValue) {
                    default_value.value = newdefaultValue;
                }

                //disabled
                if (newDisabled !== oldDisabled) {
                    disabled.value = newDisabled;
                }
            },
            { immediate: true }
        );

        return {
            options, // 옵션 v-for 돌려야 하는 값 object 또는 array
            disabled, // 변경 불가 select로 만들기
            selected, // 선택된 옵션값
            blurClose, // 선택값 변경 함수
            changeOption, // 선택값 변경 함수
            opened, // 드롭다운 오픈 체크
            openSelect, // 드롭다운 오픈 함수
            survey_form,
            selectedIndex,
            focus,
        };
    },
};
</script>
<template>
    <div class="fake_dropdown_wrap" :style="`width: auto`">
        <input type="text" ref="focus" name="dropDownOpener" @focus="opened = true" @blur="blurClose()" class="focus_input" />
        <div class="fake_dropdown ds_flex al_center gap4" :class="[opened ? 'active' : '', disabled ? 'disabled' : '']" @click="!disabled ? openSelect() : ''">
            <p>{{ selectedIndex }}</p>
            <i :class="opened ? 'icon-up_arrow' : 'icon-down_arrow'"></i>
        </div>
        <template v-if="!disabled">
            <transition name="dropdown">
                <div class="fake_dropdown_options" v-if="opened" style="">
                    <div v-for="option in options" :key="option" class="fake_option" :class="selected == option ? 'active' : ''" @click="changeOption(option)">
                        {{ option.name }}
                        <i :class="selected == option ? 'icon-checked' : ''"></i>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>
