<script>
import subHeader from '@/components/admin/subHeader.vue'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useStore } from "vuex";
import router from "@/router";
import sendMessageHistoryModal from '@/components/admin/sendMessageHistory.vue';

export default {
  name: 'sendMessage',
  components: {
    subHeader,
    sendMessageHistoryModal
  },
  setup() {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const activeSub = ref('sendNotice');
    const userName = ref('');

    const showMessageModal = ref(false)
    const closeAllModals = () => {
      showMessageModal.value = false;
    };

    let selectedType = ref('');
    let sentMsgBoxFlag = ref(false);

    let startAndEndDate = ref([]);
    let selectedDate = ref('');

    let selectAll = ref(false);
    let contactList = ref([]);
    let selectedContacts = ref([]);
    let listByType = ref([[], [], []]); //SELLER, BUYER, INTERPRETER

    let templateMsg = ref('');
    let kakaoMsg = ref('');
    let smsMsg = ref('');
    let kakaoTemplateList = ref([]);
    let smsTemplateList = ref([]);
    let seletedTemplateIndexForkakao = ref(0);
    let seletedTemplateIndexForSms = ref(0);
    let readonlyFlag = ref(true);

    const msgLogList = ref([]);

    async function getTemplate(templateTitle) {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          mt_type: templateTitle,
        })

        const url = `/message/read_template?${params.toString()}`
        const res = await axios.get(url, headers);

        if (res.data.result) {
          if (templateTitle === 'KAKAO') {
            kakaoTemplateList.value = res.data.template_list;
          } else if (templateTitle === 'SMS') {
            smsTemplateList.value = res.data.template_list;
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function readMsgLog() {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: eventId.value,
        })

        const url = `/message/read_log?${params.toString()}`
        const res = await axios.get(url, headers);

        if (res.data.result) {
          msgLogList.value = res.data.log_list.reverse();
        }
      } catch (e) {
        console.log(e);
      }
    }

    async function readPieceStartAndEndDate() {
      try {
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: eventId.value,
        })

        const url = `/schedule/read_date?${params.toString()}`;
        const res = await axios.get(url, headers);

        if (res.data.result) {
          startAndEndDate.value = res.data.date_list;
          selectedDate.value = startAndEndDate.value[0]; //상담회 날짜 중 첫번쨰 날짜로 바로 선택
        }

      } catch (e) {
        console.error(e);
      }
    }

    async function sendSms() {
      try {
        const url = `/message/send_sms`
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };
        const rb = {
          piece_event_id: eventId.value,
          template_id: smsTemplateList.value[seletedTemplateIndexForSms.value].template_id,
          message_text: templateMsg.value,
          to_at_id: selectedContacts.value,
          account_type: selectedType.value
        }

        const res = await axios.post(url, rb, headers);

        if (res.data.result) {
          alert("SMS 전송이 완료 되었습니다.");
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function sendKakao() {
      try {
        const url = `/message/send_kakao`
        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const rb = {
          piece_event_id: eventId.value,
          template_id: kakaoTemplateList.value[seletedTemplateIndexForkakao.value].template_id,
          to_at_id: selectedContacts.value,
          account_type: selectedType.value
        }

        const res = await axios.post(url, rb, headers);

        if (res.data.result) {
          alert("카카오톡 전송이 완료 되었습니다.");
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function sendMsg() {
      if (selectedType.value === '') {
        alert('보낼 대상을 선택하세요');
        return;
      }

      if (!templateMsg.value) {
        alert('메시지를 입력하세요.');
        return;
      }

      if (!readonlyFlag.value) {
        if (await sendSms()) {
          clearTemplate();
          await readMsgLog();
        }

        return;
      }

      if (await sendKakao()) {
        clearTemplate();
        await readMsgLog();
      }
    }

    async function readScheduledAccountList() {
      try {
        if (selectedDate.value === '' || selectedType.value === '') { // 불필요한 요청 판단
          return;
        }

        const headers = {
          headers: {
            Authorization: `Bearer ${token.value}`,
          }
        };

        const params = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectedDate.value,
          account_type: selectedType.value,
          limit: 1000
        })

        const url = `/message/read_scheduled_account_list?${params.toString()}`
        const res = await axios.get(url, headers);

        if (res.data.result) {
          contactList.value = res.data.scheduled_list;
          selectAll.value = false;

          toggleSelectAll();
        }

      } catch (e) {
        console.error(e);
      }
    }

    function toggleSelectAll() {
      if (selectAll.value) {
        selectedContacts.value = contactList.value.map(contact => contact.at_id);
      } else {
        selectedContacts.value = [];
      }
    }

    function clearTemplate() {
      readonlyFlag.value = true;
      templateMsg.value = '';
      selectedContacts.value = [];
      selectAll.value = false;
    }

    function formatToTwoDigits(number) { // 템플릿 숫자 두자리로 변환
      return number < 10 ? `0${number}` : `${number}`;
    }

    function changeMsgTemplate(type, index) { // false: kakao, true: sms
      if (!type) {
        readonlyFlag.value = true;
        templateMsg.value = kakaoMsg.value;
        seletedTemplateIndexForkakao.value = index - 1;
      } else {
        readonlyFlag.value = false;
        templateMsg.value = smsMsg.value;
        seletedTemplateIndexForSms.value = index - 1;
      }
    }

    function changePlaceholderByFlag() {
      if (readonlyFlag.value) {
        return '템플릿을 선택해주세요.';
      } else {
        return '직접입력';
      }
    }


    watch(selectedContacts, (newVal, oldVal) => {
      if (newVal.length === contactList.value.length) {
        selectAll.value = true;
      } else {
        selectAll.value = false;
      }
    });

    watch(selectedDate, async () => {
      await readScheduledAccountList();
    });

    watch(selectedType, async () => {
      await readScheduledAccountList();
    });

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        await router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        userName.value = store.state.userStore.userName;
      }

      await readPieceStartAndEndDate();
      // await getUserList(3);
      await getTemplate('KAKAO');
      await getTemplate('SMS');

      await readMsgLog();
    })

    return {
      axios,
      store,
      eventId,
      isAuthenticated,
      token,
      activeSub,
      userName,

      selectedType,
      sentMsgBoxFlag,
      startAndEndDate,
      selectedDate,
      selectAll,
      contactList,
      selectedContacts,
      templateMsg,
      kakaoTemplateList,
      smsTemplateList,
      kakaoMsg,
      smsMsg,
      seletedTemplateIndexForkakao,
      seletedTemplateIndexForSms,
      readonlyFlag,
      msgLogList,

      //function
      sendMsg,
      formatToTwoDigits,
      changeMsgTemplate,
      changePlaceholderByFlag,
      toggleSelectAll,
      readScheduledAccountList,

      showMessageModal,
      closeAllModals
    }
  },
}
</script>

<template>

  <div id="sendMessage" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="ds_flex jus_between align-center title_container_top">
        <div class="ds_flex al_center gap8">
          <p class="size_22 b_700 primary_text">메시지 보내기</p>
          <img src="@/assets/img/sendNotice/sendNotice_icon_envelope.svg" alt="envelope">
        </div>
        <button class="main_text size_14 send_notice_btn b_700" :class="{ 'selected': sentMsgBoxFlag }"
          @click="showMessageModal = true">보낸 메시지함
        </button>
      </div>
      <p class="sub_text notice_subtitle">BIS에게 카카오톡이나 문자 메시지를 일괄 전송할 수 있어요.</p>
      <div class="msg_wrapper">
        <div class="msg_row ds_flex">
          <p class="msg_row_left b_700">상담회 날짜</p>
          <div class="msg_row_right_wrapper">
            <select class="select_start_and_end_date" v-model="selectedDate" @change="readScheduledAccountList">
              <option v-for="(sed, index) in startAndEndDate" :key="index" :value="sed">{{ sed }}</option>
            </select>
          </div>
        </div>
        <div class="msg_row ds_flex">
          <p class="msg_row_left b_700">보낼 대상</p>
          <div class="msg_row_right_wrapper">
            <div class="ds_flex gap8">
              <div class="radio_container">
                <input id="notice_seller" type="radio" name="selectedType" value="SELLER" v-model="selectedType" />
                <label for="notice_seller" class="radio_wrap_boxed">
                  셀러
                </label>
              </div>
              <div class="radio_container">
                <input id="notice_buyer" type="radio" name="selectedType" value="BUYER" v-model="selectedType" />
                <label for="notice_buyer" class="radio_wrap_boxed">
                  바이어
                </label>
              </div>
              <div class="radio_container">
                <input id="notice_interpreter" type="radio" name="selectedType" value="INTERPRETER"
                  v-model="selectedType" />
                <label for="notice_interpreter" class="radio_wrap_boxed">
                  통역사
                </label>
              </div>
            </div>

            <div class="ds_flex select_top jus_between al_center" style="margin: 20px 0 8px 0;">
              <div class="ds_flex jus_between al_center">
                <input id="select_top" class="check_wrap_boxed" type="checkbox" name="select_top" v-model="selectAll"
                  @change="toggleSelectAll">
                <label for="select_top" class="check_wrap_boxed">
                  <em><i class="icon-checked"></i></em>전체 선택하기
                </label>
              </div>

              <div class="search">
                <input type="text" placeholder="보낼 대상 검색" /><i class="icon-search"></i>
              </div>
            </div>

            <div class="company_wrapper ds_flex dir_column">
              <div class="ds_flex gap8" v-for="(cl, index) in contactList" :key="index">
                <div class="company_chkbox">
                  <input :id="`company_num_${index}`" class="check_wrap_boxed company_chkbox" type="checkbox"
                    name="company_num" :value="cl.at_id" v-model="selectedContacts">
                  <label :for="`company_num_${index}`" class="check_wrap_boxed company_chkbox">
                    <em><i class="icon-checked"></i></em>
                  </label>
                </div>
                <div class="company_chkbox_right">
                  <p class="size_14 b_700" v-if="cl.company">{{ `${cl.company}(${cl.full_name})` }}</p>
                  <p class="size_14 b_700" v-else>{{ cl.full_name }}</p>
                  <p class="size_14">{{ cl.mobile }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="msg_row ds_flex">
          <p class="msg_row_left b_700">메시지</p>
          <div class="msg_row_right ds_flex dir_column gap16">
            <div class="ds_flex gap16">
              <div class="template_selects">
                <img src="@/assets/img/sendNotice/kakao_icon.svg" alt="kakao_icon" />
                <select v-model="kakaoMsg" @change="changeMsgTemplate(false, $event.target.selectedIndex)">
                  <option value="" selected>카카오톡 템플릿</option>
                  <option v-for="(ktl, index) in kakaoTemplateList" :key="index" :value="ktl.message_text">
                    템플릿_{{ formatToTwoDigits(index + 1) }}
                  </option>
                </select>
              </div>
              <div class="template_selects">
                <img src="@/assets/img/sendNotice/sms_icon.svg" alt="sms_icon" />
                <select v-model="smsMsg" @change="changeMsgTemplate(true, $event.target.selectedIndex)">
                  <option value="" selected>문자 템플릿</option>
                  <option v-for="(stl, index) in smsTemplateList" :key="index" :value="stl.message_text">
                    템플릿_{{ formatToTwoDigits(index + 1) }}
                  </option>
                  <option value="">직접입력</option>
                </select>
              </div>
            </div>
            <textarea class="notice_textarea" :class="{ 'readonly': readonlyFlag }" v-model="templateMsg"
              :placeholder="changePlaceholderByFlag()" :readonly="readonlyFlag"></textarea>
          </div>
        </div>
        <button v-if="templateMsg !== ''" class="main_text size_14 send_notice_btn send b_700" @click="sendMsg">
          보내기
        </button>
        <button v-else class="send_notice_btn send b_700 cancel_btn">보내기</button>
      </div>
    </div>
    <send-message-history-modal :show-message-modal="showMessageModal"
      @close="closeAllModals"></send-message-history-modal>
  </div>
</template>

<style scoped></style>
