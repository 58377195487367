import router from '@/router'
import { useStore } from 'vuex';

const userStore = {
    namespaced: true,
    state: () => ({
        isAuthenticated: false,
        email: '',
        accountId: '',
        accountType: '',
        userName: '',
        userCompany: '',
        token: '',
        multitype_user: false
    }),
    mutations: {
        login(state, status) {
            state.isAuthenticated = status;
        },
        login_info(state, payload) {
            state.email = payload.email;
            state.accountId = payload.account_id;
            state.accountType = payload.account_type;
            state.userName = payload.full_name;
            state.userCompany = payload.company;
            state.token = payload.token;
        },
        setMultiTypeUser(state, value) {
            state.multitype_user = value;
        },
        resetUserState(state) {
            state.email = '';
            state.accountId = '';
            state.accountType = '';
            state.userName = '';
            state.token = '';
            state.isAuthenticated = false;
        }
    },
    actions: {
        loginCheck({ state, commit }) {
            if (!state.token) {
                router.push({
                    name: 'home'
                }).catch(error => {
                    console.log(error);
                });
            } else {
                const tokenExpired = isTokenExpired(state.token);
                if (tokenExpired) {
                    alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                    localStorage.removeItem('vuex');
                    commit('resetUserState');
                    router.push({
                        name: 'home'
                    }).catch(error => {
                        console.log(error);
                    });
                }
            }
        },
        logout({ commit }) {
            if (confirm("로그아웃 하시겠습니까?")) {
                localStorage.removeItem('vuex');
                commit('resetUserState');
                commit('eventStore/resetState', null, { root: true });
                commit('bisStore/resetState', null, { root: true });
                router.push({ name: 'home' }).catch(error => {
                    console.log(error);
                });
            }
        }
    }
};

export default userStore;

function isTokenExpired(token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expirationDate = new Date(decodedToken.exp * 1000);
    return expirationDate < new Date();
}
