<script> 
import subHeader from '@/components/admin/subHeader.vue'
import { computed, onMounted, ref, inject, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import sendNoticeHistoryModal from '@/components/admin/sendNoticeHistory.vue';

export default {
  name: 'sendNotice',
  components: {
    subHeader,
    sendNoticeHistoryModal
  },
  setup() {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const activeSub = ref('sendNotice');


    // 상태 관리
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');
    const userName = ref('');

    // 공지 타입과 선택 상태를 하나의 배열로 관리
    const noticeTypes = ref([
      { name: 'BUYER', selected: false, label: '바이어' },
      { name: 'INTERPRETER', selected: false, label: '통역사' },
      { name: 'SELLER', selected: false, label: '셀러' },
      { name: 'FM', selected: false, label: '현장요원' }
    ]);

    const showNoticeModal = ref(false);
    const closeAllModals = () => {
      showNoticeModal.value = false;
    };
    const noticeMsg = ref('');
    const sentNoticeBox = ref(false);

    // 공지 생성
    const createNotice = async () => {
      if (!isNoticeValid()) return;

      try {
        const url = '/notice/create_notice';
        const headers = { headers: { Authorization: `Bearer ${token.value}` } };
        const payload = {
          piece_event_id: eventId.value,
          notice_type_list: getNoticeTypes(),
          notice_text: noticeMsg.value
        };

        const res = await axios.post(url, payload, headers);
        if (res.data.result) {
          resetNoticeFields();
          alert('공지 보내기 완료');
          router.go(0);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 공지 읽기
    const readNoticeForBis = async () => {
      try {
        const headers = { headers: { Authorization: `Bearer ${token.value}` } };
        const params = new URLSearchParams({ piece_event_id: eventId.value });
        const url = `/notice/read_notice_for_bis?${params.toString()}`;

        const res = await axios.get(url, headers);
        if (res.data.result) {
          // 결과 처리 로직 (필요시 추가)
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 공지 유효성 검사
    const isNoticeValid = () => {
      if (!noticeTypes.value.some(type => type.selected)) {
        alert('보낼 대상을 선택하세요');
        return false;
      }
      if (!noticeMsg.value) {
        alert('메시지를 입력하세요.');
        return false;
      }
      return true;
    };

    // 선택한 공지 타입 반환
    const getNoticeTypes = () => {
      return noticeTypes.value
        .filter(type => type.selected)
        .map(type => type.name);
    };

    // 공지 필드 초기화
    const resetNoticeFields = () => {
      noticeTypes.value.forEach(type => type.selected = false);
      noticeMsg.value = '';
    };

    // 인증 상태 및 사용자 정보 확인
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        await router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        userName.value = store.state.userStore.userName;
      }
    });

    return {
      activeSub,
      closeAllModals,
      showNoticeModal,
      sentNoticeBox,
      noticeTypes,
      noticeMsg,
      createNotice,
      resetNoticeFields,
      getNoticeTypes,
      readNoticeForBis,
    };
  },
}
</script>

<template>
  <div>
    <div id="sendNotice" class="back_light_gray2">
      <sub-header :activeSub="activeSub"></sub-header>
      <div class="wide_wrap">
        <div class="ds_flex jus_between align-center title_container_top">
          <div class="ds_flex al_center gap8">
            <p class="size_22 b_700 primary_text">공지 보내기</p>
            <img src="@/assets/img/sendNotice/sendNotice_icon_megaphone.svg" alt="megaphone">
          </div>
          <button class="main_text size_14 send_notice_btn b_700" :class="{ 'selected': sentNoticeBox }"
            @click="showNoticeModal = true">보낸 공지함
          </button>
        </div>
        <p class="sub_text notice_subtitle">공지할 대상을 선택 후 알림을 띄울 메시지를 입력하세요.</p>
        <div class="msg_wrapper">
          <div class="msg_row ds_flex">
            <p class="msg_row_left b_700">보낼 대상</p>
            <div class="ds_flex gap8 msg_row_right">
              <template v-for="(type, index) in noticeTypes" :key="type.name">
                <input :id="'notice_' + type.name.toLowerCase()" class="check_wrap_boxed" type="checkbox"
                  v-model="type.selected" :name="'notice_' + type.name.toLowerCase()" />
                <label :for="'notice_' + type.name.toLowerCase()" class="check_wrap_boxed">
                  <em><i class="icon-checked"></i></em>{{ type.label }}
                </label>
              </template>
            </div>
          </div>
          <div class="msg_row ds_flex">
            <p class="msg_row_left b_700">메시지</p>
            <textarea class="notice_textarea" placeholder="전달할 공지사항을 입력하세요." v-model="noticeMsg"></textarea>
          </div>
          <button :disabled="!noticeMsg" class="main_text size_14 send_notice_btn send b_700" @click="createNotice">
            보내기
          </button>
        </div>
      </div>
    </div>
    <send-notice-history-modal :show-Notice-modal="showNoticeModal" @close="closeAllModals"></send-notice-history-modal>
  </div>
</template>

<style scoped></style>
