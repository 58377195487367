<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import bisatStatus from '@/assets/js/bisatStatus.json';

export default defineComponent({
  props: {
    showStatusModal: Boolean,
    selectedUserData: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);

    // 유저 데이터
    const userId = computed(() => props.selectedUserData.userId);
    const userType = computed(() => props.selectedUserData.type);
    const pta_id = computed(() => props.selectedUserData.pta_id);
    const mode = computed(() => props.selectedUserData.mode);
    const userInfo = ref({
      buyer: {}, // 초기값으로 빈 객체 설정
      interpreter: {} // 초기값으로 빈 객체 설정
    });

    watch(
      () => props.selectedUserData,
      async (newValue) => {
        if (newValue && newValue.userId) {  // newValue가 존재하고 userId가 있을 때만 실행
          await fetchBuyerReceptionData();
        }
      },
      { immediate: true }
    );

    const closeModals = () => {
      emit('close');
      userInfo.value = { buyer: {}, interpreter: {} }; // 초기값으로 빈 객체 설정
    }

    const saveData = () => {
      // 저장 버튼 클릭 시 부모에게 데이터 전달
      emit('save', userInfo.value.buyer.current_status,);
      closeModals()
    }

    const getStatusList = computed(() => {
      if (userType.value && bisatStatus[userType.value]) {
        return bisatStatus[userType.value].reception || [];
      }
      return [];
    });

    // 상태 업데이트
    const changeStatus = async () => {
      const data = {
        piece_event_id: eventId.value,
        pta_id_list: [pta_id.value],
        code_name: userInfo.value.buyer.current_status,
        created_how: 'SCHEDULETABLE'
      };
      const url = '/event/update_piece_to_account_status';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    // 클릭한 사용자의 상세 정보 불러오기
    async function fetchBuyerReceptionData() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          account_id: userId.value,
        });

        const url = `/reception/read_buyer_reception_info?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          userInfo.value = response.data.buyer_info
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('사용자 정보 에러:', error);
      }
    }



    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      getStatusList,
      changeStatus,
      saveData,
      userInfo,
      userType,
      userId,
      mode
    }
  },
});
</script>

<template>
  <div id="receptionTableModal">
    <div class="overlay" v-if="showStatusModal"></div>
    <div class="modal status_width" v-if="showStatusModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <p class="user_type_bdg" :class="userType">바이어</p>
        <p class="size_26 b_700">{{ userInfo.buyer.company }}</p>
        <div class="user_cont" v-if="userInfo">
          <p><label>이름</label> {{ userInfo.buyer.full_name }}</p>
          <p v-if="userInfo.buyer.email"><label>이메일</label> {{ userInfo.buyer.contact_email }}</p>
          <p v-if="userInfo.buyer.mobile"><label>연락처</label> {{ userInfo.buyer.mobile }}</p>
          <p v-if="userInfo.buyer.country"><label>국가</label> {{ userInfo.buyer.country }}</p>
          <p v-if="userInfo.buyer.lang1"><label>언어</label> {{ userInfo.buyer.lang1 }} <span v-if="userInfo.buyer.lang2">|</span> {{ userInfo.buyer.lang2 }}</p>
        </div>
        <p class="size_18 b_700">담당 통역사</p>
        <div class="inter_info">
          <p class="b_600">{{ userInfo.interpreter.full_name }}</p>
          <p><label>연락처</label>{{ userInfo.interpreter.mobile }}</p>
          <p><label>이메일</label>{{ userInfo.interpreter.contact_email }}</p>
        </div>
        <p class="size_18 b_700">상태</p>
        <div class="user_status">
          <p class="status" v-for="status in getStatusList" :key="status.status"
            :class="{ 'active': userInfo.buyer.current_status === status.status }" @click="userInfo.buyer.current_status = status.status">
            <i :class="`icon-${status.icon}`"></i>{{ status.label }}
          </p>
        </div>
        <div class="btn_wrap ds_flex gap18 jus_end">
          <p class="inline_cont_btn cancel_btn" @click="closeModals">취소</p>
          <p class="inline_cont_btn active_btn" @click="mode === 'qr_mode' ? saveData() : changeStatus()">저장</p>
        </div>
      </div>
    </div>
  </div>
</template>
