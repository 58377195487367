<script>
import { ref, onMounted, computed, inject } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import Pagination from "@/components/admin/pagination.vue";
import subHeader from "@/components/admin/subHeader.vue";
import tabHeader from "@/components/admin/tabHeader.vue";
import { return_language } from "@/assets/js/survey_form";
import questionPreviewModal from "@/components/admin/questionPreviewModal.vue";

export default {
  name: "questionSetting",
  components: {
    Pagination,
    subHeader,
    tabHeader,
    questionPreviewModal,
  },
  setup() {
    const axios = inject("axios"); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = ref("");
    const token = ref("");
    const activeSub = ref("setting"); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref("question"); // 여기에서 'question'이라는 값을 정의합니다.
    const questionList = ref([]);

    // 리스트 받아오기
    const readQuestionList = async () => {
      try {
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/consultation/read_question_list?page=1&limit=50`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          // 결과값 선가공 후 ref 데이터에 집어넣는다.
          response.data.question_list.map((q) => {
            // 생성일자 날짜 빼고 자르기
            q.created_at = q.created_at.split("T")[0];
            // 상태 색깔 지정
            q.stat_class = "";
            if (q.is_essential == 1) {
              q.stat_class = "back_eqqui";
            } else {
              if (q.is_in_use == 0) {
                q.stat_class = "back_not_entered";
              } else {
                q.stat_class = "back_main";
              }
            }
            // null 유효성
            if (q.last_use_date == null) {
              q.last_use_date = "-";
            }
            // 사용 언어 배열 가공
            q.lang_set = "";
            q.lang.map((la, idx) => {
              let l = "";
              l = return_language(la);
              if (idx != 0) {
                q.lang_set += ", ";
              }
              q.lang_set += l;
            });
            /* 답변 예시 등록하기 데이터, is_in_use == 사용중지 인지 확인 */
            /* 사용중지 질문 최하단 정렬? */
          });
          questionList.value = response.data.question_list.sort((a, b) => {
            // 이벤트 리스트를 소팅 후 저장합니다.
            return b.is_essential - a.is_essential || b.is_in_use - a.is_in_use || b.created_at - a.created_at;
          });
          // pagination();
          // initializeTooltips();
        } else {
          alert("질문 리스트를 불러오지 못했습니다.");
        }
      } catch (error) {
        console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
      }
    };

    const searchText = ref("");
    const searchClear = () => {
      searchText.value = "";
    }
    const searchList = computed(() => {
      const list = questionList.value.filter(item => {
        return item.list_title.includes(searchText.value);
      })
      return list;
    })

    // 사이드 팝업
    const showPreviewModal = ref({
      onoff: false,
      selected: "",
    });
    const showQuestionPreview = (cq_id) => {
      if (showPreviewModal.value.selected == cq_id) {
        closeModals();
      } else {
        showPreviewModal.value.onoff = true; // question preview 모달 표시
        showPreviewModal.value.selected = cq_id;
      }
    };
    const closeModals = () => {
      showPreviewModal.value.onoff = false; // question preview 모달 표시
      showPreviewModal.value.selected = "";
    };

    // 추가질문 상세, 생성
    const routerPush = (cq_id) => {
      if (cq_id == "new") {
        router.push({ name: "newQuestionDetail" });
      } else if (typeof cq_id == "number") {
        router.push({ name: "questionDetail", params: { cq_id: cq_id } });
      } else {
        return false;
      }
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: "home" }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;

        readQuestionList();
      }
    });
    return {
      isAuthenticated,
      userName,
      activeSub,
      activeHeadTab,
      questionList,
      searchText,
      searchClear,
      searchList,
      showPreviewModal,
      showQuestionPreview,
      closeModals,
      routerPush,
    };
  },
};
</script>

<template>
  <div id="question" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn" @click="routerPush('new')">질문지 생성하기</p>
      </div>
      <div class="search"><input type="text" v-model="searchText" placeholder="검색어" /><i :class="searchText != '' ? 'icon-delete cs_pointer' : 'icon-search'" @click="searchText != '' ? searchClear() : null"></i></div>
      <div class="user_list">
        <div class="user_list_item" :class="showPreviewModal.selected == item.cq_id ? 'active' : ''" v-for="item in searchList" :key="item.cq_id">
          <div class="item_left cs_pointer" @click="routerPush(item.cq_id)">
            <p class="item_status" :class="item.stat_class">
              <i class="icon-bullet_list"></i>
            </p>
            <div class="item_main">
              <p class="item_title">{{ item.list_title }}</p>
              <p class="item_subtitle">{{ item.lang_set }}</p>
            </div>
          </div>
          <div class="item_right">
            <template v-if="item.is_in_use == 0">
              <p class="icon_cont_btn back_light_gray2 size_14 b_500 sub_text">사용중지</p>
            </template>
            <template v-else>
              <div class="item_date_grid">
                <p class="item_recent">최근사용일</p>
                <p class="primary_text size_14 b_500">{{ item.last_use_date }}</p>
                <template v-if="item.is_essential == 0">
                  <p class="item_creator">생성자: {{ item.created_by.account_name }}</p>
                  <p class="sub_text size_14 b_500">{{ item.created_at }}</p>
                </template>
              </div>
            </template>
            <p class="item_preview" @click="showQuestionPreview(item.cq_id)">
              <i class="icon-preview"></i>
            </p>
          </div>
        </div>
      </div>
      <question-preview-modal :show-preview-modal="showPreviewModal" @close="closeModals"></question-preview-modal>
    </div>
  </div>
</template>
