  `<script>
  import { ref, onMounted, watch, computed, inject } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from 'vue-router';
  import subHeader from "@/components/admin/subHeader.vue";
  import tabHeader from "@/components/admin/tabHeader.vue";
  import Pagination from "@/components/admin/pagination.vue";
  import recordDetailModal from "@/components/admin/recordDetailModal.vue";

  export default {
    name: "surveyRecord",
    props: {
      event: Object
    },
    components: {
      subHeader,
      tabHeader,
      Pagination,
      recordDetailModal
    },
    setup(props) {
      const axios = inject("axios"); // $axios 주입 받기
      const store = useStore();
      const isAuthenticated = ref(false);
      const eventId = computed(() => store.state.eventStore.eventId);
      const userName = ref("");
      const token = ref("");
      const route = useRoute(); // vue-router의 현재 route 정보 접근
      const router = useRouter(); // 라우터 인스턴스를 가져오기
      const activeSub = ref("survey"); // 여기에서 'setting'이라는 값을 정의합니다.
      const activeHeadTab = ref("survey"); // 여기에서 'question'이라는 값을 정의합니다.

      const pathName = route.name;
      let BIS = 'INTERPRETER';
      if (pathName == 'surveyRecordInterpreter') {
        BIS = "INTERPRETER";
      } else if (pathName == 'surveyRecordBuyer') {
        BIS = "BUYER";
      } else if (pathName == 'surveyRecordSeller') {
        BIS = "SELLER";
      }

      // PAGINATION 관련 변수
      const currentPage = ref(1);
      const itemsPerPage = ref(10); // 페이지당 아이템 수

      const totalPages = computed(() => Math.ceil(filteredList.value.length / itemsPerPage.value));
      const paginatedList = computed(() => {
        const start = (currentPage.value - 1) * itemsPerPage.value;
        const end = start + itemsPerPage.value;
        return filteredList.value.slice(start, end);
      });
      const handleClickPage = (pageIndex) => {
        if (pageIndex >= 1 && pageIndex <= totalPages.value) {
          currentPage.value = pageIndex;
        }
      };

      // 리스트 정보 호출과 검색, 필터결과 가공
      const dashBoard = ref({});
      const statusCount = ref([]);
      const emailCount1 = ref(0);
      const emailCount2 = ref(0);
      const fData = ref({});
      const recordList = ref([]);
      const selectedType = ref([]);
      const searchText = ref('');
      const searchBy = ref("company");
      const searchByLabel = (searchBy) => {
        switch (searchBy) {
          case 'booth_name':
            return "부스명"
          case 'company':
            return "업체명"
          case 'created_by':
            return "작성자명"
          default:
            return "-"
        }
      }
      const listOnLoading = ref(false);
      watch(selectedType, () => {
        readRecordList()
      });


      const readRecordList = async () => {
        listOnLoading.value = true;
        try {
          // 전체 URL에 쿼리 스트링을 추가합니다.
          const queryParams = new URLSearchParams({
            piece_event_id: eventId.value,
            cr_type: BIS,
            cr_category: 'PIECE',
            start_date: selectStartDate.value,
            end_date: selectEndDate.value,
            search_by: searchBy.value,
            search_text: searchText.value,
          });
          for (const status of selectedType.value) {
            queryParams.append('cr_status', status);
          }
          const url = `/consultation/read_record_list?${queryParams.toString()}`;
          // Axios 요청을 보냅니다.
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          });

          if (response.data.result === true) {
            recordList.value = response.data.record_list.map(record => {
              const updatedAt = record.cr_updated_at;
              return {
                ...record,
                cr_updated_date: updatedAt ? updatedAt.slice(0, 10) : "-",  // YYYY-MM-DD 형식
                cr_updated_time: updatedAt ? updatedAt.slice(11, 16) : "-",  // HH:MM 형식
              };
            });

            statusCount.value = response.data.status_count;
            dashBoard.value['email_count'] = response.data.email_count;
            dashBoard.value['total'] = response.data.total;
            emailCount1.value = response.data.email_count["1"] + response.data.email_count["2"];
            emailCount2.value = response.data.email_count["2"];
            fData.value = response.data.f_data;
          } else {
            console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
          }
        } catch (error) {
          console.error("질문 리스트를 가져오는 중에 오류가 발생했습니다:", error);
        } finally {
          listOnLoading.value = false;
        }
      }

      const filteredList = computed(() => {
        if (searchText.value == '') {
          return recordList.value;
        } else {
          const list = recordList.value.filter(item => {
            let boothBool;
            let createBool;
            let buyerBool;
            let sellerBool;
            let interpretBool;
            if (searchText.value != '') {
              if (item.booth_name != undefined && item.booth_name.includes(searchText.value)) {
                boothBool = true;
              }
              if (item.created_by_full_name != undefined && item.created_by_full_name.includes(searchText.value)) {
                createBool = true;
              }
              if (item.buyer_full_name != undefined && item.buyer_full_name.includes(searchText.value)) {
                buyerBool = true;
              }
              if (item.seller_full_name != undefined && item.seller_full_name.includes(searchText.value)) {
                sellerBool = true;
              }
              if (item.interpreter_full_name != undefined && item.interpreter_full_name.includes(searchText.value)) {
                interpretBool = true;
              }
            }
            return boothBool || createBool || buyerBool || sellerBool || interpretBool;
          })
          return list;
        }
      })

      // 엑셀 다운로드
      const openDownloadDropDown = ref(false);
      const oDDFocus = ref(null);

      const openoDD = () => {
        if (openDownloadDropDown.value == true) {
          return false;
        } else {
          oDDFocus.value.focus();
        }
      };

      const blurCloseoDD = () => {
        // 드롭다운 자신 눌렀을때 닫히도록 타임아웃 걸기
        // 타임아웃 없으면 자신 눌렀을 때 닫기는 척하다 다시 켜짐
        setTimeout(() => {
          openDownloadDropDown.value = false;
        }, 90);
      };

      const recordDownload = async () => {
        try {
          const response = await axios.get(`/consultation/download_consultation_excel?piece_event_id=${eventId.value}&cr_type=${BIS}`, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
            responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
          });
          // 파일 객체 생성
          const blob = new Blob([response.data], { type: response.headers["content-type"] });

          // 파일 URL 생성
          const url = URL.createObjectURL(blob);
          // 현재 날짜와 시간을 포맷
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
          const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `record_survey_form${formattedDateTime}.xlsx`); // 파일명에 날짜와 시간을 추가
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // 메모리 해제
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("파일 다운로드 중 오류 발생:", error);
        }
      };

      const recordDownloadList = async () => {
        try {
          const response = await axios.get(`/consultation/download_consultation_list_excel?piece_event_id=${eventId.value}&cr_type=${BIS}`, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
            responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
          });
          // 파일 객체 생성
          const blob = new Blob([response.data], { type: response.headers["content-type"] });

          // 파일 URL 생성
          const url = URL.createObjectURL(blob);
          // 현재 날짜와 시간을 포맷
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
          const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `record_table${formattedDateTime}.xlsx`); // 파일명에 날짜와 시간을 추가
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // 메모리 해제
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("파일 다운로드 중 오류 발생:", error);
        }
      };

      // 리스트 정보 출력내용 가공
      const returnComma = money => {
        return Number(money).toLocaleString("ko-KR");
      }

      // const returnDate = date => {
      //   const days = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
      //   let value = date.split('T')[0];
      //   let day = new Date(value).getDay();
      //   return `${value} ${days[day]}`;
      // }

      // const returnTime = date => {
      //   return date.substring(11, 16);
      // }

      //이벤트 일시 관련 변수
      const eventDateList = ref([]);
      const { selectDate } = props.event;
      const selectStartDate = ref(null);
      const selectEndDate = ref(null);
      const readDate = async () => {
        try {
          const queryParams = new URLSearchParams({
            piece_event_id: eventId.value
          });

          const url = `/schedule/read_date?${queryParams.toString()}`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });

          if (response.data.result === true) {
            eventDateList.value = response.data.date_list;
            if (!selectStartDate.value) {
              selectStartDate.value = eventDateList.value[0];
              selectEndDate.value = eventDateList.value[0];
            }
            readRecordList();
          } else {
            alert('에러');
          }
        } catch (error) {
          console.error('스케줄 일시 불러올때 오류:', error);
        }
      }

      // 팝업
      const showRecordModal = ref({
        onoff: false,
        selected: "",
      });
      const showRecordDetail = (schedule_id) => {
        if (showRecordModal.value.selected == schedule_id) {
          closeModals();
        } else {
          showRecordModal.value.onoff = true; // question Record 모달 표시
          showRecordModal.value.selected = schedule_id;
        }
      };
      const closeModals = () => {
        showRecordModal.value.onoff = false; // question Record 모달 표시
        showRecordModal.value.selected = "";
      };

      const toastBool = ref(false);
      const toastStatus = ref(0);
      const showToast = status => {
        toastStatus.value = status;
        toastBool.value = true;
        readRecordList();
        setTimeout(() => {
          toastBool.value = false;
          toastStatus.value = 0;
        }, 700);
      }

      onMounted(async () => {
        isAuthenticated.value = store.state.userStore.isAuthenticated;

        if (!isAuthenticated.value) {
          router.push({ name: "home" }); // 홈으로 이동
        } else {
          // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
          userName.value = store.state.userStore.userName;
          token.value = store.state.userStore.token;
          setTimeout(() => {
            readDate();
            // showRecordDetail(22)
          }, 100);
        }
      });
      return {
        isAuthenticated,
        userName,
        BIS,
        activeSub,
        activeHeadTab,
        currentPage,
        totalPages,
        handleClickPage,
        paginatedList,
        selectEndDate,
        selectStartDate,
        searchText,
        searchBy,
        searchByLabel,
        selectedType,
        eventDateList,
        dashBoard,
        statusCount,
        fData,
        emailCount2,
        emailCount1,
        listOnLoading,
        recordList,
        filteredList,
        readRecordList,
        openDownloadDropDown,
        oDDFocus,
        openoDD,
        blurCloseoDD,
        recordDownload,
        recordDownloadList,
        returnComma,
        // returnDate,
        // returnTime,
        showRecordModal,
        showRecordDetail,
        closeModals,
        showToast,
        toastStatus,
        toastBool,
      };
    }
  };
</script>

<template>
  <div id="record" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <div class="date_picker_wrap">
              <div class="date_picker">
                <select id="selectStartDate" name="selectStartDate" v-model="selectStartDate"
                  @change="readRecordList()">
                  <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
                </select>
              </div>
              <span>~</span>
              <div class="date_picker">
                <select id="selectEndDate" name="selectEndDate" v-model="selectEndDate" @change="readRecordList()">
                  <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
                </select>
              </div>
            </div>
            <div class="cont_wrap">
              <div class="user_table_top_cont ds_flex al_center gap8">
                <div class="fake_dropdown_wrap">
                  <input type="text" ref="oDDFocus" name="dropDownOpener" @focus="openDownloadDropDown = true"
                    @blur="blurCloseoDD()" class="focus_input" />
                  <p class="icon_box" @click="openoDD"><i class="icon-download"></i></p>
                  <transition name="dropdown">
                    <div class="fake_dropdown_options" v-if="openDownloadDropDown" style="width: 140px">
                      <div class="fake_option" @click="recordDownloadList()">엑셀 양식</div>
                      <div class="fake_option" @click="recordDownload()">상담일지 양식</div>
                    </div>
                  </transition>
                </div>
                <p class="fake_textbox">
                  <select name="searchBy" id="searchBy" v-model="searchBy">
                    <option value="company">바이어/셀러 업체명</option>
                    <option value="created_by">작성자</option>
                    <option value="booth_name">부스명</option>
                  </select>
                  <input type="text" id="searchText" :placeholder="`${searchByLabel(searchBy)} 검색어 입력`"
                    v-model="searchText" @keyup.enter="readRecordList()" />
                  <i :class="searchText != '' ? 'icon-delete cs_pointer' : 'icon-search'"
                    @click="searchText != '' ? searchClear() : readRecordList()"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="user_table_top_cont ds_flex al_center">
              <label class="fx_name">상태 보기</label>
              <div class="radio_wrap back_light_gray">
                <div class="radio_option">
                  <label for="checkbox_5" class="fake_checkbox" :class="{ active: selectedType.includes(Number(5)) }">
                    <i v-if="selectedType.includes(Number(5))" class="icon-checked"></i>
                  </label>
                  <label for="checkbox_5" class="size_14"
                    :class="selectedType.includes(Number(5)) ? 'main_text b_700' : 'secondary_text'">우수상담 {{
                      statusCount[5] }}</label>
                  <input type="checkbox" id="checkbox_5" name="status_type" :value="Number(5)" v-model="selectedType">
                </div>
                <div class="radio_option">
                  <label for="checkbox_4" class="fake_checkbox" :class="{ active: selectedType.includes(Number(4)) }">
                    <i v-if="selectedType.includes(Number(4))" class="icon-checked"></i>
                  </label>
                  <label for="checkbox_4" class="size_14"
                    :class="selectedType.includes(Number(4)) ? 'main_text b_700' : 'secondary_text'">승인됨 {{
                      statusCount[4] }}</label>
                  <input type="checkbox" id="checkbox_4" name="status_type" :value="Number(4)" v-model="selectedType">
                </div>
                <div class="radio_option">
                  <label for="checkbox_2" class="fake_checkbox" :class="{ active: selectedType.includes(Number(2)) }">
                    <i v-if="selectedType.includes(Number(2))" class="icon-checked"></i>
                  </label>
                  <label for="checkbox_2" class="size_14"
                    :class="selectedType.includes(Number(2)) ? 'main_text b_700' : 'secondary_text'">검토중 {{
                      statusCount[2] }}</label>
                  <input type="checkbox" id="checkbox_2" name="status_type" :value="Number(2)" v-model="selectedType">
                </div>
                <div class="radio_option">
                  <label for="checkbox_3" class="fake_checkbox" :class="{ active: selectedType.includes(Number(3)) }">
                    <i v-if="selectedType.includes(Number(3))" class="icon-checked"></i>
                  </label>
                  <label for="checkbox_3" class="size_14"
                    :class="selectedType.includes(Number(3)) ? 'main_text b_700' : 'secondary_text'">수정요청 {{
                      statusCount[3] }}</label>
                  <input type="checkbox" id="checkbox_3" name="status_type" :value="Number(3)" v-model="selectedType">
                </div>
                <div class="radio_option">
                  <label for="checkbox_1" class="fake_checkbox" :class="{ active: selectedType.includes(Number(1)) }">
                    <i v-if="selectedType.includes(Number(1))" class="icon-checked"></i>
                  </label>
                  <label for="checkbox_1" class="size_14"
                    :class="selectedType.includes(Number(1)) ? 'main_text b_700' : 'secondary_text'">작성중 {{
                      statusCount[1] }}</label>
                  <input type="checkbox" id="checkbox_1" name="status_type" :value="Number(1)" v-model="selectedType">
                </div>
                <div class="radio_option">
                  <label for="checkbox_0" class="fake_checkbox" :class="{ active: selectedType.includes(Number(7)) }">
                    <i v-if="selectedType.includes(Number(7))" class="icon-checked"></i>
                  </label>
                  <label for="checkbox_0" class="size_14"
                    :class="selectedType.includes(Number(7)) ? 'main_text b_700' : 'secondary_text'">작성전 {{
                      statusCount[7] }}</label>
                  <input type="checkbox" id="checkbox_0" name="status_type" :value="Number(7)" v-model="selectedType">
                </div>
              </div>
            </div>
            <div>
              <label class="secondary_text cs_pointer" for="checkbox_6">이메일 전송 가능일지 보기</label>
              <label class="fake_checkbox" for="checkbox_6" :class="{ active: selectedType.includes(Number(6)) }">
                <i v-if="selectedType.includes(Number(6))" class="icon-checked"></i>
              </label>
              <input type="checkbox" id="checkbox_6" name="status_type" :value="Number(6)" v-model="selectedType">
            </div>
          </div>
        </div>
        <div class="record_dash_wrap">
          <div class="record_dash">
            <div class="thumb">
              <img src="@/assets/img/survey/record_dash_0.png" width="28" alt="">
            </div>
            <div class="text">
              <div class="title">상담 금액</div>
              <div class="cont">{{ returnComma(fData.total_c_fee) }}</div>
            </div>
          </div>
          <div class="record_dash">
            <div class="thumb">
              <img src="@/assets/img/survey/record_dash_1.png" width="28" alt="">
            </div>
            <div class="text">
              <div class="title">계약 금액</div>
              <div class="cont">{{ returnComma(fData.total_p_fee) }}</div>
            </div>
          </div>
          <div class="record_dash">
            <div class="thumb">
              <img src="@/assets/img/survey/record_dash_2.png" width="28" alt="">
            </div>
            <div class="text">
              <div class="title">총 상담일지</div>
              <div class="cont">{{ dashBoard.total }}</div>
            </div>
          </div>
          <div class="record_dash">
            <div class="thumb">
              <img src="@/assets/img/survey/record_dash_3.png" width="28" alt="">
            </div>
            <div class="text">
              <div class="title">
                이메일 전송
                <p class="send">보내기</p>
              </div>
              <div class="cont">{{ emailCount2 }} / {{ emailCount1 }}</div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <colgroup>
              <col>
              <col>
              <col>
              <col>
              <col>
              <col>
              <col>
              <col>
            </colgroup><!-- 8개 -->
            <thead>
              <tr>
                <th>부스</th>
                <th>작성자</th>
                <th>바이어/ 셀러</th>
                <th>상담 일시</th>
                <th>상담 금액</th>
                <th>계약 금액</th>
                <th>현황</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedList.length > 0">
                <tr v-for="r in paginatedList" :key="r.cr_id">
                  <td>{{ r.booth_name }}</td>
                  <td>{{ r.created_by_full_name ? r.created_by_full_name : r.interpreter_full_name }}</td>
                  <td>
                    <div class="ds_flex dir_column gap8">
                      <template v-if="r.buyer_full_name != undefined">
                        <div class="ds_flex al_center gap6">
                          <span class="type_badge">B</span>
                          {{ r.buyer_full_name }}
                        </div>
                      </template>
                      <template v-if="r.seller_full_name != undefined">
                        <div class="ds_flex al_center gap6">
                          <span class="type_badge">S</span>
                          {{ r.seller_full_name }}
                        </div>
                      </template>
                      <template v-if="r.interpreter_full_name !== undefined && BIS !== 'INTERPRETER'">
                        <div class="ds_flex al_center gap6">
                          <span class="type_badge">I</span>
                          {{ r.interpreter_full_name }}
                        </div>
                      </template>
                    </div>
                  </td>
                  <!-- <td>{{ returnDate(r.cr_updated_at) }}<br><span class="secondary_text">{{ returnTime(r.cr_updated_at) -->
                  <td>{{ r.cr_updated_date }}<br><span class="secondary_text">{{ r.cr_updated_time
                      }}</span></td>
                  <td>{{ returnComma(r.c_fee) }}</td>
                  <td>{{ returnComma(r.c_fee) }}</td>
                  <td>
                    <div class="ds_flex al_center gap8 b_500" :class="{ 'cs_pointer': r.cr_status !== 7 }"
                      @click="r.cr_status !== 7 && showRecordDetail(r.schedule_id)">
                      <template v-if="r.cr_status == 7">
                        <img src="@/assets/img/survey/record_0.png" width="24" alt="작성전">
                        <span>작성전</span>
                      </template>
                      <template v-else-if="r.cr_status == 1">
                        <img src="@/assets/img/survey/record_1.png" width="24" alt="작성중">
                        <span>작성중</span>
                      </template>
                      <template v-else-if="r.cr_status == 2">
                        <img src="@/assets/img/survey/record_2.png" width="24" alt="검토중">
                        <span>검토중</span>
                      </template>
                      <template v-else-if="r.cr_status == 3">
                        <img src="@/assets/img/survey/record_3.png" width="24" alt="수정요청">
                        <span>수정요청</span>
                      </template>
                      <template v-else-if="r.cr_status == 4">
                        <img src="@/assets/img/survey/record_4.png" width="24" alt="승인됨">
                        <span>승인됨</span>
                      </template>
                      <template v-else-if="r.cr_status == 5">
                        <img src="@/assets/img/survey/record_5.png" width="24" alt="우수상담">
                        <span>우수상담</span>
                      </template>
                    </div>
                  </td>
                  <td>
                    <template v-if="r.cr_status == 4 || r.cr_status == 5">
                      <template v-if="r.is_email == 1">
                        <button class="icon_box mail"><i class="icon-mail main_text"></i></button>
                      </template>
                      <template v-else-if="r.is_email == 2">
                        <button class="icon_box mail again"><i class="icon-mail sub_text"></i></button>
                      </template>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-if="listOnLoading">
                  <tr>
                    <td colspan="8" class="t_center">
                      로딩 중...
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="8" class="t_center">
                      제출된 상담일지가 없습니다.
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
      <record-detail-modal :showRecordModal="showRecordModal" :BIS="BIS" @close="closeModals"
        @toast="showToast"></record-detail-modal>
      <transition name="fade-alert">
        <div class="toast" v-if="toastBool">
          <template v-if="toastStatus == 3">
            <div class="icon_box back_6"><i class="icon-checked"></i></div>
            <p>상담일지를 반려했어요</p>
          </template>
          <template v-else-if="toastStatus == 4">
            <div class="icon_box back_4"><i class="icon-checked"></i></div>
            <p>상담일지를 승인했어요</p>
          </template>
          <template v-else-if="toastStatus == 5">
            <div class="icon_box back_8"><i class="icon-checked"></i></div>
            <p>우수 상담일지로 등록했어요</p>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>
