<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import bisatTradeGlossary from '@/assets/js/bisatTradeGlossary.json';
import Pagination from '@/components/admin/pagination.vue'

export default {
  name: 'BISGlossary',
  components: {
    Pagination
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.

    const tradeTerms = ref(bisatTradeGlossary);
    const page = ref(1); // 페이지 번호
    const limit = 10; // 페이지당 항목 수
    const keyword = ref('');
    const filteredTerms = ref([]); // 검색 결과를 저장
    const keyword_pagination = ref(0); // 페이지네이션 계산용
    const currentPageData = ref([]); // 현재 페이지의 데이터를 저장

    // 페이지네이션을 위한 함수
    async function pagination() {
      keyword_pagination.value = Math.ceil(filteredTerms.value.length / limit); // 필터링된 데이터 기준으로 페이지 계산
    }

    // 페이지에 맞는 데이터를 업데이트
    function updateCurrentPageData() {
      const start = (page.value - 1) * limit;
      const end = start + limit;
      currentPageData.value = filteredTerms.value.slice(start, end); // 필터링된 데이터에서 슬라이스
    }

    // 키워드를 바탕으로 데이터 필터링
    function filterTerms() {
      if (keyword.value.trim() === '') {
        filteredTerms.value = tradeTerms.value; // 키워드가 없을 때는 전체 데이터
      } else {
        const lowerKeyword = keyword.value.toLowerCase();
        filteredTerms.value = tradeTerms.value.filter(term =>
          term.terms.toLowerCase().includes(lowerKeyword) ||
          term.kor.toLowerCase().includes(lowerKeyword) ||
          term.zh.toLowerCase().includes(lowerKeyword) ||
          term.ja.toLowerCase().includes(lowerKeyword)
        );
      }
      pagination(); // 필터링 후 페이지네이션 업데이트
      updateCurrentPageData(); // 필터링 후 현재 페이지 데이터 업데이트
    }

    // 페이지 변경을 감지하여 데이터 업데이트
    watch([page], updateCurrentPageData);

    // 키워드 변경을 감지하여 필터링 및 페이지네이션 업데이트
    watch([keyword], filterTerms);

    const handleClickPage = (pageIndex) => {
      page.value = pageIndex;  // 페이지 값 업데이트
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        filteredTerms.value = tradeTerms.value; // 처음에 전체 데이터 표시
        await pagination();
        updateCurrentPageData()
      }
    });


    return {
      router,
      tradeTerms,
      keyword,
      page,
      keyword_pagination,
      currentPageData,
      limit,
      handleClickPage,
      filteredTerms
    };
  },
};
</script>

<template>
  <div class="back_light_gray2">
    <div class="glossary">
      <div class="page_title ds_flex jus_between al_center">
        <div class="ds_flex gap8 al_center">
          <p class="icon_box" @click="router.push({ name: 'BISDashboard' })"><i
              class="icon-down_arrow rotate90 size_28"></i></p>
          <p class="size_22 b_700">무역용어집</p>
        </div>
      </div>
      <p class="fake_textbox"><input type="text" placeholder="용어 검색" v-model="keyword" @keyup="filterTerms" /><i
          class="icon-search b_700"></i></p>
      <div class="glossary_cont_wrap">
        <template v-if="filteredTerms.length > 0">
          <p class="size_15 secondary_text">{{ filteredTerms.length }}개의 용어</p>
          <div class="glossary_cont ds_flex dir_column gap16" v-for="(item, index) in currentPageData" :key="index">
            <p class="size_18 b_700">{{ item.terms }}</p>
            <div class="ds_flex dir_column gap6">
              <div class="ds_flex al_center gap8">
                <p class="label">한국어</p>
                <p class="size_14 secondary_text">{{ item.kor }}</p>
              </div>
              <div class="ds_flex al_center gap8">
                <p class="label">중국어</p>
                <p class="size_14 secondary_text">{{ item.zh }}</p>
              </div>
              <div class="ds_flex al_center gap8">
                <p class="label">일본어</p>
                <p class="size_14 secondary_text">{{ item.ja }}</p>
              </div>
            </div>
            <div class="description_box back_light_gray3">
              <p class="size_12 secondary_text">{{ item.description }}</p>
              <p class="size_12 secondary_text">{{ item.description_en }}</p>
            </div>
          </div>
          <Pagination :currentPage="page" :totalPages="keyword_pagination" :handleClickPage="handleClickPage" />
        </template>
        <template v-else>
          <p class="t_center secondary_text">검색결과가 없습니다.</p>
        </template>
      </div>
    </div>
  </div>
</template>
