<script>
import { ref, computed } from 'vue';

export default {
  props: {
    currentPage: Number,
    totalPages: Number,
    handleClickPage: Function // handleClickPage 함수를 props로 받음
  },
  setup(props) {
    // const event_pagination = computed(() => props.totalPages);
    const event_pagination = computed(() => props.totalPages);
    const page = computed(() => props.currentPage);
    const pageRange = 1 // 현재 페이지를 기준으로 양쪽에 표시할 페이지 번호 개수
    const totalVisiblePages = 3 // 처음과 마지막을 제외한 표시될 페이지 번호 개수

    const visiblePages = computed(() => {
      const currentPageIndex = page.value - 1;
      const visiblePages = [];

      if (event_pagination.value <= totalVisiblePages + 2) {
        visiblePages.push(...Array.from({ length: event_pagination.value }, (_, index) => index + 1));
      } else if (currentPageIndex < totalVisiblePages - pageRange) {
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => index + 1));
        visiblePages.push('...');
        visiblePages.push(event_pagination.value);
      } else if (currentPageIndex >= event_pagination.value - 2 - pageRange) {
        visiblePages.push(1);
        visiblePages.push('...');
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => event_pagination.value - totalVisiblePages + index));
      } else {
        visiblePages.push(1);
        visiblePages.push('...');
        visiblePages.push(...Array.from({ length: pageRange * 2 + 1 }, (_, index) => currentPageIndex - pageRange + index + 1));
        visiblePages.push('...');
        visiblePages.push(event_pagination.value);
      }

      return visiblePages;
    });

    // handleClick 함수 정의
    const handleClick = (pageIndex) => {
      props.handleClickPage(pageIndex); // 부모 컴포넌트의 handleClickPage 함수 호출
    };

    return {
      page,
      event_pagination,
      visiblePages,
      handleClick // handleClick 함수를 반환
    };
  }
};
</script>

<template>
  <div class="pagination">
    <!-- 이전 페이지로 이동하는 버튼 -->
    <button class="pagination_btn arrow" @click="handleClick(page - 1)" :disabled="page === 1"><i
        class="icon-down_arrow"></i></button>

    <!-- 페이지 번호 버튼들 -->
    <template v-for="pageIndex in visiblePages" :key="pageIndex">
      <button v-if="pageIndex !== '...'" @click="handleClick(pageIndex)" class="pagination_btn"
        :class="{ 'active': page === pageIndex }">{{ pageIndex }}</button>
      <span v-else>...</span>
    </template>

    <!-- 다음 페이지로 이동하는 버튼 -->
    <button class="pagination_btn arrow" @click="handleClick(page + 1)" :disabled="page === event_pagination"><i
        class="icon-down_arrow"></i></button>
  </div>
</template>
